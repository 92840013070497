import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "faqs/";

// POST new Faq
const addFaq = async (token, Faq) => {
  const res = await axios.post(API_URL, Faq, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.faqs;
};

// GET all Faq
const getAllFaqs = async (token) => {
  const res = await axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.faqs;
};

// GET an Faq
const getFaq = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.faqs;
};

// Delete a Faq
const deleteFaq = async (id, token) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an Faq
const updateFaq = async (Faq, id, token) => {
  const res = await axios.patch(API_URL + id, Faq, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.faqs;
};

// UPDATE an Faq
const publishFaq = async (Faq, id, token) => {
  const res = await axios.patch(API_URL + "faqs-publish-unpublish/" + id, Faq, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

const FaqService = {
  addFaq,
  getAllFaqs,
  getFaq,
  updateFaq,
  deleteFaq,
  publishFaq,
};

export default FaqService;
