import React, { useEffect, useState } from "react";
import VideoCard from "../../components/VideoCard";
import { faker } from "@faker-js/faker";
import DeleteModal from "../../components/DeleteModal";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import {
  deleteVideo,
  getAllVideos,
  reset,
} from "../../redux/videos/video.slices";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const Videos = () => {
  const dispatch = useDispatch();

  const [videosFiltered, setvideosFiltered] = useState([]);

  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const [showModal, setShowModal] = React.useState(false);

  const { videos, isLoading, isError, isSuccess } = useSelector(
    (state) => state.video
  );

  useEffect(() => {
    dispatch(getAllVideos());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Deleted successfully");
      dispatch(reset());
      dispatch(getAllVideos());
      setShowModal(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    const re = new RegExp(keyword, "i");

    setvideosFiltered(
      videos?.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val == "string" && val.match(re)
        )
      )
    );
  }, [keyword, videos]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = videosFiltered?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(videosFiltered?.length / recordsPerPage);

  function handleDelete(id) {
    dispatch(deleteVideo(id));
  }

  return (
    <div>
      <div class="bg-gray-100 flex justify-center items-center">
        <div class="w-full bg-[#003049] rounded-lg p-14">
          <div>
            <h1 class="text-center font-bold text-white text-4xl">
              Search any video by title
            </h1>
            <div className="flex flex-col justify-center items-center mx-auto my-4">
              <p class="mx-auto font-normal text-yellow-600 text-center text-sm mt-2">
                To add new video, click on
              </p>
              <Link to={"/videos/add"} className="my-2 text-white underline">
                add video
              </Link>
            </div>

            <div class="sm:flex items-center bg-white rounded-lg overflow-hidden px-2 py-1 justify-between w-1/3 mx-auto">
              <input
                class="text-base text-gray-400 flex-grow outline-none px-2 "
                type="text"
                placeholder="Search your video title...."
                autoComplete="on"
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
              <div class="ms:flex items-center px-2 rounded-lg space-x-4 mx-auto ">
                <button class="ring-blue-50 text-base rounded-lg px-4 py-2 font-thin">
                  <i className="ri-search-line align-middle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-6">
        {isLoading ? (
          <div className="w-full">
            <Loader />
          </div>
        ) : currentRecords?.length <= 0 ? (
          <div className="w-full flex justify-center">
            <h1>OOPS! video list empty</h1>
          </div>
        ) : (
          <div className="grid grid-cols-4 gap-4">
            {currentRecords?.map((v) => (
              <VideoCard
                data={v}
                key={v?._d}
                toggle={() => setShowModal(!showModal)}
                isLoading={isLoading}
                onDeleteClick={() => handleDelete(v?._id)}
              />
            ))}
          </div>
        )}
      </div>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Videos;
