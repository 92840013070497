import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/auth.slices";

const Login = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const { user, isLoading, isError, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user) {
      router("/dashboard");
    }
  }, [user, router]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handleLogin = (data) => {
    // localStorage.setItem("rezgari", );
    dispatch(login(data));
    // router("/dashboard");
  };

  if (isLoading) {
    return <div className="flex justify-center items-center">Loading...</div>;
  }

  return (
    <div className="flex min-h-full items-center justify-center h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div className="border-t-4 border-red-600 rounded-full">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-red-600">
            REZ<span className="text-blue-900">GARI</span>
          </h2>
          <p className="mt-2 text-center text-sm  font-medium text-indigo-900 hover:text-indigo-500">
            Welcome to Rezgari Admin Dashboard
          </p>
        </div>
        {isError && (
          <p className="text-red-600 ring p-2 ring-red-500 bg-red-200 rounded">
            {message}
          </p>
        )}
        {/* @ts-ignore */}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(handleLogin)}>
          <input type="hidden" name="remember" value="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                type="email"
                autoComplete="email"
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="text-red-600">Email is required</span>
              )}
            </div>
            <div className="mt-5">
              <label htmlFor="password" className="sr-only">
                Password
              </label>

              <div
                className="relative flex w-full appearance-none rounded-none
              rounded-b-md border border-gray-300 px-3 py-2 text-gray-900
              placeholder-gray-500 focus:z-10 focus:border-indigo-500
              focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <input
                  id="password"
                  type={visible ? "text" : "password"}
                  autoComplete="current-password"
                  className="w-full appearance-none text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                  {...register("password", { required: true })}
                />
                <button
                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                  onClick={() => setVisible(!visible)}
                >
                  {visible ? (
                    <i className="ri-eye-fill align-middle"></i>
                  ) : (
                    <i className="ri-eye-off-line align-middle"></i>
                  )}
                </button>
              </div>
              {errors.password && (
                <span className="text-red-600">Password is required</span>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                {...register("remember_me")}
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <Link
                to="/forgotpassword"
                className="font-medium text-yellow-600 hover:text-indigo-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-900 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
