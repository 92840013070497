import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import BreadCrumb from "../../components/BreadCrumb";
import DeleteModal from "../../components/DeleteModal";
import {
  deleteCategory,
  getCategory,
  reset,
} from "../../redux/categories/categories.slices";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

function Category() {
  const [showModal, setShowModal] = React.useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { category, isLoading, isSuccess } = useSelector(
    (state) => state.category
  );

  useEffect(() => {
    dispatch(getCategory(id));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Deleted successfully");
      dispatch(reset());
      setShowModal(false);
      navigate("/categories");
    }
  }, [isSuccess]);

  function handleDelete(id) {
    dispatch(deleteCategory(id));
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex gap-2 justify-between px-8 my-4">
            <BreadCrumb title={"Category's details"} link="categories" />

            <div className="flex gap-2">
              <Link
                class="block w-20 text-white bg-[#003049] hover:bg-[#003049] focus:ring-4 focus:ring-[#003049] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                type="button"
                to={`/categories/${category?._id}/edit`}
              >
                EDIT
              </Link>
              <button
                class="block w-20 text-red-900 hover:bg-[#003049] ring-1 focus:ring-[#003049] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                type="button"
                onClick={() => setShowModal(!showModal)}
              >
                DELETE
              </button>
            </div>
          </div>

          <div class="relative block p-8 overflow-hidden border bg-white border-slate-100 rounded-lg ml-6 mr-6">
            <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-[#D62828] via-[#F77F00] to-[#003049]"></span>
            <span class="absolute inset-x-0 top-0 h-2 bg-gradient-to-r from-[#154485] via-[#F77F00] to-[#003049]"></span>
            <div class="justify-between sm:flex">
              <div>
                <h5 class="text-xl font-bold text-slate-900">
                  {category?.name} category
                </h5>
                <p class="mt-1 text-xs font-medium text-slate-600">
                  ID : {category?.categoryId}
                </p>
              </div>

              <div class="flex-shrink-0 hidden ml-3 sm:block">
                <img
                  class="object-cover w-16 h-16 rounded-lg shadow-sm"
                  src={category?.icon}
                  alt=""
                />
              </div>
            </div>
            <dl class="flex mt-4">
              <div class="flex flex-col-reverse">
                <dt class="text-sm font-medium text-slate-600">Created On</dt>
                <dd class="text-xs text-slate-500">
                  {moment(category?.createdAt).format("YYYY MMM, DD : hh:mm:a")}
                </dd>
              </div>

              <div class="flex flex-col-reverse ml-3 sm:ml-6">
                <dt class="text-sm font-medium text-slate-600">Status</dt>
                <dd class="text-xs text-slate-500 bg-green-300 px-3">active</dd>
              </div>
            </dl>
            <h1 className="text-blue-800 mt-4 ">
              Offers Content above the fold
            </h1>
            <div class="mt-4 sm:pr-8">
              <p class="text-sm text-slate-500">{category?.content_above}</p>
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="p-8 mx-6">
              <h1 className="text-blue-800 my-4 ">Meta title</h1>
              <p class="text-sm text-slate-500">{category?.meta_title}</p>
            </div>
            <div className="p-8 mx-6">
              <h1 className="text-blue-800 my-4 ">Description</h1>
              <p class="text-sm text-slate-500">{category?.description}</p>
            </div>
          </div>
          <div className="grid grid-cols-3">
            {category?.content_below?.map((content) => (
              <div class="relative block p-8 overflow-hidden border bg-white border-slate-100 rounded-lg ml-6 mr-6">
                <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-[#f8f6f6] via-[#F77F00] to-[#003049]"></span>

                <h1 className="text-blue-800 mt-4 ">
                  {content?.tab} Content Below the fold
                </h1>
                <div
                  class="mt-4 sm:pr-8text-sm text-slate-500"
                  dangerouslySetInnerHTML={{ __html: content?.desc }}
                />
              </div>
            ))}
          </div>
          {showModal && (
            <>
              <DeleteModal
                isLoading={isLoading}
                onDeleteClick={() => handleDelete(category?._id)}
                toggle={() => setShowModal(!showModal)}
              />
              <div className=" opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Category;
