import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// POST new Review
const addReview = async (token, Review) => {
  const res = await axios.post(API_URL, Review, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.Review;
};

// GET all Review
const getAllReviews = async (token) => {
  const res = await axios.get(API_URL + "admin/review", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.reviews;
};

// GET an Review
const getReview = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// Delete a Review
const deleteReview = async (id, token) => {
  const res = await axios.delete(API_URL + "admin/review/" + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an Review
const updateReview = async (Review, id, token) => {
  const res = await axios.patch(API_URL + "admin/review/" + id, Review, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// UPDATE an Review
const publishReview = async (Review, id, token) => {
  const res = await axios.patch(API_URL + "admin/review/active/" + id, Review, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

const ReviewService = {
  addReview,
  getAllReviews,
  getReview,
  updateReview,
  deleteReview,
  publishReview,
};

export default ReviewService;
