import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "coupon/";

// POST new Coupon
const addCoupon = async (token, Coupon) => {
	const res = await axios.post(API_URL, Coupon, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	return res.data.data;
};

// GET all Coupon
const getAllCoupons = async (token) => {
	const res = await axios.get(API_URL + "?isAdmin=true", {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	return res.data.coupons;
};

// GET all cancelled Coupon
const getCancelledCoupons = async (token) => {
	const res = await axios.get(API_URL + "cancelled", {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	return res.data;
};

// GET an Coupon
const getCoupon = async (token, id) => {
	const res = await axios.get(API_URL + id, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	return res.data.coupon;
};

// GET admin Coupon graph
const getAdminCouponGraph = async (token, year) => {
	let fd = new FormData();
	fd.append("year", year);

	const res = await axios.post(
		process.env.REACT_APP_API_URL + "dashboard/admin",
		fd,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);

	return res.data;
};

// Delete a Coupon
const deleteCoupon = async (id, token) => {
	const res = await axios.delete(API_URL + id, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return res.data;
};

// Delete a Coupon
const deleteManyCoupon = async (ids, token) => {
	const res = await axios.post(
		API_URL + "delete/many",
		{
			ids,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	return res.data;
};

// UPDATE an Coupon
const updateCoupon = async (Coupon, id, token) => {
	const res = await axios.patch(API_URL + id, Coupon, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	return res.data.data;
};

const CouponService = {
	addCoupon,
	getAllCoupons,
	getCancelledCoupons,
	getCoupon,
	updateCoupon,
	deleteCoupon,
	deleteManyCoupon,
	getAdminCouponGraph,
};

export default CouponService;
