const menuItems = [
  { id: 1, label: "Dashboard", icon: "ri-dashboard-line", link: "/dashboard" },
  {
    id: 2,
    label: "Homepage Settings",
    icon: "ri-settings-2-line",
    link: "/settings",
  },
  {
    id: 3,
    label: "Categories",
    icon: "ri-file-copy-line",
    link: "/categories",
  },
  {
    id: 4,
    label: "Sub-Categories",
    icon: "ri-file-copy-2-line",
    link: "/sub-categories",
  },
  {
    id: 5,
    label: "Tags",
    icon: "ri-bookmark-line",
    link: "/tags",
  },
  { id: 6, label: "Stores", icon: "ri-store-line", link: "/stores" },

  {
    id: 7,
    label: "Coupons/Offer",
    icon: "ri-ticket-line",
    link: "/coupons",
  },
  {
    id: 8,
    label: "Cashback Activity",
    icon: "ri-ticket-line",
    link: "/cashback",
  },
  {
    id: 9,
    label: "Wallet List",
    icon: "ri-ticket-line",
    link: "/Wallet",
  },
  {
    id: 10,
    label: "Videos",
    icon: "ri-video-line",
    link: "/videos",
  },
  {
    id: 11,
    label: "Blogs",
    icon: "ri-book-open-line",
    link: "/blogs",
  },
  {
    id: 12,
    label: "Reviews",
    icon: "ri-message-2-line",
    link: "/reviews",
  },
  {
    id: 13,
    label: "Faqs",
    icon: "ri-questionnaire-fill",
    link: "/faqs",
  },
  {
    id: 14,
    label: "Faqs New",
    icon: "ri-questionnaire-fill",
    link: "/questions",
  },
  {
    id: 15,
    label: "Comments",
    icon: "ri-questionnaire-fill",
    link: "/comments",
  },
  {
    id: 16,
    label: "Users",
    icon: "ri-shield-user-line",
    link: "/users",
  },
  // {
  //   id: 6,
  //   label: "Deals",
  //   icon: "ri-links-line",
  //   link: "/deals",
  // },
];

export default menuItems;
