import { Outlet } from "react-router-dom";
import { TheFooter, TheSidebar, TheHeader } from "./index";

const TheLayout = () => {
  return (
    <div className="relative">
      <TheHeader />
      <div className="h-screen flex flex-row justify-start">
        <TheSidebar />
        <div className="flex-1">
          <div
            className="h-5/6 w-auto m-3 mb-0 rounded-md overflow-scroll "
            style={{
              height: "87vh",
              overflow: "scroll",
              scrollbarWidth: "none",
            }}
          >
            <Outlet />
          </div>
          <TheFooter />
        </div>
      </div>
    </div>
  );
};

export default TheLayout;
