import { useState, useRef } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import JoditEditor from "jodit-react";
import StarPicker from "react-star-picker";
import { FileUploader } from "react-drag-drop-files";
import BreadCrumb from "../../components/BreadCrumb";

const animatedComponents = makeAnimated();

function AddReview() {


  const [rating, setRating] = useState(3.5);

  const onChange = (value) => {
    setRating(value);
  };

  return (
    <>
      <BreadCrumb title={"Add New Review"} link="reviews" />
      {/* <h1 className="text-3xl text-center text-red-600">Add new Review</h1> */}
      <div className="flex items-center justify-center p-12">
        <div className="mx-auto w-full">
          <form className="container px-24">
            <div className="grid grid-cols-3 gap-5">
              <div>
                <label
                  htmlFor="name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Rating
                </label>
                <StarPicker onChange={onChange} value={rating} />
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Reviewer Name
                </label>
                <input
                  type="text"
                  placeholder="enter name of reviewer"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                />
              </div>

              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Gender
                </label>

                <select
                  className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
                >
                  <option value="male">male</option>
                  <option value="female">female</option>
                  <option value="declined">Others</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-5 mt-5">
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Content
                </label>

                <textarea
                  rows="4"
                  placeholder="Type category content above fold here"
                  className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                ></textarea>
              </div>
            </div>

            <div className="mt-5">
              <button
                type="submit"
                className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddReview;
