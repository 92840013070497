import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addFaq, reset } from "../../redux/faqs/faq.slices";
function AddFaq() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { isLoading, isSuccess } = useSelector((state) => state.faq);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added FAQ successfully");
      dispatch(reset());
      navigate("/faqs");
    }
  }, [isSuccess]);

  function handleSave(data) {
    dispatch(addFaq(data));
  }

  return (
    <>
      <h1 className="text-3xl text-center text-red-600">Add new FAQ</h1>
      <div className="flex items-center justify-center p-12">
        <div className="mx-auto w-full">
          <form onSubmit={handleSubmit(handleSave)}>
            <div className="grid grid-cols-2 gap-5">
              <div>
                <div className="my-4">Question</div>
                <textarea
                  rows="4"
                  placeholder="Type faq question"
                  className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("question", { required: true })}
                ></textarea>
              </div>
              <div>
                <div className="my-4">Answer</div>
                <textarea
                  rows="4"
                  placeholder="Type faq answer"
                  className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("answer", { required: true })}
                ></textarea>
              </div>
            </div>
            <div className="mt-5">
              <button
                type="submit"
                className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddFaq;
