import React, { useEffect } from "react";
import { faker } from "@faker-js/faker";
import { Link, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import DeleteModal from "../../components/DeleteModal";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteVideo,
	getVideo,
	reset,
	updateVideo,
} from "../../redux/videos/video.slices";
import { toast } from "react-toastify";
import moment from "moment";

function VideoScreen() {
	const [showModal, setShowModal] = React.useState(false);

	const { id } = useParams();
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { video, isLoading, isSuccess } = useSelector((state) => state.video);

	useEffect(() => {
		dispatch(getVideo(id));
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success("Success");
			dispatch(reset());
			setShowModal(false);
			navigate("/videos");
		}
	}, [isSuccess]);

	function handleDelete(id) {
		dispatch(deleteVideo(id));
	}

	const handleRecommend = () => {
		dispatch(
			updateVideo({
				id,
				data: {
					is_recommended: !video?.is_recommended,
				},
			})
		);
	};

	return (
		<div>
			<div className="flex gap-2 justify-between px-8 my-4">
				<BreadCrumb title={"Video's details"} link="videos" />
				<div className="flex gap-2">
					<Link
						class="block w-20 text-white bg-[#003049] hover:bg-[#003049] focus:ring-4 focus:ring-[#003049] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
						to={`/videos/${video?._id}/edit`}
					>
						EDIT
					</Link>
					<button
						class="block bg-yellow-700 text-white hover:bg-[#003049] ring-1 focus:ring-[#003049] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
						type="button"
						onClick={handleRecommend}
					>
						{video?.is_recommended ? "UNRECOMMEND" : "RECOMMEND"}
					</button>
					<button
						class="block w-20 text-red-900 hover:bg-[#003049] ring-1 focus:ring-[#003049] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
						type="button"
						onClick={() => setShowModal(!showModal)}
					>
						DELETE
					</button>
				</div>
			</div>

			<div class="relative block ring-1 p-8 overflow-hidden border bg-white border-slate-100 rounded-lg ml-6 mr-6">
				<div class="justify-between sm:flex w-full rounded-t-lg  p-5  bg-gradient-to-r from-[#0e39b8] via-[#F77F00] to-[#003049]">
					<div>
						<h5 class="text-xl font-bold text-white">Video ID</h5>
						<p class="mt-1 text-xs font-medium text-slate-200">
							{video?._id}
						</p>
					</div>
					<div>
						<h5 class="text-xl font-bold text-white">Store</h5>
						<p class="mt-1 text-xs font-medium text-slate-200">
							{video?.store?.name || "N/A"}
						</p>
					</div>
					<div>
						<h5 class="text-xl font-bold text-white">Category</h5>
						<p class="mt-1 text-xs font-medium text-slate-200">
							{video?.category?.name || "N/A"}
						</p>
					</div>
					<div class="flex">
						<div>
							<div class="text-xl font-bold text-white">
								Created On
							</div>
							<div class="text-xs mt-1 text-slate-200">
								{moment(video?.createdAt).format(
									"YYYY MMM DD hh:mm:a"
								)}
							</div>
						</div>

						{/* <div class="ml-3 sm:ml-6">
              <div class="text-xl font-bold text-white">Status</div>
              <div class="text-xs mt-1 text-slate-500 bg-green-300 px-3">
                active
              </div>
            </div> */}
					</div>
					<div class="flex-shrink-0 hidden ml-3 sm:block">
						<img
							class="object-cover w-26 h-16 rounded-lg shadow-sm"
							src={video?.desktop_image}
							alt=""
						/>
					</div>
				</div>

				<div className="grid lg:grid-cols-2 sm:grid-cols-1">
					<div>
						<h1 className="text-blue-800 mt-6 ">Video</h1>
						<div class="mt-4 sm:pr-8">
							<ReactPlayer url={video?.link} controls={true} />
						</div>
					</div>

					<div className="p-8 border-l-8 rounded-l-lg">
						<h1 className="text-blue-800 my-4 ">Tabs</h1>
						<div className="flex gap-4">
							{video?.tabs?.map((tab, i) => (
								<div
									key={i}
									class="text-xs mt-1 rounded-md text-slate-200 bg-[#003049] px-3 py-1"
								>
									{tab}
								</div>
							))}
						</div>
						<h1 className="text-blue-800 my-4 ">Title</h1>
						<div className="flex gap-4">{video?.title}</div>
						<h1 className="text-blue-800 my-4 ">Description</h1>
						<div className="flex gap-4">{video?.description}</div>
					</div>
				</div>
			</div>
			<div class="relative block p-8 overflow-hidden border bg-white border-slate-100 rounded-lg ml-6 mr-6">
				<span class="absolute inset-x-0 top-0 h-2 bg-gradient-to-r from-[white] via-[#003049] to-[white]"></span>
				<div class="justify-between sm:flex pb-6">
					<div>
						<h5 class="text-xl font-bold text-yellow-700">
							views && Votes
						</h5>
					</div>
					<div>
						<h5 class="text-xl font-bold text-slate-900">
							Admin views
						</h5>
						<p class="mt-1 text-xs font-medium text-slate-600">
							{video?.admin_views}
						</p>
					</div>
					<div>
						<h5 class="text-xl font-bold text-slate-900">
							Admin votes
						</h5>
						<p class="mt-1 text-xs font-medium text-slate-600">
							{video?.admin_votes}
						</p>
					</div>
					<div>
						<div class="text-xl font-bold text-slate-900">
							Actual views
						</div>
						<div class="text-xs mt-1 text-slate-500">
							{video?.actual_views}
						</div>
					</div>
					<div>
						<div class="text-xl font-bold text-slate-900">
							Actual votes
						</div>
						<div class="text-xs mt-1 text-slate-500">
							{video?.actual_votes}
						</div>
					</div>
				</div>
				{/* <span class="absolute inset-x-0 top-0 h-2 bg-gradient-to-r from-[white] via-[#003049] to-[white]"></span> */}
			</div>
			<div className="grid lg:grid-cols-3 sm:grid-cols-1">
				{video?.content_below?.map((content) => (
					<div class="relative block p-8 overflow-hidden border bg-white border-slate-100 rounded-lg ml-6 mr-6">
						<span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-[#f8f6f6] via-[#F77F00] to-[#003049]"></span>

						<h1 className="text-blue-800 mt-4 ">
							{content?.tab} Content Below the fold
						</h1>
						<div
							class="mt-4 sm:pr-8"
							dangerouslySetInnerHTML={{ __html: content?.desc }}
						/>
					</div>
				))}
			</div>
			{showModal && (
				<>
					<DeleteModal
						toggle={() => setShowModal(!showModal)}
						isLoading={isLoading}
						onDeleteClick={() => handleDelete(video?._id)}
					/>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			)}
		</div>
	);
}

export default VideoScreen;
