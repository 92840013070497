import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BreadCrumb from "../../components/BreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addCoupon, reset } from "../../redux/coupons/coupon.slices";
import { getAllCategories } from "../../redux/categories/categories.slices";
import { getAllSubCategories } from "../../redux/subcategories/subcategories.slices";
import { getAllTags } from "../../redux/tags/tag.slices";
import { getAllStores } from "../../redux/stores/stores.slices";
import axios from "axios";
import JoditEditor from "jodit-react";
import { editorConfig } from "../../utils/editorConfig";

const animatedComponents = makeAnimated();

function AddCoupon() {
	const [imageTypeDesktop, setimageTypeDesktop] = useState(null);
	const [imageTypeMobile, setimageTypeMobile] = useState(null);
	const [couponFormat, setCouponFormat] = useState("");
	const [isTabs, setIsTabs] = useState(false);
	const [isBankOffers, setIsBankOffers] = useState(false);
	const [selectedBank, setSelectedBank] = useState(null);
	const [selectedTabs, setSelectedTabs] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]);
	const [selectedSubCategory, setSelectedSubCategory] = useState(null);
	const [selectedSubCategories, setSelectedSubCategories] = useState(null);
	const [imageMobile, setImageMObile] = useState([]);
	const [imageDesktop, setImageDesktop] = useState([]);
	const [formData, setFormData] = useState([]);
	const [description, setDescription] = useState(null);
	const [offertext2, setOffertext2] = useState(null);
	const [offertext1, setOffertext1] = useState(null);
	const [storeBankOffers, setStoreBankOffers] = useState(null);
	const [isAffiliate, setIsAffiliate] = useState(false)
	const dispatch = useDispatch();

	const navigate = useNavigate();



	const {
		handleSubmit,
		register,
		reset: resetForm,
		watch,
		clearErrors,
		setValue,
		formState: { errors },
	} = useForm();

	const { categories } = useSelector((state) => state.category);
	const { subcategories } = useSelector((state) => state.subcategory);
	const { stores } = useSelector((state) => state.store);
	const { tags } = useSelector((state) => state.tag);
	const { isSuccess, isLoading } = useSelector((state) => state.coupon);

	const editor = useRef(null);
	const config = useMemo(() => editorConfig, []);

	const bankOptions = [
		{ value: "Kotak Bank", label: "Kotak Bank" },
		{ value: "ICICI Bank", label: "ICICI Bank" },
		// { value: "ICICI Bank", label: "ICICI Bank" },
		// { value: "ICICI Bank", label: "ICICI Bank" },
	];
	const findBankOffers = (storeName) => {
		const store = stores.find(store => store._id === storeName);

		if (store) {
			if (store?.bankOffers && store?.bankOffers?.length > 0) {
				const bankOption = store?.bankOffers.map((bankName) => ({
					value: bankName,
					label: bankName,
				}));
				setStoreBankOffers(bankOption);
			}



		} else {
			// Reset the bank offers if the store is not found
			setStoreBankOffers(null);
		}
	};

	useEffect(() => {
		console.log("JGHGHGJG")
	}, [storeBankOffers])


	const handleContentChange = (content, index) => {
		setFormData([
			...formData,
			{
				tab: content?.tab,
				desc: content?.content,
			},
		]);
	};

	useEffect(() => {
		dispatch(getAllCategories());
		dispatch(getAllSubCategories());
		dispatch(getAllTags());
		dispatch(getAllStores());
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success("Added coupon successfully");
			resetForm();
			dispatch(reset());
			navigate("/coupons");
		}
	}, [isSuccess]);

	const tabsOption = [
		{ value: "What is the Offer", label: "What is the Offer" },
		{ value: "How to use it", label: "How to use it" },
	];

	const subCategoriesOptpions = subcategories?.map((sub) => ({
		value: sub?._id,
		label: sub?.name,
	}));

	const tagsOption = tags?.map((sub) => ({
		value: sub?._id,
		label: sub?.name,
	}));

	const [loading, setLoading] = useState(false);

	const handleImageMobile = (e) => {
		// e.preventDefault();
		setLoading(true);
		try {
			Array.from(e.target.files).map((i) => {
				let file = i;

				let formData = new FormData();
				formData.append("image", file);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
					},
				};
				axios
					.post(
						process.env.REACT_APP_API_URL + `images`,
						formData,
						config
					)
					.then(({ data }) => {
						// set image in the state
						setImageMObile((prev) => [
							...prev,
							data?.data?.uploadedUrl,
						]);
						toast.success("Success");
					});
			});
			return;
		} catch (err) {
			toast.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	const handleImageDesktop = (e) => {
		// e.preventDefault();
		setLoading(true);
		try {
			Array.from(e.target.files).map((i) => {
				let file = i;
				let formData = new FormData();
				formData.append("image", file);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
					},
				};

				axios.post(
					process.env.REACT_APP_API_URL + `images`,
					formData,
					config
				)
					.then(({ data }) => {
						setImageDesktop((prev) => [
							...prev,
							data?.data?.uploadedUrl,
						]);
						toast.success("Success");
					});
			});
		} catch (error) {
			toast.error(
				error.response.data.message || error.response.data.error
			);
		} finally {
			setLoading(false);
		}
	};

	function handleSave(data) {
		if (imageDesktop === "album" && imageDesktop?.length < 3) {
			return toast.warn("please provide atleast 3 images");
		}
		if (imageTypeMobile === "album" && imageMobile?.length < 3) {
			return toast.warn("please provide atleast 3 images");
		}

		if (!description) {
			return toast.warn("please provide description");
		}

		const newtabs = selectedTabs?.map((t) => {
			return {
				tab: t.value,
				desc: "",
			};
		});

		const combinedArray = newtabs.concat(formData);

		const uniqueMembers = Object.values(
			combinedArray.reduce((acc, obj) => {
				acc[obj.tab] = obj;
				return acc;
			}, new Map())
		);

		let q = {
			...data,
			is_tabs: isTabs,
			is_bank_offers: isBankOffers,
			bankOffers: (selectedBank && selectedBank.length > 0 && selectedBank !== null) ? selectedBank.map((tab) => tab.value) : null,
			tabs: selectedTabs.map((tab) => tab.value),
			tags: selectedTags.map((tag) => tag.value),
			subcategory: selectedSubCategory?.value,
			desktop_image: imageDesktop,
			mobile_image: imageMobile,
			is_affiliate_url: isAffiliate,
			image_format_desktop: imageTypeDesktop,
			image_format_mobile: imageTypeMobile,
			content_below: uniqueMembers,
			description,
			offer_text_2: offertext2,
			offer_text_1: offertext1,
			subcategories: selectedSubCategories.map((cat) => cat.value),
		}

		dispatch(
			addCoupon(q)
		);
	}

	const preventMinus = (e) => {
		if (e.code === "Minus") {
			e.preventDefault();
		}
		const keyCode = e.keyCode;

		const excludedKeys = [8, 37, 39, 46];

		if (
			!(
				(keyCode >= 48 && keyCode <= 57) ||
				(keyCode >= 96 && keyCode <= 105) ||
				excludedKeys.includes(keyCode)
			)
		) {
			e.preventDefault();
		}
	};

	const handleStoreChange = (selectedStore) => {
		// Call the function to find bank offers for the selected store
		findBankOffers(selectedStore?.value);
	};
	const handleEditorData = (event, editor) => {
		const data = editor.getData();
		console.log(data);
	};

	const editorConfigck = {
		toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
	};

	return (
		<>
			<div className="flex gap-2 justify-between px-8 my-4">
				<BreadCrumb title={"Coupon Add"} link="coupons" />
				<h1 className="text-2xl text-center text-red-600">
					Add New Coupon or Offer
				</h1>
				<div className="flex gap-2"></div>
			</div>
			<div className="flex items-center justify-center p-12">
				<div className="mx-auto w-full">
					<form
						className="container px-24"
						onSubmit={handleSubmit(handleSave)}
					>
						<div className="grid grid-cols-2 gap-5">
							<div>
								<label
									htmlFor="name"
									className="mb-3 block text-base font-medium text-[#07074D]"
								>
									Store name
								</label>
								{/* <select
									className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
									{...register("store", { required: true })}
								>


									<option></option>
									{stores?.map((s) => (
										<option value={s._id}>{s.name}</option>
									))}
								</select> */}
								{stores.length !== 0 ?
									<Select {...register("store", { required: true })}
										onChange={(e) => {
											setValue("store", e.value);
											clearErrors("store")
											handleStoreChange(e);
										}}
										options={stores?.map((s) => ({ value: s._id, label: s.name }))} />
									: <Select />}
								{errors.store && (
									<span className="text-red-700">
										Store is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Category name
								</label>
								{/* <select
									className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
									{...register("category", {
										required: true,
									})}
								>
									<option></option>
									{categories?.map((cat) => (
										<option value={cat._id}>
											{cat.name}
										</option>
									))}
								</select> */}

								{categories.length !== 0 ? <Select {...register("category", { required: true })} onChange={(e) => { setValue("category", e.value); clearErrors("category") }} options={categories?.map((s) => ({ value: s._id, label: s.name }))} />
									: <Select />}

								{errors.category && (
									<span className="text-red-700">
										Category is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Title
								</label>
								<textarea
									rows="3"
									placeholder="Type coupon title here..."
									className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("title", { required: true })}
								></textarea>
								{errors.title && (
									<span className="text-red-700">
										Title is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Description
								</label>
								<div>

									<div>
										<JoditEditor
											ref={editor}
											value={description}
											config={config}
											onChange={(value) => { }}
											onBlur={(content) =>
												setDescription(content)
											}
										/>
									</div>



								</div>
							</div>
							{/* <div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Sub Category name
								</label>

								<Select
									components={animatedComponents}
									isMulti={false}
									name="subcategory"
									className="basic-multi-select"
									placeholder="select a subcategory to display..."
									classNamePrefix="select"
									closeMenuOnSelect={true}
									options={subCategoriesOptpions}
									value={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e)}
								/>
							</div> */}
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Sub Categories
								</label>

								<Select
									components={animatedComponents}
									isMulti
									name="subcategories"
									className="basic-multi-select"
									placeholder="select subcategories to display..."
									classNamePrefix="select"
									closeMenuOnSelect={false}
									options={subCategoriesOptpions}
									value={selectedSubCategories}
									onChange={(e) =>
										setSelectedSubCategories(e)
									}
								/>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Tags
								</label>

								<Select
									components={animatedComponents}
									isMulti
									name="tags"
									className="basic-multi-select bg-white"
									placeholder="select the tags to interlink..."
									classNamePrefix="select"
									closeMenuOnSelect={false}
									options={tagsOption}
									value={selectedTags}
									onChange={(e) => setSelectedTags(e)}
								/>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium mt-5 text-[#07074D]">
									Is affiliate
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											value={true}
											onChange={(e) => setIsAffiliate(true)}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Yes
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											value={false}
											onChange={(e) => setIsAffiliate(false)}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											No
										</label>
									</div>
								</div>

							</div>
							{isAffiliate && (
								<div>
									<label className="mb-3 block text-base font-medium text-[#07074D]">
										Affiliate Url
									</label>
									<input
										type="text"
										placeholder="enter affilaite Url e.g www.mytra.com/MYT004"
										className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
										{...register("affiliate_url", {
											required: true,
										})}
									/>
									{errors.affiliate_url && (
										<span className="text-red-700">
											Affilate Url is required
										</span>
									)}
								</div>
							)}
	<div>
							{storeBankOffers && storeBankOffers?.length >0 &&
							(
								<>
							<label className="mb-3 block text-base font-medium mt-5 text-[#07074D]">
								Bank Ofers
							</label>
							<div className="flex items-center space-x-6 mb-5">
								<div className="flex items-center">
									<input
										type="radio"
										name="tabs"
										className="h-5 w-5"
										value={true}
										onChange={(e) => setIsBankOffers(true)}
									/>
									<label
										for="radioButton1"
										className="pl-3 text-base font-medium text-gray-400"
									>
										Yes
									</label>
								</div>
								<div className="flex items-center">
									<input
										type="radio"
										name="tabs"
										className="h-5 w-5"
										value={false}
										onChange={(e) => setIsBankOffers(false)}
									/>
									<label
										for="radioButton2"
										className="pl-3 text-base font-medium text-gray-400"
									>
										No
									</label>
								</div>
							</div>
							{isBankOffers && (
								<Select
									components={animatedComponents}
									isMulti
									name="tabs"
									className="basic-multi-select"
									placeholder="select Bank "
									classNamePrefix="select"
									closeMenuOnSelect={false}
									options={storeBankOffers}
									value={selectedBank}
									onChange={(e) => setSelectedBank(e)}
								/>
							)}
							</>
						)
						}
						</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Coupon type
								</label>
								<select className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
									{...register("coupon_type")}
								>
									<option></option>
									<option value="Coupon">Coupon</option>
									<option value="Deal">Deal</option>
									{/* <option value="Bank offer">
										Bank offer
									</option> */}
									{/* <option value="Wallet offer">
										Wallet offer
									</option> */}
								</select>
								{errors.coupon_type && (
									<span className="text-red-700">
										Coupon Type is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Coupon format
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="coupon_format"
											className="h-5 w-5"
											value={"Text"}
											onChange={(e) =>
												setCouponFormat("Text")
											}
											{...register("coupon_format", {
												required: true,
											})}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Text format
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="coupon_format"
											className="h-5 w-5"
											value={"Image"}
											onChange={(e) =>
												setCouponFormat("Image")
											}
											{...register("coupon_format", {
												required: true,
											})}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Image format
										</label>
									</div>
								</div>
								{errors.coupon_format && (
									<span className="text-red-700">
										Coupon Format is required
									</span>
								)}
							</div>
						</div>

						{watch("coupon_format") === "Text" && (
							<textarea
								rows="3"
								placeholder="Type text for coupon here"
								className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
								{...register("text")}
							></textarea>
						)}
						{watch("coupon_format") === "Image" && (
							<div className="grid grid-cols-1 gap-5 mt-5">
								<div className="grid grid-cols-2">
									<div>
										<label className="mb-3 block text-base font-medium text-[#07074D]">
											Desktop Image
										</label>
										<div className="flex items-center space-x-6 mb-5">
											<div className="flex items-center">
												<input
													type="radio"
													name="image_format_desktop"
													className="h-5 w-5"
													value={"single"}
													onChange={(e) => {
														setImageDesktop([]);
														setimageTypeDesktop(
															"single"
														);
													}}
												/>
												<label
													for="radioButton1"
													className="pl-3 text-base font-medium text-gray-400"
												>
													Single
												</label>
											</div>
											<div className="flex items-center">
												<input
													type="radio"
													name="image_format_desktop"
													className="h-5 w-5"
													value={"album"}
													onChange={(e) => {
														setImageDesktop([]);
														setimageTypeDesktop(
															"album"
														);
													}}
												/>
												<label
													for="radioButton2"
													className="pl-3 text-base font-medium text-gray-400"
												>
													Album
												</label>
											</div>
											<div className="flex items-center">
												<input
													type="radio"
													name="image_format_desktop"
													className="h-5 w-5"
													value={"gif"}
													onChange={(e) => {
														setImageDesktop([]);
														setimageTypeDesktop(
															"gif"
														);
													}}
												// {...register("image_format_desktop")}
												/>
												<label
													for="radioButton2"
													className="pl-3 text-base font-medium text-gray-400"
												>
													Gif
												</label>
											</div>
										</div>
										{imageTypeDesktop === "single" ||
											imageTypeDesktop === "gif" ? (
											<>
												{imageDesktop.length > 0 ? (
													<div class="image-preview relative">
														<img
															src={
																imageDesktop[0]
															}
															alt=""
														/>
														<button
															onClick={() =>
																setImageDesktop(
																	[]
																)
															}
															className="btn absolute top-0 right-0 z-40 bg-white"
														>
															<i className="dropdown-icon ri-delete-bin-6-line"></i>
														</button>
													</div>
												) : (
													<div class="flex">
														<label
															for="file-upload"
															class="upload-button"
														>
															<i class="ri-file-add-line"></i>
														</label>

														<input
															id="file-upload"
															type="file"
															name="desktop_image"
															size="large"
															onChange={
																handleImageDesktop
															}
															accept="image/*"
															hidden
														/>
													</div>
												)}
											</>
										) : (
											imageTypeDesktop === "album" && (
												<div className="flex flex-wrap gap-4">
													{imageDesktop.length > 0 &&
														imageDesktop?.map(
															(image, i) => (
																<div
																	key={i}
																	class="image-preview relative"
																>
																	<img
																		src={
																			image
																		}
																		alt=""
																	/>
																	<button
																		onClick={() =>
																			setImageDesktop(
																				imageDesktop?.filter(
																					(
																						img
																					) =>
																						img !==
																						image
																				)
																			)
																		}
																		className="btn absolute top-0 right-0 z-40 bg-white"
																	>
																		<i className="dropdown-icon ri-delete-bin-6-line"></i>
																	</button>
																</div>
															)
														)}

													<div class="flex items-center justify-center">
														<label
															for="file-upload"
															class="upload-button"
														>
															<i class="ri-file-add-line"></i>
														</label>
														<input
															id="file-upload"
															type="file"
															multiple
															name="desktop_image"
															size="large"
															onChange={
																handleImageDesktop
															}
															accept="image/*"
															hidden
														/>
													</div>
												</div>
											)
										)}
									</div>
									<div>
										<label className="mb-3 block text-base font-medium text-[#07074D]">
											Mobile Image
										</label>
										<div className="flex items-center space-x-6 mb-5">
											<div className="flex items-center">
												<input
													type="radio"
													name="image_format_mobile"
													className="h-5 w-5"
													value={"single"}
													onChange={(e) => {
														setImageMObile([]);
														setimageTypeMobile(
															"single"
														);
													}}
												// {...register("image_format")}
												/>
												<label
													for="radioButton1"
													className="pl-3 text-base font-medium text-gray-400"
												>
													Single
												</label>
											</div>
											<div className="flex items-center">
												<input
													type="radio"
													name="image_format_mobile"
													className="h-5 w-5"
													value={"album"}
													onChange={(e) => {
														setImageMObile([]);
														setimageTypeMobile(
															"album"
														);
													}}
												// {...register("image_format")}
												/>
												<label
													for="radioButton2"
													className="pl-3 text-base font-medium text-gray-400"
												>
													Album
												</label>
											</div>
											<div className="flex items-center">
												<input
													type="radio"
													name="image_format_mobile"
													className="h-5 w-5"
													value={"gif"}
													onChange={(e) => {
														setImageMObile([]);
														setimageTypeMobile(
															"gif"
														);
													}}
												// {...register("image_format")}
												/>
												<label
													for="radioButton2"
													className="pl-3 text-base font-medium text-gray-400"
												>
													Gif
												</label>
											</div>
										</div>
										{imageTypeMobile === "single" ||
											imageTypeMobile === "gif" ? (
											<>
												{imageMobile.length > 0 ? (
													<div class="image-preview relative">
														<img
															src={imageMobile[0]}
															alt=""
														/>
														<button
															onClick={() =>
																setImageMObile(
																	[]
																)
															}
															className="btn absolute top-0 right-0 z-40 bg-white"
														>
															<i className="dropdown-icon ri-delete-bin-6-line"></i>
														</button>
													</div>
												) : (
													<div class="flex">
														<label
															for="file-upload2"
															class="upload-button"
														>
															<i class="ri-file-add-line"></i>
														</label>

														<input
															id="file-upload2"
															type="file"
															name="mobile_image"
															size="large"
															onChange={
																handleImageMobile
															}
															accept="image/*"
															hidden
														/>
													</div>
												)}
											</>
										) : (
											imageTypeMobile === "album" && (
												<div className="flex flex-wrap gap-4">
													{imageMobile.length > 0 &&
														imageMobile?.map(
															(image, i) => (
																<div
																	key={i}
																	class="image-preview relative"
																>
																	<img
																		src={
																			image
																		}
																		alt=""
																	/>
																	<button
																		onClick={() =>
																			setImageMObile(
																				imageMobile?.filter(
																					(
																						img
																					) =>
																						img !==
																						image
																				)
																			)
																		}
																		className="btn absolute top-0 right-0 z-40 bg-white"
																	>
																		<i className="dropdown-icon ri-delete-bin-6-line"></i>
																	</button>
																</div>
															)
														)}

													<div class="flex items-center justify-center">
														<label
															for="file-upload2"
															class="upload-button"
														>
															<i class="ri-file-add-line"></i>
														</label>
														<input
															id="file-upload2"
															multiple
															type="file"
															name="mobile_image"
															size="large"
															onChange={
																handleImageMobile
															}
															accept="image/*"
															hidden
														/>
													</div>
												</div>
											)
										)}
									</div>
								</div>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="mb-3 block text-base font-medium text-[#07074D]">
											Desktop Image Alt tag
										</label>
										<input
											type="text"
											placeholder="provide alt text for desktop images"
											{...register("desktop_alt_tag")}
											className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
										/>
									</div>
									<div>
										<label className="mb-3 block text-base font-medium text-[#07074D]">
											Mobile Image Alt tag
										</label>
										<input
											type="text"
											placeholder="provide alt text for mobile images"
											{...register("mobile_alt_tag")}
											className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
										/>
									</div>
								</div>
							</div>
						)}
						{(storeBankOffers && storeBankOffers.length > 0) && (
							<div>
								<label className="mb-3 block text-base font-medium mt-5 text-[#07074D]">
									Bank Ofers
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											value={true}
											onChange={(e) => setIsBankOffers(true)}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Yes
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											value={false}
											onChange={(e) => setIsBankOffers(false)}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											No
										</label>
									</div>
								</div>
								{isBankOffers && (
									<Select
										components={animatedComponents}
										isMulti
										name="tabs"
										className="basic-multi-select"
										placeholder="select Bank "
										classNamePrefix="select"
										closeMenuOnSelect={false}
										options={storeBankOffers}
										value={selectedBank}
										onChange={(e) => setSelectedBank(e)}
									/>
								)}
							</div>

						)}


						<div>
							<label className="mb-3 block text-base font-medium mt-5 text-[#07074D]">
								Sections (details)
							</label>
							<div className="flex items-center space-x-6 mb-5">
								<div className="flex items-center">
									<input
										type="radio"
										name="tabs"
										className="h-5 w-5"
										value={true}
										onChange={(e) => setIsTabs(true)}
									/>
									<label
										for="radioButton1"
										className="pl-3 text-base font-medium text-gray-400"
									>
										Add sections
									</label>
								</div>
								<div className="flex items-center">
									<input
										type="radio"
										name="tabs"
										className="h-5 w-5"
										value={false}
										onChange={(e) => setIsTabs(false)}
									/>
									<label
										for="radioButton2"
										className="pl-3 text-base font-medium text-gray-400"
									>
										No section required
									</label>
								</div>
							</div>
							{isTabs && (
								<Select
									components={animatedComponents}
									isMulti
									name="tabs"
									className="basic-multi-select"
									placeholder="select the tabs to display..."
									classNamePrefix="select"
									closeMenuOnSelect={false}
									options={tabsOption}
									value={selectedTabs}
									onChange={(e) => setSelectedTabs(e)}
								/>
							)}
						</div>
						<div className="grid grid-cols-1 my-4">
							{isTabs && (
								<>
									{selectedTabs?.map((tab, i) => (
										<details key={i} className="group">
											<summary className="flex items-center font-medium cursor-pointer list-none bg-[#F5F5F5] p-2">
												<span className="transition group-open:rotate-180">
													<svg
														width="23"
														height="22"
														viewBox="0 0 23 22"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M11.7001 0.057373C5.65134 0.057373 0.750488 4.95823 0.750488 11.007C0.750488 17.0558 5.65134 21.9567 11.7001 21.9567C17.7489 21.9567 22.6498 17.0558 22.6498 11.007C22.6498 4.95823 17.7489 0.057373 11.7001 0.057373ZM18.058 12.2433C18.058 12.5347 17.8196 12.7731 17.5282 12.7731H13.4662V16.8351C13.4662 17.1265 13.2278 17.3649 12.9364 17.3649H10.4639C10.1725 17.3649 9.93407 17.1265 9.93407 16.8351V12.7731H5.8721C5.5807 12.7731 5.34228 12.5347 5.34228 12.2433V9.77078C5.34228 9.47938 5.5807 9.24096 5.8721 9.24096H9.93407V5.17899C9.93407 4.88758 10.1725 4.64916 10.4639 4.64916H12.9364C13.2278 4.64916 13.4662 4.88758 13.4662 5.17899V9.24096H17.5282C17.8196 9.24096 18.058 9.47938 18.058 9.77078V12.2433Z"
															fill="black"
														/>
													</svg>
												</span>
												<span className="ml-4">
													{tab?.value} Content Below
													the fold
												</span>
											</summary>
											<div>
												<JoditEditor
													ref={editor}
													// value={tab?.value}
													config={config}
													onChange={(value) => { }}
													onBlur={(content) =>
														handleContentChange(
															{
																tab: tab?.value,
																content,
															},
															i
														)
													}
												/>
											</div>
										</details>
									))}
								</>
							)}
						</div>
						<div className="grid grid-cols-2 gap-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Admin votes
								</label>
								<input
									type="number"
									placeholder="enter admin votes"
									min={"0"}
									onKeyDown={preventMinus}
									{...register("admin_votes")}
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
								/>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Admin clicks
								</label>
								<input
									type="number"
									placeholder="enter admin clicks"
									min={"0"}
									onKeyDown={preventMinus}
									{...register("admin_clicks")}
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
								/>
							</div>
						</div>

						<div className="grid grid-cols-2  my-4 gap-5">


							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Offer Text 1
								</label>
								<div>
									<JoditEditor
										ref={editor}
										value={offertext1}
										config={config}
										onChange={(value) => { }}
										onBlur={(content) =>
											setOffertext1(content)
										}
									/>
								</div>
							</div>



							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Offer Text 2
								</label>
								<div>
									<JoditEditor
										ref={editor}
										value={offertext2}
										config={config}
										onChange={(value) => { }}
										onBlur={(content) =>
											setOffertext2(content)
										}
									/>
								</div>
							</div>
						</div>

						<div className="mt-5">
							<button
								type="submit"
								className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
							>
								{isLoading ? "Saving..." : "Save"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default AddCoupon;
