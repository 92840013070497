import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const router = useNavigate();

  const { user, isError, isLoading, isSuccess } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user) {
      router("/dashboard");
    }
  }, [user, router]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handleLogin = (data) => {
    localStorage.setItem("rezgari", JSON.stringify(data));
    router("/dashboard");
  };

  if (isLoading) {
    return <div className="flex justify-center items-center">Loading...</div>;
  }

  return (
    <div className="flex min-h-full items-center justify-center h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div className="border-t-4 border-red-600 rounded-full">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-red-600">
            REZ<span className="text-blue-900">GARI</span>
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600 font-medium text-indigo-900 hover:text-indigo-500">
            Welcome to Rezgari Admin Dashboard
          </p>
        </div>
        {/* @ts-ignore */}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(handleLogin)}>
          <input type="hidden" name="remember" value="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="new_password" className="sr-only">
                New password
              </label>
              <input
                id="new_password-address"
                type="password"
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="new password"
                {...register("new_password", { required: true })}
              />
              {errors.new_password && (
                <span className="text-red-600">New password is required</span>
              )}
            </div>
            <div className="mt-5">
              <label htmlFor="confirm_password" className="sr-only">
                Confirm password
              </label>
              <input
                id="confirm_password"
                type="password"
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="confirm password"
                {...register("confirm_password", { required: true })}
              />
              {errors.confirm_password && (
                <span className="text-red-600">
                  Confirm password is required
                </span>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
