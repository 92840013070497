import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storeService from "./stores.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  stores: [],
  store: null,
};

//add new stores
export const addStore = createAsyncThunk(
  "stores/add",
  async function (stores, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await storeService.addStore(token, stores);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all stores
export const getAllStores = createAsyncThunk(
  "stores/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await storeService.getAllStores(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an stores
export const getStore = createAsyncThunk(
  "stores/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await storeService.getStore(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a stores
export const updateStore = createAsyncThunk(
  "stores/update",
  async function (stores, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await storeService.updateStore(stores.data, stores.id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// DEL a stores
export const deleteStore = createAsyncThunk(
  "stores/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await storeService.deleteStore(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for stores
const storeslice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addStore.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(addStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getStore.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.store = action.payload;
      })
      .addCase(getStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })

      .addCase(getAllStores.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllStores.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stores = action.payload;
      })
      .addCase(getAllStores.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(updateStore.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.store = action.payload;
        state.isSuccess = true;
      })
      .addCase(updateStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteStore.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.store = action.payload;
        state.isSuccess = true;
      })
      .addCase(deleteStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = storeslice.actions;
export default storeslice.reducer;
