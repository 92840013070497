import React, { useEffect, useState } from "react";
import { faker } from "@faker-js/faker";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import DeleteModal from "../../components/DeleteModal";
import StarPicker from "react-star-picker";
import moment from "moment";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteReview,
	getAllReviews,
	publishReview,
	reset,
} from "../../redux/reviews/review.slices";

const ReviewHome = () => {
	const dispatch = useDispatch();

	const [reviewsFiltered, setreviewsFiltered] = useState([]);
	const [showModal, setShowModal] = React.useState(false);
	const [rating, setRating] = useState(3.5);
	const [single, setsingle] = useState("");
	const [keyword, setKeyword] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(15);

	const { reviews, isLoading, isSuccess, isError } = useSelector(
		(state) => state.review
	);

	const onChange = (value) => {
		setRating(value);
	};

	useEffect(() => {
		dispatch(getAllReviews());
	}, []);

	useEffect(() => {
		if (isSuccess) {
			setShowModal(false);
			dispatch(reset());
			dispatch(getAllReviews());
		}
	}, [dispatch, isSuccess]);

	useEffect(() => {
		const re = new RegExp(keyword, "i");

		setreviewsFiltered(
			reviews?.filter((entry) =>
				Object.values(entry).some(
					(val) => typeof val == "string" && val.match(re)
				)
			)
		);
	}, [keyword, reviews]);

	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = reviewsFiltered.slice(
		indexOfFirstRecord,
		indexOfLastRecord
	);
	const nPages = Math.ceil(reviewsFiltered.length / recordsPerPage);

	function handleDelete() {
		dispatch(deleteReview(single));
	}

	return (
		<>
			<section className="container mx-auto p-6 font-mono">
				<div className="flex my-auto items-center justify-between">
					<SearchInput
						autoComplete="on"
						onChange={(e) => setKeyword(e.target.value)}
						value={keyword}
					/>
					<h1 className="text-2xl text-red-700 underline">Reviews</h1>
					{/* <Link
            class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
            // onClick={() => setIsOpen(!modalIsOpen)}
            to={"/reviews/add"}
          >
            ADD
          </Link> */}
				</div>

				<div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
					<div className="w-full overflow-x-auto">
						<table className="w-full">
							<thead>
								<tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
									<th className="px-4 py-3">Ratings</th>
									<th className="px-4 py-3">Created</th>
									<th className="px-4 py-3">Reviews</th>
									<th className="px-4 py-3">Source</th>
									<th className="px-4 py-3">Status</th>
									<th className="px-4 py-3">Actions</th>
								</tr>
							</thead>
							<tbody className="bg-white">
								{isLoading ? (
									<tr>
										<td colSpan={6}>
											<Loader />
										</td>
									</tr>
								) : currentRecords.length <= 0 ? (
									<tr>
										<td
											className="flex m-5 col-span-6"
											colSpan={6}
										>
											No records found
										</td>
									</tr>
								) : (
									currentRecords.map((review) => (
										<>
											<tr className="text-gray-700">
												<td className="px-4 py-3 border">
													<div className="flex items-center text-sm">
														<p className="font-semibold text-black">
															<StarPicker
																onChange={
																	onChange
																}
																value={
																	review?.ratings
																}
															/>
														</p>
													</div>
												</td>

												<td className="px-4 py-3 text-ms font-semibold border">
													{moment(
														review?.createdAt
													).format("YYYY/MM/DD")}
												</td>
												<td className="px-4 py-3 text-xs border">
													{review?.comment}
												</td>
												<td className="px-4 py-3 text-xs border">
													<span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">
														email
													</span>
												</td>
												<td className="px-4 py-3 text-sm border">
													{/* <buton
                            class="block text-black ring-1 ring-black my-4 hover:text-white hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center"
                            type="button"
                            // onClick={() => setIsOpen(!modalIsOpen)}
                          >
                            {review?.is_aprove ? "Hide" : "Show"}
                          </buton> */}
													<button
														class="block text-black ring-1 ring-black my-4 hover:text-white hover:bg-yellow-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center"
														type="button"
														onClick={() =>
															dispatch(
																publishReview({
																	id: review?._id,
																	data: {},
																})
															)
														}
													>
														{review?.is_aprove
															? "Unpublish"
															: "publish"}
													</button>
												</td>
												<td className="px-4 py-3 text-sm border">
													<div className="flex gap-5">
														<Link
															to={`/reviews/${review?._id}/edit`}
															className="btn dropdown-item"
														>
															<i className="dropdown-icon ri-edit-line text-2xl text-green-800"></i>
														</Link>
														{/* <Link
                              to={`/reviews/${review?._id}/edit`}
                              className="btn dropdown-item"
                            >
                              <i className="dropdown-icon ri-question-answer-fill text-2xl text-red-800"></i>
                            </Link> */}
														<button
															onClick={() => {
																setsingle(
																	review?._id
																);
																setShowModal(
																	!showModal
																);
															}}
															className="btn dropdown-item"
														>
															<i className="dropdown-icon ri-chat-delete-line text-2xl text-red-800"></i>
														</button>
													</div>
												</td>
											</tr>
											{showModal && (
												<>
													<DeleteModal
														toggle={() =>
															setShowModal(
																!showModal
															)
														}
														isLoading={isLoading}
														onDeleteClick={
															handleDelete
														}
													/>
													<div className="opacity-5 fixed inset-0 z-40 bg-black"></div>
												</>
											)}
										</>
									))
								)}
							</tbody>
							<Pagination
								nPages={nPages}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/>
						</table>
					</div>
				</div>
			</section>
			{/* <AddCategort
        isOpen={modalIsOpen}
        toggle={() => setIsOpen(!modalIsOpen)}
      /> */}
		</>
	);
};

export default ReviewHome;
