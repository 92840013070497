import { toast } from "react-toastify";

export const copyToClipboard = async ({ text }) => {
	try {
		await navigator.clipboard.writeText(text);
		toast.success("Copied to clipboard");
	} catch (error) {
		toast.success("Failed to copy");
	}
};
