import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {},
};

export function LineChart({ clicks }) {
  const data = {
    labels: clicks?.map((cl) => cl?._id?.year + "/" + cl?._id?.month),
    datasets: [
      {
        label: "Monthly Clicks on coupons",
        data: clicks?.map((cl) => cl?.totalClicks),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line options={options} data={data} className="w-full h-auto" />;
}
