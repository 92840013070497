import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import DeleteModal from "../../components/DeleteModal";
import {
  addTag,
  deleteTag,
  getAllTags,
  reset,
  updateTag,
} from "../../redux/tags/tag.slices";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

export default function TagsHome() {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    reset: resetForm,
    formState: { errors },
  } = useForm();

  const [categoriesFiltered, setCategoriesFiltered] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState("");
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const [tagName, setTagName] = useState("");
  const [tagID, setTagID] = useState("");
  const [single, setSingle] = useState(null);

  const { tags, isLoading, isSuccess } = useSelector((state) => state.tag);
  console.log("MMMMMMMMMMM55", tags)

  useEffect(() => {
    if (isSuccess) {
      toast.success("success");
      dispatch(reset());
      dispatch(getAllTags());
      setShowModal(false);
      setShowEdit("");
      setTagName("");
      resetForm();
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(getAllTags());
  }, []);

  useEffect(() => {
    const re = new RegExp(keyword, "i");

    setCategoriesFiltered(
      tags?.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val == "string" && val.match(re)
        )
      )
    );
  }, [keyword, tags]);

  function handleSave(data) {
    dispatch(addTag({ ...data }));
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = categoriesFiltered?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(categoriesFiltered?.length / recordsPerPage);

  function handleUpdate() {
    dispatch(
      updateTag({
        id: showEdit,
        data: { name: tagName },
      })
    );
  }

  function handleDelete() {
    dispatch(deleteTag(single));
  }

  return (
    <>
      <section className="container mx-auto p-6 font-mono">
        <div className="flex items-center justify-between my-4 w-full">
          <SearchInput
            autoComplete="on"
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />
          <h1 className="text-2xl text-red-700 underline">Tags</h1>

          <form onSubmit={handleSubmit(handleSave)}>
            <div className="flex gap-2">
              <div className="w-full">
                <input
                  type="text"
                  placeholder="enter tag name"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("name", { required: true })}
                />
                {/* {errors.name && (
                  <span className="text-red-700">Name is required</span>
                )} */}
              </div>

              <button
                type="submit"
                className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
              >
                {isLoading ? "saving..." : "Save"}
              </button>
            </div>
          </form>
        </div>

        <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-900">
                  <th className="px-4 py-3">ID</th>
                  <th className="px-4 py-3">Tag Name</th>
                  <th className="px-4 py-3">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {isLoading ? (
                  <tr>
                    <td colSpan={3}>
                      <Loader />
                    </td>
                  </tr>
                ) : currentRecords?.length <= 0 ? (
                  <tr>
                    <td className="flex m-5 col-span-6" colSpan={3}>
                      No records found
                    </td>
                  </tr>
                ) : (
                  currentRecords?.map((tag, i) => (
                    <tr className="text-gray-700" key={i}>
                      <td className="px-4 py-3 border">
                        <div className="flex items-center text-sm">
                          <p className="font-semibold text-black">0{(i + 1) + ((currentPage - 1) * 15)}</p>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-ms font-semibold border">
                        {showEdit === tag?._id ? (
                          <div className="flex gap-2">
                            <div className="w-full">
                              <input
                                type="text"
                                // placeholder={tag?.name}
                                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                                value={tagName}
                                onChange={(e) => setTagName(e.target.value)}
                              />
                            </div>

                            <button
                              onClick={handleUpdate}
                              className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
                            >
                              {isLoading ? "Update..." : "Update"}
                            </button>
                          </div>
                        ) : (
                          <span>{tag?.name} </span>
                        )}
                      </td>

                      <td className="px-4 py-3 text-sm border">
                        <div className="flex gap-5">
                          <button
                            onClick={() => {
                              setSingle(tag?._id);
                              setShowModal(!showModal);
                            }}
                            className="btn dropdown-item"
                          >
                            <i className="dropdown-icon ri-delete-bin-6-line"></i>
                          </button>
                          <button
                            onClick={() => {
                              setShowEdit(tag?._id);
                              setTagName(tag?.name);
                              setTagID(tag?._id);
                            }}
                            className="btn dropdown-item"
                          >
                            <i className="dropdown-icon ri-edit-line"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
              <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </table>
          </div>
        </div>
        {showModal && (
          <>
            <DeleteModal
              onDeleteClick={handleDelete}
              toggle={() => setShowModal(!showModal)}
            />
            <div className=" opacity-5 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
      </section>
    </>
  );
}
