import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "video/";

// POST new Video
const addVideo = async (token, Video) => {
  const res = await axios.post(API_URL, Video, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.videos;
};

// GET all Video
const getAllVideos = async (token) => {
  const res = await axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.videos;
};

// GET an Video
const getVideo = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.video;
};

// Delete a Video
const deleteVideo = async (id, token) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an Video
const updateVideo = async (Video, id, token) => {
  const res = await axios.patch(API_URL + id, Video, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

const VideoService = {
  addVideo,
  getAllVideos,
  getVideo,
  updateVideo,
  deleteVideo,
};

export default VideoService;
