import { useState, useRef, useEffect, useMemo } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { addStore, reset } from "../../redux/stores/stores.slices";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { getAllCategories } from "../../redux/categories/categories.slices";
import { getAllFaqs } from "../../redux/faqs/faq.slices";
import { editorConfig } from "../../utils/editorConfig";

const animatedComponents = makeAnimated();

function AddSctore() {
  const navigate = useNavigate();
  const [contentAbove, setContentAbove] = useState(false);
  const [contentAboveValue, setContentAboveValue] = useState('');
  const [contentBelow, setContentBelow] = useState(false);
  const [isTabs, setIsTabs] = useState(false);
  const [isCasback, setIsCasback] = useState(false);
  const [isStoreBanner, setIsStoreBanner] = useState(false);
  const [isFaQs, setIsFaQs] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [checkedTabs, setCheckedTabs] = useState([]);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState(null);
  const [status, setStatus] = useState(null);
  const [allQuestions, setAllQuestions] = useState([]);
  const [imageMobile, setImageMObile] = useState([]);
  const [imageDesktop, setImageDesktop] = useState([]);
  const [box1ImageDesktop, setBox1ImageDesktop] = useState([]);
  const [box2ImageDesktop, setBox2ImageDesktop] = useState([]);
  const [box3ImageDesktop, setBox3ImageDesktop] = useState([]);
  const [box1imageTypeDesktop, setBox1imageTypeDesktop] = useState(null);
  const [box2imageTypeDesktop, setBox2imageTypeDesktop] = useState(null);
  const [box3imageTypeDesktop, setbox3imageTypeDesktop] = useState(null);
  const [description, setDescription] = useState(null);
  const [imageTypeDesktop, setimageTypeDesktop] = useState(null);
  const [imageTypeMobile, setimageTypeMobile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isNetwork, setIsNetwork] = useState(false);
  const [movApplicable, setMovApplicable] = useState(false);
  const [cashback, setCashback] = useState(null);
  const [isBankOffers, setIsBankOffers] = useState(false);
  const [selectedBank, setSelectedBank] = useState([]);
  const editor = useRef(null);
  const config = useMemo(() => editorConfig, []);
  const tabsOption = [
    { value: "Videos", label: "Videos" },
    { value: "Offers", label: "Offers" },
    { value: "Reviews", label: "Reviews" },
    { value: "Must read", label: "Must read" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllFaqs());
  }, []);

  const {
    handleSubmit,
    register,
    reset: resetForm,
    setValue,
    formState: { errors },
  } = useForm();

  const { categories } = useSelector((state) => state.category);
  const { isSuccess, isLoading } = useSelector((state) => state.store);
  const { faqs } = useSelector((state) => state.faq);

  const bankOption = [
    { value: "Kotak Bank", label: "Kotak Bank" },
    { value: "ICICI Bank", label: "ICICI Bank" },
  ];

  const [formData, setFormData] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added store successfully");
      resetForm();
      dispatch(reset());
      navigate("/stores");
    }
  }, [dispatch, isSuccess, navigate, resetForm]);

  useEffect(() => {
    if (!isTabs) {
      setSelectedTabs([]);
      setCheckedTabs([]);
      setFormData([]);
    }
    if (!contentAbove) {
      setValue("content_above", "");
    }
    if (!contentBelow) {
      setFormData([]);
    }
  }, [isTabs, contentBelow, contentAbove, isFaQs, setValue]);

  // const handleImageDesktop = (e) => {
  // 	// e.preventDefault();
  // 	setLoading(true);
  // 	try {
  // 		Array.from(e.target.files).map((i) => {
  // 			let file = i;
  // 			let formData = new FormData();
  // 			formData.append("image", file);
  // 			const config = {
  // 				headers: {
  // 					"content-type": "multipart/form-data",
  // 				},
  // 			};

  // 			axios.post(
  // 				process.env.REACT_APP_API_URL + `images`,
  // 				formData,
  // 				config
  // 			)
  // 				.then(({ data }) => {
  // 					setImageDesktop((prev) => [
  // 						...prev,
  // 						data?.data?.uploadedUrl,
  // 					]);
  // 					toast.success("Success");
  // 				});
  // 		});
  // 	} catch (error) {
  // 		toast.error(
  // 			error.response.data.message || error.response.data.error
  // 		);
  // 	} finally {
  // 		setLoading(false);
  // 	}
  // };

  const handleBox1ImageDesktop = (e) => {
    // e.preventDefault();
    setLoading(true);
    try {
      Array.from(e.target.files).map((i) => {
        let file = i;
        let formData = new FormData();
        formData.append("image", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        axios.post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
          .then(({ data }) => {
            setBox1ImageDesktop((prev) => [
              ...prev,
              // data?.data?.uploadedUrl,
              data?.data?.uploadedUrl
            ]);
            toast.success("Success");
          });
      });
    } catch (error) {
      toast.error(
        error.response.data.message || error.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const handleBox2ImageDesktop = (e) => {
    // e.preventDefault();
    setLoading(true);
    try {
      Array.from(e.target.files).map((i) => {
        let file = i;
        let formData = new FormData();
        formData.append("image", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        axios.post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
          .then(({ data }) => {
            setBox2ImageDesktop((prev) => [
              ...prev,
              // data?.data?.uploadedUrl,
              data?.data?.uploadedUrl
            ]);
            toast.success("Success");
          });
      });
    } catch (error) {
      toast.error(
        error.response.data.message || error.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const handleBox3ImageDesktop = (e) => {
    // e.preventDefault();
    setLoading(true);
    try {
      Array.from(e.target.files).map((i) => {
        let file = i;
        let formData = new FormData();
        formData.append("image", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        axios.post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
          .then(({ data }) => {
            setBox3ImageDesktop((prev) => [
              ...prev,
              // data?.data?.uploadedUrl,
              data?.data?.uploadedUrl
            ]);
            toast.success("Success");
          });
      });
    } catch (error) {
      toast.error(
        error.response.data.message || error.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleImageMobile = (e) => {
    // e.preventDefault();
    setLoading(true);
    try {
      Array.from(e.target.files).map((i) => {
        let file = i;

        let formData = new FormData();
        formData.append("image", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + `images`,
            formData,
            config
          )
          .then(({ data }) => {
            // set image in the state
            setImageMObile((prev) => [
              ...prev,
              // data?.data?.uploadedUrl,
              data?.data?.uploadedUrl
            ]);
            toast.success("Success");
          });
      });
      return;
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleContentChange = (content, index) => {
    setFormData([
      ...formData,
      {
        tab: content?.tab,
        desc: content?.content,
      },
    ]);
  };

  const handleImage = (e) => {
    // e.preventDefault();
    try {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("image", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios.post(
        process.env.REACT_APP_API_URL + `images`,
        formData,
        config
      ).then(({ data }) => {
        setImages([...images, data?.data?.uploadedUrl]);
        setImage(data?.data?.uploadedUrl);
        toast.success("Success");
      });
    } catch (error) {
      toast.error(
        error.response.data.message || error.response.data.error
      );
    }
  };

  function handleSave(data) {
    try {
      if (!image) {
        return toast.warn("Store icon is required");
      }
      if (!status) {
        return toast.warn("Status is required");
      }
      if (imageDesktop === "album" && imageDesktop?.length < 3) {
        return toast.warn("please provide atleast 3 images");
      }
      if (imageTypeMobile === "album" && imageMobile?.length < 3) {
        return toast.warn("please provide atleast 3 images");
      }

      const newtabs = selectedTabs?.map((t) => {
        return {
          tab: t.value,
          desc: "",
        };
      });

      const combinedArray = newtabs.concat(formData);

      const uniqueMembers = Object.values(
        combinedArray.reduce((acc, obj) => {
          acc[obj.tab] = obj;
          return acc;
        }, new Map())
      );
      let t = (cashback === "fixed") ? `fixed:20` : `percentage:23`
      let cashbackd = {};
      if (isCasback) {
        cashbackd = {
          // desktop_image: imageDesktop,
          box1_desktop_image: box1ImageDesktop,
          box2_desktop_image: box2ImageDesktop,
          box3_desktop_image: box3ImageDesktop,
          mobile_image: imageMobile,
          // image_format_desktop: imageTypeDesktop,
          box1_image_format_desktop: box1imageTypeDesktop,
          box2_image_format_desktop: box2imageTypeDesktop,
          box3_image_format_desktop: box3imageTypeDesktop,
          image_format_mobile: imageTypeMobile,
          integrationType: isNetwork ? "network" : 'direct',
          storeBanner: isStoreBanner,
          network: "",
          cashbackDetails: {
            cashbackType: cashback,
            [cashback]: "20",
            minimumOrderApplicable: movApplicable,
            minimumOrderValue: data?.minimum_order_value

          },
        }
      }

      let da = {
        ...data,
        ...cashbackd,
        icon: image,
        card_description: description,
        status,
        checked_tabs: checkedTabs,
        tabs: selectedTabs?.map((t) => t.value),
        offersTypes: isCasback ? "Cashback" : 'Coupon',
        is_content_above: contentAbove,
        is_tabs: isTabs,
        content_below: uniqueMembers,
        is_content_below: contentBelow,
        is_faqs: isFaQs,
        faqs: allQuestions?.map((al) => al?.value),
        is_bank_offers: isBankOffers,
        bankOffers: (selectedBank && selectedBank !== null && selectedBank.length > 0) ? selectedBank.map((tag) => tag.value) : null,
      }
      dispatch(
        addStore(
          da
        )
      );
    } catch (error) {
      console.error("Error occurred:", error);
      // Display an error message to the user
      toast.error("An error occurred while saving. Please try again later.");

      // Log the exact error details
      console.error("Exact error details:", error);
    }
  }

  return (
    <>
      <h1 className="text-3xl text-center text-red-600">Add new Store</h1>
      <div className="flex items-center justify-center p-12">
        <div className="mx-auto w-full">
          <form
            className="container px-24"
            onSubmit={handleSubmit(handleSave)}
          >
            <div className="grid grid-cols-3 gap-5">
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Status
                </label>
                <div className="flex items-center space-x-6 mb-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="status"
                      className="h-5 w-5"
                      value={"active"}
                      onChange={(e) =>
                        setStatus("active")
                      }
                    />
                    <label
                      htmlFor="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Active
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="status"
                      value={"inactive"}
                      onChange={(e) =>
                        setStatus("inactive")
                      }
                      className="h-5 w-5"
                    />
                    <label
                      htmlFor="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Inactive
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="status"
                      className="h-5 w-5"
                      value={"delete"}
                      onChange={(e) =>
                        setStatus("active")
                      }
                    />
                    <label
                      htmlFor="radioButton2"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Delete
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Name
                </label>
                <input
                  type="text"
                  placeholder="store name"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="text-red-700">
                    Name is required
                  </span>
                )}
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Slug
                </label>
                <input
                  type="text"
                  placeholder="Slug name"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("slug", { required: true })}
                />
                {errors.slug && (
                  <span className="text-red-700">
                    Slug is required
                  </span>
                )}
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  ID
                </label>
                <input
                  type="text"
                  placeholder="enter id e.g ST_002"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("storeId", { required: true })}
                />
                {errors.storeId && (
                  <span className="text-red-700">
                    Store ID is required
                  </span>
                )}
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Url
                </label>
                <input
                  type="text"
                  placeholder="enter carteegory Url e.g www.rezgari.com/foodoffers"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("url", { required: true })}
                />
                {errors.url && (
                  <span className="text-red-700">
                    URL is required
                  </span>
                )}
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Affiliate Url

                </label>
                <input
                  type="text"
                  placeholder="enter affilaite Url e.g www.mytra.com/MYT004"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("affiliate_url", { required: true })}
                />
                {errors.affiliate_url && (
                  <span className="text-red-700">
                    Affilate Url is required
                  </span>
                )}
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Store Icon
                </label>
                <div className="d-flex flex-col gap-4">
                  {image ? (
                    <div class="image-preview relative">
                      <img src={image} alt="" />
                      <button
                        onClick={() => setImage(null)}
                        className="btn absolute top-0 right-0 z-40 bg-white"
                      >
                        <i className="dropdown-icon ri-delete-bin-6-line"></i>
                      </button>
                    </div>
                  ) : (
                    <div class="flex">
                      <label
                        for="file-upload"
                        class="upload-button"
                      >
                        <i class="ri-file-add-line"></i>
                      </label>

                      <input
                        id="file-upload"
                        type="file"
                        name="profileImage"
                        size="large"
                        onChange={handleImage}
                        accept="image/*"
                        hidden
                      />
                    </div>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Alt tag text
                </label>
                <input
                  type="text"
                  placeholder="enter alt text here"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("icon_alt_tag")}
                />
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Category
                </label>

                <select
                  className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
                  {...register("categories", {
                    required: true,
                  })}
                >
                  <option></option>
                  {categories?.map((cat) => (
                    <option value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                {errors.categories && (
                  <span className="text-red-700">
                    Category is required
                  </span>
                )}
              </div>


            </div>
            <div>
              <label className="mb-3 block text-base font-medium mt-5 text-[#07074D]">
                Bank Ofers
              </label>
              <div className="flex items-center space-x-6 mb-5">
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="tabs"
                    className="h-5 w-5"
                    value={true}
                    onChange={(e) => setIsBankOffers(true)}
                  />
                  <label
                    for="radioButton1"
                    className="pl-3 text-base font-medium text-gray-400"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="tabs"
                    className="h-5 w-5"
                    value={false}
                    onChange={(e) => setIsBankOffers(false)}
                  />
                  <label
                    for="radioButton2"
                    className="pl-3 text-base font-medium text-gray-400"
                  >
                    No
                  </label>
                </div>
              </div>
              {isBankOffers && (
                <Select
                  components={animatedComponents}
                  isMulti
                  name="tabs"
                  className="basic-multi-select"
                  placeholder="select Bank "
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  options={bankOption}
                  value={selectedBank}
                  onChange={(e) => setSelectedBank(e)}
                />
              )}
            </div>
            <div>
              <label className="mb-3 block text-base font-medium text-[#07074D]">
                Offers Types
              </label>
              <div className="flex items-center space-x-6 mb-5">
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="isCasback"
                    className="h-5 w-5"
                    value={false}
                    onChange={(e) => setIsCasback(false)}
                  />
                  <label
                    for="radioButton1"
                    className="pl-3 text-base font-medium text-gray-400"
                  >
                    Coupon only
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="isCasback"
                    className="h-5 w-5"
                    value={true}
                    onChange={(e) => setIsCasback(true)}
                  />
                  <label
                    for="radioButton2"
                    className="pl-3 text-base font-medium text-gray-400"
                  >
                    Coupon + Cashback
                  </label>
                </div>
              </div>

            </div>
            {/********  start cashback Code  */}
            {isCasback &&
              (
                <>
                  <div>
                    <label className="mb-3 block text-base font-medium text-[#07074D]">
                      Store Banner
                    </label>
                    <div className="flex items-center space-x-6 mb-5">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="isBanner"
                          className="h-5 w-5"
                          value={true}
                          onChange={(e) => setIsStoreBanner(true)}
                        />
                        <label
                          for="radioButton1"
                          className="pl-3 text-base font-medium text-gray-400"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="isBanner"
                          className="h-5 w-5"
                          value={false}
                          onChange={(e) => setIsStoreBanner(false)}
                        />
                        <label
                          for="radioButton2"
                          className="pl-3 text-base font-medium text-gray-400"
                        >
                          No
                        </label>
                      </div>
                    </div>

                  </div>

                  {isStoreBanner && (
                    <div className="grid grid-cols-1 gap-5 mt-5">
                      <div className="grid grid-cols-2">
                        <div>
                          <label className="mb-3 block text-base font-medium text-[#07074D]">
                            For	Desktop Image
                          </label>
                          {/* box one single image start code  */}
                          <label className="mb-3 block text-base font-medium text-[#07074D]">
                            Box1 Image
                          </label>
                          <div className="flex items-center space-x-6 mb-5">
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="box1_image_format_desktop"
                                className="h-5 w-5"
                                value={"single"}
                                onChange={(e) => {
                                  setBox1ImageDesktop([]);
                                  setBox1imageTypeDesktop(
                                    "single"
                                  );
                                }}
                              />
                              <label
                                for="radioButton1"
                                className="pl-3 text-base font-medium text-gray-400"
                              >
                                Single
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="box1_image_format_desktop"
                                className="h-5 w-5"
                                value={"album"}
                                onChange={(e) => {
                                  setBox1ImageDesktop([]);
                                  setBox1imageTypeDesktop(
                                    "album"
                                  );
                                }}
                              />
                              <label
                                for="radioButton2"
                                className="pl-3 text-base font-medium text-gray-400"
                              >
                                Album
                              </label>
                            </div>

                          </div>
                          {box1imageTypeDesktop === "single" ||
                            box1imageTypeDesktop === "gif" ? (
                            <>
                              {box1ImageDesktop.length > 0 ? (
                                <div class="image-preview relative">
                                  <img
                                    src={
                                      box1ImageDesktop[0]
                                    }
                                    alt=""
                                  />
                                  <button
                                    onClick={() =>
                                      setBox1ImageDesktop(
                                        []
                                      )
                                    }
                                    className="btn absolute top-0 right-0 z-40 bg-white"
                                  >
                                    <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                  </button>
                                </div>
                              ) : (
                                <div class="flex">
                                  <label
                                    for="box1_file-upload"
                                    class="upload-button"
                                  >
                                    <i class="ri-file-add-line"></i>
                                  </label>

                                  <input
                                    id="box1_file-upload"
                                    type="file"
                                    name="box1_desktop_image"
                                    size="large"
                                    onChange={
                                      handleBox1ImageDesktop
                                    }
                                    accept="image/*"
                                    hidden
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            box1imageTypeDesktop === "album" && (
                              <div className="flex flex-wrap gap-4">
                                {box1ImageDesktop.length > 0 &&
                                  box1ImageDesktop?.map(
                                    (image, i) => (
                                      <div
                                        key={i}
                                        class="image-preview relative"
                                      >
                                        <img
                                          src={
                                            image
                                          }
                                          alt=""
                                        />
                                        <button
                                          onClick={() =>
                                            setBox1ImageDesktop(
                                              box1ImageDesktop?.filter(
                                                (
                                                  img
                                                ) =>
                                                  img !==
                                                  image
                                              )
                                            )
                                          }
                                          className="btn absolute top-0 right-0 z-40 bg-white"
                                        >
                                          <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                        </button>
                                      </div>
                                    )
                                  )}

                                <div class="flex items-center justify-center">
                                  <label
                                    for="box1_file-upload"
                                    class="upload-button"
                                  >
                                    <i class="ri-file-add-line"></i>
                                  </label>
                                  <input
                                    id="box1_file-upload"
                                    type="file"
                                    multiple
                                    name="box1_desktop_image"
                                    size="large"
                                    onChange={
                                      handleBox1ImageDesktop
                                    }
                                    accept="image/*"
                                    hidden
                                  />
                                </div>
                              </div>
                            )
                          )}
                          {/* box one single image end code  */}
                          {/* Box 2 image upload start code  */}

                          <label className="mb-3 block text-base font-medium text-[#07074D]">
                            Box2 Image
                          </label>

                          <div className="flex items-center space-x-6 mb-5">
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="box2_image_format_desktop"
                                className="h-5 w-5"
                                value={"single"}
                                onChange={(e) => {
                                  setBox2ImageDesktop([]);
                                  setBox2imageTypeDesktop(
                                    "single"
                                  );
                                }}
                              />
                              <label
                                for="radioButton1"
                                className="pl-3 text-base font-medium text-gray-400"
                              >
                                Single
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="box2_image_format_desktop"
                                className="h-5 w-5"
                                value={"album"}
                                onChange={(e) => {
                                  setBox2ImageDesktop([]);
                                  setBox2imageTypeDesktop(
                                    "album"
                                  );
                                }}
                              />
                              <label
                                for="radioButton2"
                                className="pl-3 text-base font-medium text-gray-400"
                              >
                                Album
                              </label>
                            </div>

                          </div>
                          {box2imageTypeDesktop === "single" ||
                            box2imageTypeDesktop === "gif" ? (
                            <>
                              {box2ImageDesktop.length > 0 ? (
                                <div class="image-preview relative">
                                  <img
                                    src={
                                      box2ImageDesktop[0]
                                    }
                                    alt=""
                                  />
                                  <button
                                    onClick={() =>
                                      setBox2ImageDesktop(
                                        []
                                      )
                                    }
                                    className="btn absolute top-0 right-0 z-40 bg-white"
                                  >
                                    <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                  </button>
                                </div>
                              ) : (
                                <div class="flex">
                                  <label
                                    for="box2_file-upload"
                                    class="upload-button"
                                  >
                                    <i class="ri-file-add-line"></i>
                                  </label>

                                  <input
                                    id="box2_file-upload"
                                    type="file"
                                    name="box2_desktop_image"
                                    size="large"
                                    onChange={
                                      handleBox2ImageDesktop
                                    }
                                    accept="image/*"
                                    hidden
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            box2imageTypeDesktop === "album" && (
                              <div className="flex flex-wrap gap-4">
                                {box2ImageDesktop.length > 0 &&
                                  box2ImageDesktop?.map(
                                    (image, i) => (
                                      <div
                                        key={i}
                                        class="image-preview relative"
                                      >
                                        <img
                                          src={
                                            image
                                          }
                                          alt=""
                                        />
                                        <button
                                          onClick={() =>
                                            setBox2ImageDesktop(
                                              box2ImageDesktop?.filter(
                                                (
                                                  img
                                                ) =>
                                                  img !==
                                                  image
                                              )
                                            )
                                          }
                                          className="btn absolute top-0 right-0 z-40 bg-white"
                                        >
                                          <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                        </button>
                                      </div>
                                    )
                                  )}

                                <div class="flex items-center justify-center">
                                  <label
                                    for="box2_file-upload"
                                    class="upload-button"
                                  >
                                    <i class="ri-file-add-line"></i>
                                  </label>
                                  <input
                                    id="box2_file-upload"
                                    type="file"
                                    multiple
                                    name="box2_desktop_image"
                                    size="large"
                                    onChange={
                                      handleBox2ImageDesktop
                                    }
                                    accept="image/*"
                                    hidden
                                  />
                                </div>
                              </div>
                            )
                          )}

                          {/* box 2 end code */}
                          {/* box 3 start code  */}
                          <label className="mb-3 block text-base font-medium text-[#07074D]">
                            Box3 Image
                          </label>
                          <div className="flex items-center space-x-6 mb-5">
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="box3_image_format_desktop"
                                className="h-5 w-5"
                                value={"single"}
                                onChange={(e) => {
                                  setBox3ImageDesktop([]);
                                  setbox3imageTypeDesktop(
                                    "single"
                                  );
                                }}
                              />
                              <label
                                for="radioButton1"
                                className="pl-3 text-base font-medium text-gray-400"
                              >
                                Single
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="box3_image_format_desktop"
                                className="h-5 w-5"
                                value={"album"}
                                onChange={(e) => {
                                  setBox3ImageDesktop([]);
                                  setbox3imageTypeDesktop(
                                    "album"
                                  );
                                }}
                              />
                              <label
                                for="radioButton2"
                                className="pl-3 text-base font-medium text-gray-400"
                              >
                                Album
                              </label>
                            </div>

                          </div>
                          {box3imageTypeDesktop === "single" ||
                            box3imageTypeDesktop === "gif" ? (
                            <>
                              {box3ImageDesktop.length > 0 ? (
                                <div class="image-preview relative">
                                  <img
                                    src={
                                      box3ImageDesktop[0]
                                    }
                                    alt=""
                                  />
                                  <button
                                    onClick={() =>
                                      setBox3ImageDesktop(
                                        []
                                      )
                                    }
                                    className="btn absolute top-0 right-0 z-40 bg-white"
                                  >
                                    <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                  </button>
                                </div>
                              ) : (
                                <div class="flex">
                                  <label
                                    for="box3_file-upload"
                                    class="upload-button"
                                  >
                                    <i class="ri-file-add-line"></i>
                                  </label>

                                  <input
                                    id="box3_file-upload"
                                    type="file"
                                    name="box3_desktop_image"
                                    size="large"
                                    onChange={
                                      handleBox3ImageDesktop
                                    }
                                    accept="image/*"
                                    hidden
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            box3imageTypeDesktop === "album" && (
                              <div className="flex flex-wrap gap-4">
                                {box3ImageDesktop.length > 0 &&
                                  box3ImageDesktop?.map(
                                    (image, i) => (
                                      <div
                                        key={i}
                                        class="image-preview relative"
                                      >
                                        <img
                                          src={
                                            image
                                          }
                                          alt=""
                                        />
                                        <button
                                          onClick={() =>
                                            setBox3ImageDesktop(
                                              box3ImageDesktop?.filter(
                                                (
                                                  img
                                                ) =>
                                                  img !==
                                                  image
                                              )
                                            )
                                          }
                                          className="btn absolute top-0 right-0 z-40 bg-white"
                                        >
                                          <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                        </button>
                                      </div>
                                    )
                                  )}

                                <div class="flex items-center justify-center">
                                  <label
                                    for="box3_file-upload"
                                    class="upload-button"
                                  >
                                    <i class="ri-file-add-line"></i>
                                  </label>
                                  <input
                                    id="box3_file-upload"
                                    type="file"
                                    multiple
                                    name="box3_desktop_image"
                                    size="large"
                                    onChange={
                                      handleBox3ImageDesktop
                                    }
                                    accept="image/*"
                                    hidden
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div>
                          <label className="mb-3 block text-base font-medium text-[#07074D]">
                            Mobile Image
                          </label>
                          <div className="flex items-center space-x-6 mb-5">
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="image_format_mobile"
                                className="h-5 w-5"
                                value={"single"}
                                onChange={(e) => {
                                  setImageMObile([]);
                                  setimageTypeMobile(
                                    "single"
                                  );
                                }}
                              // {...register("image_format")}
                              />
                              <label
                                for="radioButton1"
                                className="pl-3 text-base font-medium text-gray-400"
                              >
                                Single
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="image_format_mobile"
                                className="h-5 w-5"
                                value={"album"}
                                onChange={(e) => {
                                  setImageMObile([]);
                                  setimageTypeMobile(
                                    "album"
                                  );
                                }}
                              // {...register("image_format")}
                              />
                              <label
                                for="radioButton2"
                                className="pl-3 text-base font-medium text-gray-400"
                              >
                                Album
                              </label>
                            </div>

                          </div>
                          {imageTypeMobile === "single" ||
                            imageTypeMobile === "gif" ? (
                            <>
                              {imageMobile.length > 0 ? (
                                <div class="image-preview relative">
                                  <img
                                    src={imageMobile[0]}
                                    alt=""
                                  />
                                  <button
                                    onClick={() =>
                                      setImageMObile(
                                        []
                                      )
                                    }
                                    className="btn absolute top-0 right-0 z-40 bg-white"
                                  >
                                    <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                  </button>
                                </div>
                              ) : (
                                <div class="flex">
                                  <label
                                    for="file-upload2"
                                    class="upload-button"
                                  >
                                    <i class="ri-file-add-line"></i>
                                  </label>

                                  <input
                                    id="file-upload2"
                                    type="file"
                                    name="mobile_image"
                                    size="large"
                                    onChange={
                                      handleImageMobile
                                    }
                                    accept="image/*"
                                    hidden
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            imageTypeMobile === "album" && (
                              <div className="flex flex-wrap gap-4">
                                {imageMobile.length > 0 &&
                                  imageMobile?.map(
                                    (image, i) => (
                                      <div
                                        key={i}
                                        class="image-preview relative"
                                      >
                                        <img
                                          src={
                                            image
                                          }
                                          alt=""
                                        />
                                        <button
                                          onClick={() =>
                                            setImageMObile(
                                              imageMobile?.filter(
                                                (
                                                  img
                                                ) =>
                                                  img !==
                                                  image
                                              )
                                            )
                                          }
                                          className="btn absolute top-0 right-0 z-40 bg-white"
                                        >
                                          <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                        </button>
                                      </div>
                                    )
                                  )}

                                <div class="flex items-center justify-center">
                                  <label
                                    for="file-upload2"
                                    class="upload-button"
                                  >
                                    <i class="ri-file-add-line"></i>
                                  </label>
                                  <input
                                    id="file-upload2"
                                    multiple
                                    type="file"
                                    name="mobile_image"
                                    size="large"
                                    onChange={
                                      handleImageMobile
                                    }
                                    accept="image/*"
                                    hidden
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="mb-3 block text-base font-medium text-[#07074D]">
                            Desktop Image Alt tag
                          </label>
                          <input
                            type="text"
                            placeholder="provide alt text for desktop images"
                            {...register("desktop_alt_tag")}
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                          />
                        </div>
                        <div>
                          <label className="mb-3 block text-base font-medium text-[#07074D]">
                            Mobile Image Alt tag
                          </label>
                          <input
                            type="text"
                            placeholder="provide alt text for mobile images"
                            {...register("mobile_alt_tag")}
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                          />
                        </div>
                      </div>
                    </div>
                  )}


                  <div>
                    <label className="mb-3 block text-base font-medium text-[#07074D]">
                      Intigrated Type
                    </label>
                    <div className="flex items-center space-x-6 mb-5">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="isNetwork"
                          className="h-5 w-5"
                          value={false}
                          onChange={(e) => setIsNetwork(false)}
                        />
                        <label
                          for="radioButton1"
                          className="pl-3 text-base font-medium text-gray-400"
                        >
                          Direct
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="isNetwork"
                          className="h-5 w-5"
                          value={true}
                          onChange={(e) => setIsNetwork(true)}
                        />
                        <label
                          for="radioButton2"
                          className="pl-3 text-base font-medium text-gray-400"
                        >
                          Network
                        </label>
                      </div>
                    </div>

                  </div>

                  {isNetwork && (
                    <div>
                      <label className="mb-3 block text-base font-medium text-[#07074D]">
                        Network
                      </label>

                      <select
                        className="w-full rounded-md border border-[#e0e0e0] bg-white
						py-3 px-6 text-base font-medium text-[#6B7280] outline-none
						focus:border-[#003049] focus:shadow-md"
                        {...register("network", {
                          required: true,
                        })}
                      >
                        <option></option>
                        {categories?.map((cat) => (
                          <option value={cat._id}>
                            {cat.name}
                          </option>
                        ))}
                      </select>
                      {errors.categories && (
                        <span className="text-red-700">
                          Network is required
                        </span>
                      )}
                    </div>


                  )}

                  <div>
                    <label className="mb-3 block text-base font-medium text-[#07074D]">
                      Tracking Url
                    </label>
                    <input
                      type="text"
                      placeholder="enter carteegory Url e.g www.rezgari.com/foodoffers"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                      {...register("tracking_url", { required: true })}
                    />
                    {errors.url && (
                      <span className="text-red-700">
                        Tracking URL is required
                      </span>
                    )}
                  </div>


                  <div>
                    <label className="mb-3 block text-base font-medium text-[#07074D]">
                      Cashback Type
                    </label>
                    <div className="flex items-center space-x-6 mb-5">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="CashbackType"
                          className="h-5 w-5"
                          value="fixed"
                          onChange={(e) => setCashback("fixed")}
                        />
                        <label
                          for="radioButton1"
                          className="pl-3 text-base font-medium text-gray-400"
                        >
                          fixed
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="CashbackType"
                          className="h-5 w-5"
                          value="percentage"
                          onChange={(e) => setCashback("percentage")}
                        />
                        <label
                          for="radioButton2"
                          className="pl-3 text-base font-medium text-gray-400"
                        >
                          percentage
                        </label>
                      </div>
                    </div>

                  </div>

                  {cashback === "percentage" && (
                    <>
                      <div className="grid grid-cols-2 gap-5 mt-5">
                        <div>
                          <label className="mb-3 block text-base font-medium text-[#07074D]">
                            Percentage Cashback
                          </label>

                          <input
                            type="text"
                            placeholder="Enter Percentage Cashback  value "
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                            {...register("cashback_percentage", {
                              required: true,
                            })}
                          />
                          {errors.meta_title && (
                            <span className="text-red-700">
                              Percentage Cashback   is required
                            </span>
                          )}
                        </div>

                      </div>
                    </>
                  )}

                  {cashback === "fixed" && (
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div>
                        <label className="mb-3 block text-base font-medium text-[#07074D]">
                          Fixed : Enter Details
                        </label>

                        <input
                          type="text"
                          placeholder="enter Fixed Amount"
                          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                          {...register("cashback_fixed_value", {
                            required: true,
                          })}
                        />
                        {errors.meta_title && (
                          <span className="text-red-700">
                            Fixed Amount  is required
                          </span>
                        )}
                      </div>

                    </div>
                  )}


                  <div>
                    <label className="mb-3 block text-base font-medium text-[#07074D]">
                      Minimum order value applicable
                    </label>
                    <div className="flex items-center space-x-6 mb-5">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="minOr_val"
                          className="h-5 w-5"
                          value={true}
                          onChange={(e) => setMovApplicable(true)}
                        />
                        <label
                          for="radioButton1"
                          className="pl-3 text-base font-medium text-gray-400"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="minOr_val"
                          className="h-5 w-5"
                          value={false}
                          onChange={(e) => setMovApplicable(false)}
                        />
                        <label
                          for="radioButton2"
                          className="pl-3 text-base font-medium text-gray-400"
                        >
                          No
                        </label>
                      </div>
                    </div>

                  </div>

                  {movApplicable && (
                    <>
                      <div className="grid grid-cols-2 gap-5 mt-5">
                        <div>
                          <label className="mb-3 block text-base font-medium text-[#07074D]">
                            Enter Minimum order value
                          </label>

                          <input
                            type="text"
                            placeholder="Enter Minimum order value "
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                            {...register("minimum_order_value", {
                              required: true,
                            })}
                          />
                          {errors.meta_title && (
                            <span className="text-red-700">
                              Minimum order value  is required
                            </span>
                          )}
                        </div>

                      </div>
                    </>
                  )}

                  <div className="grid grid-cols-2 gap-5 mt-5">
                    <div>
                      <label className="mb-3 block text-base font-medium text-[#07074D]">
                        Steps to avail cashback
                      </label>
                      <textarea
                        rows="4"
                        placeholder="Type Steps to avail cashback"
                        className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                        {...register("step_to_avail_cashback", {
                          required: true,
                        })}
                      ></textarea>

                      {errors.description && (
                        <span className="text-red-700">
                          Card Description is required
                        </span>
                      )}
                    </div>
                  </div>

                </>
              )}
            {/******  cashback code end */}
            <div className="grid grid-cols-2 gap-5 mt-5">
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Card Header
                </label>

                <input
                  type="text"
                  placeholder="enter card title"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("card_header", {
                    required: true,
                  })}
                />
                {errors.meta_title && (
                  <span className="text-red-700">
                    Card title is required
                  </span>
                )}
              </div>

            </div>

            <div className="grid grid-cols-1 gap-5 mt-5">
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Content above fold
                </label>
                <div className="flex items-center space-x-6 mb-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="contentAbove"
                      className="h-5 w-5"
                      value={true}
                      onChange={(e) =>
                        setContentAbove(true)
                      }
                    />
                    <label
                      for="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Add content above
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="contentAbove"
                      className="h-5 w-5"
                      value={false}
                      onChange={(e) =>
                        setContentAbove(false)
                      }
                    />
                    <label
                      for="radioButton2"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Not required
                    </label>
                  </div>
                </div>
                {contentAbove && (
                  // <textarea
                  // 	rows="4"
                  // 	placeholder="Type category content above fold here"
                  // 	className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  // 	{...register("content_above")}
                  // ></textarea>
                  // 	<JoditEditor
                  // 	ref={editor}
                  // 	value={description}
                  // 	config={config}
                  // 	onChange={(value) => { }}
                  // 	onBlur={(content) =>
                  // 		setDescription(content)
                  // 	}

                  // />

                  <JoditEditor
                    ref={editor}
                    value={contentAboveValue}
                    config={config}
                    // onChange={(value) => { }}
                    onChange={(value) => setContentAboveValue(value)}
                    onBlur={() => setValue('content_above', contentAboveValue)}
                  />
                )}
              </div>

              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Tabs
                </label>
                <div className="flex items-center space-x-6 mb-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="tabs"
                      className="h-5 w-5"
                      value={true}
                      onChange={(e) => setIsTabs(true)}
                    />
                    <label
                      for="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Add tabs
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="tabs"
                      className="h-5 w-5"
                      value={false}
                      onChange={(e) => setIsTabs(false)}
                    />
                    <label
                      for="radioButton2"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      No tab required
                    </label>
                  </div>
                </div>
                {isTabs && (
                  <Select
                    components={animatedComponents}
                    isMulti
                    name="users"
                    className="basic-multi-select"
                    placeholder="select the tabs to display..."
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={tabsOption}
                    value={selectedTabs}
                    onChange={(e) => setSelectedTabs(e)}
                  />
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-5 mt-5">
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Content below fold
                </label>
                <div className="flex items-center space-x-6 mb-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="contentBelow"
                      className="h-5 w-5"
                      value={true}
                      onChange={(e) =>
                        setContentBelow(true)
                      }
                    />
                    <label
                      for="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Add content below
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="contentBelow"
                      className="h-5 w-5"
                      value={false}
                      onChange={(e) =>
                        setContentBelow(false)
                      }
                    />
                    <label
                      for="radioButton2"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Not required
                    </label>
                  </div>
                </div>
                {contentBelow && (
                  <div className="my-4">
                    {selectedTabs?.map((tab, i) => (
                      <details key={i} className="group">
                        <summary className="flex items-center font-medium cursor-pointer list-none bg-[#F5F5F5] p-2">
                          <span className="transition group-open:rotate-180">
                            <svg
                              width="23"
                              height="22"
                              viewBox="0 0 23 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.7001 0.057373C5.65134 0.057373 0.750488 4.95823 0.750488 11.007C0.750488 17.0558 5.65134 21.9567 11.7001 21.9567C17.7489 21.9567 22.6498 17.0558 22.6498 11.007C22.6498 4.95823 17.7489 0.057373 11.7001 0.057373ZM18.058 12.2433C18.058 12.5347 17.8196 12.7731 17.5282 12.7731H13.4662V16.8351C13.4662 17.1265 13.2278 17.3649 12.9364 17.3649H10.4639C10.1725 17.3649 9.93407 17.1265 9.93407 16.8351V12.7731H5.8721C5.5807 12.7731 5.34228 12.5347 5.34228 12.2433V9.77078C5.34228 9.47938 5.5807 9.24096 5.8721 9.24096H9.93407V5.17899C9.93407 4.88758 10.1725 4.64916 10.4639 4.64916H12.9364C13.2278 4.64916 13.4662 4.88758 13.4662 5.17899V9.24096H17.5282C17.8196 9.24096 18.058 9.47938 18.058 9.77078V12.2433Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          <span className="ml-4">
                            {tab?.value} Content
                            Below the fold
                          </span>
                        </summary>
                        <div>
                          <JoditEditor
                            ref={editor}
                            // value={tab}
                            config={config}
                            // name={tab.value}
                            onBlur={(content) =>
                              handleContentChange(
                                {
                                  tab: tab?.value,
                                  content,
                                },
                                i
                              )
                            }
                          // onBlur={(content) =>
                          //   handleContentChange({ tab, content }, i)
                          // }
                          />
                        </div>
                      </details>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mt-5">
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Card Header
                </label>

                <input
                  type="text"
                  placeholder="enter card title"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("card_header", {
                    required: true,
                  })}
                />
                {errors.meta_title && (
                  <span className="text-red-700">
                    Card title is required
                  </span>
                )}
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Description
                </label>
                <div>

                  <div>
                    <JoditEditor
                      ref={editor}
                      value={description}
                      config={config}
                      onChange={(value) => { }}
                      onBlur={(content) =>
                        setDescription(content)
                      }

                    />
                  </div>



                </div>
              </div>
              {/* <div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Store Card Description
								</label>
								<textarea
									rows="4"
									placeholder="Type card description here"
									className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("card_description", {
										required: true,
									})}
								></textarea>

								{errors.description && (
									<span className="text-red-700">
										Card Description is required
									</span>
								)}
							</div> */}

            </div>

            <div className="grid grid-cols-2 gap-5 mt-5">
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Meta title
                </label>

                <input
                  type="text"
                  placeholder="enter meta title"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("meta_title", {
                    required: true,
                  })}
                />
                {errors.meta_title && (
                  <span className="text-red-700">
                    Meta title is required
                  </span>
                )}
              </div>

              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Store Description
                </label>
                <textarea
                  rows="4"
                  placeholder="Type store description here"
                  className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("description", {
                    required: true,
                  })}
                ></textarea>

                {errors.description && (
                  <span className="text-red-700">
                    Description is required
                  </span>
                )}
              </div>
            </div>

            <div>
              <label className="mb-3 block text-base font-medium text-[#07074D]">
                FAQs
              </label>
              <div className="flex items-center space-x-6 mb-5">
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="faqs"
                    className="h-5 w-5"
                    value={true}
                    onChange={(e) => setIsFaQs(true)}
                  />
                  <label
                    for="radioButton1"
                    className="pl-3 text-base font-medium text-gray-400"
                  >
                    Active
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="faqs"
                    className="h-5 w-5"
                    value={false}
                    onChange={(e) => setIsFaQs(false)}
                  />
                  <label
                    for="radioButton2"
                    className="pl-3 text-base font-medium text-gray-400"
                  >
                    Inactive
                  </label>
                </div>
              </div>
              {isFaQs && (
                <div>
                  <Select
                    components={animatedComponents}
                    isMulti
                    name="users"
                    className="basic-multi-select"
                    placeholder="select the tabs to display..."
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={faqs?.map((faq) => ({
                      value: faq?._id,
                      label: faq?.question,
                      answer: faq?.answer,
                    }))}
                    value={allQuestions}
                    onChange={(e) => setAllQuestions(e)}
                  />
                </div>
              )}

              {allQuestions?.map((al, i) => (
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <div className="my-4">
                      Question {i + 1}
                    </div>
                    <textarea
                      rows="2"
                      placeholder="Type faq question"
                      className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                      value={al?.label}
                      disabled
                    ></textarea>
                  </div>
                  <div>
                    <div className="my-4">
                      Answer {i + 1}
                    </div>
                    <textarea
                      rows="2"
                      placeholder="Type faq answer"
                      className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                      value={al?.answer}
                      disabled
                    ></textarea>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-5">
              <button
                type="submit"
                className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddSctore;
