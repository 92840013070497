import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import videoService from "./video.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  videos: [],
  videos_graph: [],
  video: null,
};

//add new video
export const addVideo = createAsyncThunk(
  "videos/add",
  async function (video, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await videoService.addVideo(token, video);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all videos
export const getAllVideos = createAsyncThunk(
  "videos/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await videoService.getAllVideos(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an video
export const getVideo = createAsyncThunk(
  "videos/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await videoService.getVideo(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a video
export const updateVideo = createAsyncThunk(
  "videos/update",
  async function (video, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await videoService.updateVideo(video.data, video.id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// DEL a video
export const deleteVideo = createAsyncThunk(
  "videos/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await videoService.deleteVideo(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for videos
const videoSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addVideo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.video = action.payload;
      })
      .addCase(addVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getVideo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.video = action.payload;
      })
      .addCase(getVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })

      .addCase(getAllVideos.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllVideos.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videos = action.payload;
      })
      .addCase(getAllVideos.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(updateVideo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.video = action.payload;
        state.isSuccess = true;
      })
      .addCase(updateVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteVideo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.video = action.payload;
        state.isSuccess = true;
      })
      .addCase(deleteVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = videoSlice.actions;
export default videoSlice.reducer;
