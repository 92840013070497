import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import tagService from "./tag.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  tags: [],
  tags_graph: [],
  tag: null,
};

//add new tag
export const addTag = createAsyncThunk(
  "tags/add",
  async function (tag, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await tagService.addTag(token, tag);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all tags
export const getAllTags = createAsyncThunk(
  "tag",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await tagService.getAllTags(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an tag
export const getTag = createAsyncThunk(
  "tags/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await tagService.getTag(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a tag
export const updateTag = createAsyncThunk(
  "tags/update",
  async function (tag, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await tagService.updateTag(tag.data, tag.id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// DEL a tag
export const deleteTag = createAsyncThunk(
  "tags/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await tagService.deleteTag(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for tags
const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTag.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tag = action.payload;
      })
      .addCase(addTag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getTag.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tag = action.payload;
      })
      .addCase(getTag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })

      .addCase(getAllTags.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tags = action.payload;
      })
      .addCase(getAllTags.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(updateTag.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tag = action.payload;
        state.isSuccess = true;
      })
      .addCase(updateTag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteTag.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tag = action.payload;
        state.isSuccess = true;
      })
      .addCase(deleteTag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = tagSlice.actions;
export default tagSlice.reducer;
