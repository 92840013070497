import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateReview, reset } from "../../redux/reviews/review.slices";
import { StarPicker } from "react-star-picker";
function EditReview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { isLoading, isSuccess } = useSelector((state) => state.review);
  const { user } = useSelector((state) => state.auth);

  const [comment, setcomment] = useState(null);
  const [response, setresponse] = useState(null);
  const [ratings, setRatings] = useState(3.5);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "admin/review/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const review = json.review;
        setcomment(review.comment);
        setresponse(review.response);
        setRatings(review?.ratings);
      });
  }, [dispatch, id, user?.token]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Updated review successfully");
      dispatch(reset());
      navigate("/reviews");
    }
  }, [dispatch, isSuccess, navigate]);

  function handleSave(e) {
    e.preventDefault();
    dispatch(
      updateReview({
        id,
        data: {
          comment,
          response,
          ratings,
        },
      })
    );
  }

  return (
    <>
      <h1 className="text-3xl text-center text-red-600">
        Edit or reply review
      </h1>
      <div className="flex items-center justify-center p-12">
        <div className="mx-auto w-full">
          <form onSubmit={handleSave}>
            <div className="grid grid-cols-2 gap-5">
              <div>
                <div className="my-4">Comment</div>
                <textarea
                  rows="4"
                  placeholder="Type faq question"
                  className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  value={comment}
                  onChange={(e) => setcomment(e.target.value)}
                ></textarea>
              </div>
              <div>
                <div className="my-4">Response</div>
                <textarea
                  rows="4"
                  placeholder="Type faq answer"
                  className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  value={response}
                  onChange={(e) => setresponse(e.target.value)}
                ></textarea>
              </div>
              <div>
                <div className="my-4">Response</div>
                <StarPicker onChange={(e) => setRatings(e)} value={ratings} />
              </div>
            </div>
            <div className="mt-5">
              <button
                type="submit"
                className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditReview;
