import React from "react";
import { Link } from "react-router-dom";
import DeleteModal from "./DeleteModal";

export default function BlogCard({ data }) {
	return (
		<div className="lg:flex">
			<img
				className="object-cover w-full h-56 rounded-lg lg:w-64"
				src={data?.jetpack_featured_media_url}
				alt=""
			/>

			<div className="flex flex-col justify-between py-6 lg:mx-6">
				<Link
					to={`/blogs/${data?.id}`}
					className="text-xl font-semibold text-gray-800 hover:underline"
				>
					{data?.title?.rendered}
				</Link>
				<a
					href="#"
					className="text-xl font-thin text-gray-400 hover:underline truncate"
				>
					{/* <div></div>{" "}
					<div
						className="truncate overflow-ellipsis"
						dangerouslySetInnerHTML={{
							__html: data?.content?.rendered,
						}}
					/> */}
				</a>

				<span className="text-sm text-gray-500">{data?.date}</span>
			</div>
		</div>
	);
}
