import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import walletService from "./wallet.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  wallets: [],
};

//get all coupons
export const getAllWallet = createAsyncThunk(
  "wallet/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await walletService.getAllWallet(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
//get an coupon
export const approveCashback = createAsyncThunk(
  "wallet/aoorove",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await walletService.approveCashback(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for coupons
const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(approveCashback.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveCashback.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.coupon = action.payload;
      })
      .addCase(approveCashback.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getAllWallet.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllWallet.fulfilled, (state, action) => {
        console.log("BBBBBBBBBB", action);
        state.isLoading = false;
        state.wallets = action.payload;
      })
      .addCase(getAllWallet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      });
  },
});

export const { reset } = walletSlice.actions;
export default walletSlice.reducer;
