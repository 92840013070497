import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;

// POST new Faq
const addComments = async (token, Faq) => {
  const res = await axios.post(API_BASE_URL, Faq, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.faqs;
};

// GET all Faq
export const getComments = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getallRecentComment`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


// GET an Faq
export const getCommentsById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/recentComment/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a Faq
export const publishComments = async (id) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/recentComment/${id}/publish`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// UPDATE an Faq
export const unpublishComments = async (id) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/recentComment/${id}/unpublish`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const FaqService = {
  addComments,
  getComments,
  getCommentsById,
  publishComments,
  unpublishComments,
};

export default FaqService;
