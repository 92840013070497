// faqSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import faqService from "./question.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isDeleted: false,
  message: "",
  questions: [],
  question: null,
};

// add new question
export const addQuestion = createAsyncThunk(
  "questions/add",
  async function (Faq, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqService.addQuestions(token, Faq);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// get all questions
export const getQuestions = createAsyncThunk(
  "questions/all",
  async function (_, thunkAPI) {
    try {
      return await faqService.getQuestions();
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getComments = createAsyncThunk(
  "questions/all",
  async function (_, thunkAPI) {
    try {
      return await faqService.getComments();
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// get a question by id
export const getQuestionById = createAsyncThunk(
  "questions/details",
  async function (id, thunkAPI) {
    try {
      return await faqService.getQuestionById(id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// publish a question
export const publishQuestion = createAsyncThunk(
  "questions/publish",
  async function (id, thunkAPI) {
    try {
      return await faqService.publishQuestion(id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// unpublish a question
export const unpublishQuestion = createAsyncThunk(
  "questions/unpublish",
  async function (id, thunkAPI) {
    try {
      return await faqService.unpublishQuestion(id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// publish a comment on a question
export const publishComment = createAsyncThunk(
  "questions/comments/publish",
  async function ({ questionId, commentId }, thunkAPI) {
    try {
      return await faqService.publishComment(questionId, commentId);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// unpublish a comment on a question
export const unpublishComment = createAsyncThunk(
  "questions/comments/unpublish",
  async function ({ questionId, commentId }, thunkAPI) {
    try {
      return await faqService.unpublishComment(questionId, commentId);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// create slice for questions
const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.question = action.payload;
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(getQuestions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questions = action.payload;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(getQuestionById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestionById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.question = action.payload;
      })
      .addCase(getQuestionById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(publishQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(publishQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.question = action.payload;
        state.isSuccess = true;
      })
      .addCase(publishQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(unpublishQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unpublishQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.question = action.payload;
        state.isDeleted = true;
      })
      .addCase(unpublishQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(publishComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(publishComment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(publishComment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(unpublishComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unpublishComment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(unpublishComment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = questionSlice.actions;
export default questionSlice.reducer;
