import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainRoutes from "./routes/Routes";
import "jodit";
import "jodit/build/jodit.min.css";

function App() {
	return (
		<>
			<MainRoutes />
			<ToastContainer />
		</>
	);
}

export default App;
