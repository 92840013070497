import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// GET all Coupon
const getAllWallet = async (token) => {
  const res = await axios.get(`${API_URL}/getAllusersWallet`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

export const approveCashback = async (token, id) => {
  try {
    const response = await axios.put(`${API_URL}/cashback/approve/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const CouponService = {
  getAllWallet,
  approveCashback,
};

export default CouponService;
