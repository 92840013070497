import React, { useEffect, useState } from "react";
import { faker } from "@faker-js/faker";
import DeleteModal from "../../components/DeleteModal";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import BlogCard from "../../components/BlogCard";
import Loader from "../../components/Loader";

const BlogsHome = () => {
	const [blogs, setblogs] = useState([]);
	const [blogsFiltered, setblogsFiltered] = useState([]);
	const [loading, setLoading] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(15);

	useEffect(() => {
		const fetcher = async () => {
			setLoading(true);
			await fetch(
				`https://vps54436.inmotionhosting.com/~stageserver/rezgari/wp-json/wp/v2/posts`
			)
				.then((res) => res.json())
				.then((data) => setblogs(data))
				.finally(() => setLoading(false));
		};

		fetcher();
	}, []);

	useEffect(() => {
		const re = new RegExp(keyword, "i");

		setblogsFiltered(
			blogs?.filter((entry) =>
				Object.values(entry).some(
					(val) => typeof val == "string" && val.match(re)
				)
			)
		);
	}, [keyword, blogs]);

	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = blogsFiltered.slice(
		indexOfFirstRecord,
		indexOfLastRecord
	);
	const nPages = Math.ceil(blogsFiltered.length / recordsPerPage);

	return (
		<div>
			<div className="bg-gray-100 flex justify-center items-center">
				<div className="w-full bg-[#F77F00] rounded-lg p-14">
					<div>
						<h1 className="text-center font-bold text-white text-4xl">
							Search any blog by title
						</h1>
						<div className="flex flex-col justify-center items-center mx-auto my-4">
							<p className="mx-auto font-normal text-gray-700 text-center text-sm mt-2">
								To add new blog, visit wordpress console
							</p>
						</div>
						<div className="sm:flex items-center bg-white rounded-lg overflow-hidden px-2 py-1 justify-between w-1/3 mx-auto">
							<input
								className="text-base text-gray-400 flex-grow outline-none px-2 "
								type="text"
								placeholder="Search your blog title...."
								autoComplete="on"
								onChange={(e) => setKeyword(e.target.value)}
								value={keyword}
							/>
							<div className="ms:flex items-center px-2 rounded-lg space-x-4 mx-auto ">
								<button className="ring-blue-50 text-base rounded-lg px-4 py-2 font-thin">
									<i className="ri-search-line align-middle"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container p-6 w-full">
				{loading ? (
					<div className="w-full">
						<Loader />
					</div>
				) : currentRecords?.length <= 0 ? (
					<div className="w-full flex justify-center">
						<h1>OOPS! blog list empty</h1>
					</div>
				) : (
					<div className="grid grid-cols-1 gap-8 mt-8 md:mt-16 md:grid-cols-2 w-full">
						{currentRecords.map((v) => (
							<BlogCard data={v} />
						))}
					</div>
				)}
			</div>
			<Pagination
				nPages={nPages}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
		</div>
	);
};

export default BlogsHome;
