import { LineChart } from "../../components/LineChart";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useEffect, useState } from "react";
import { getAllCategories } from "../../redux/categories/categories.slices";
import { getAllStores } from "../../redux/stores/stores.slices";
import { getAllCoupons } from "../../redux/coupons/coupon.slices";
import { useDispatch, useSelector } from "react-redux";
import { getAllReviews } from "../../redux/reviews/review.slices";

const animatedComponents = makeAnimated();

export default function Home() {
  const dispatch = useDispatch();
  // const [selectedTabs1, setSelectedTabs1] = useState([]);
  // const [selectedTabs2, setSelectedTabs2] = useState([]);
  const [monthlyClicks, setMonthlyClicks] = useState(null);
  const [dailyClicks, setDailyClicks] = useState(null);

  const { stores } = useSelector((state) => state.store);
  const { coupons } = useSelector((state) => state.coupon);
  // const { categories } = useSelector((state) => state.category);
  const { user } = useSelector((state) => state.auth);
  const { reviews } = useSelector((state) => state.review);

  useEffect(() => {
    dispatch(getAllStores());
    dispatch(getAllCoupons());
    dispatch(getAllReviews());

    fetch(process.env.REACT_APP_API_URL + "reports/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setMonthlyClicks(json?.yearlyMonthlyReports);
        setDailyClicks(json?.dailyClicks);
      });
  }, []);

  const totalActualClicks = coupons?.reduce(
    (acc, curr) => (acc += curr?.actual_clicks),
    0
  );

  const totalAdminClicks = coupons?.reduce(
    (acc, curr) => (acc += curr?.admin_clicks),
    0
  );

  return (
    <>
      <main>
        <div className="grid grid-cols-4 gap-4  shadow-lg">
          <div className="block p-6 max-w-sm hover:border-t-8 border-blue-900">
            <h5 className="text-gray-500 text-xl leading-tight font-medium mb-2">
              Total Brands
            </h5>
            <p className="text-gray-700 text-base mb-4">{stores?.length}</p>
          </div>
          <div className="block p-6 max-w-sm hover:border-t-8 border-blue-900">
            <h5 className="text-gray-500 text-xl leading-tight font-medium mb-2">
              Total Offers
            </h5>
            <p className="text-gray-700 text-base mb-4">{coupons?.length}</p>
          </div>
          <div className="block p-6 max-w-sm hover:border-t-8 border-blue-900">
            <h5 className="text-gray-500 text-xl leading-tight font-medium mb-2">
              Actual total clicks
            </h5>
            <p className="text-gray-700 text-base mb-4">{totalActualClicks}</p>
          </div>
          <div className="block p-6 max-w-sm hover:border-t-8 border-blue-900">
            <h5 className="text-gray-500 text-xl leading-tight font-medium mb-2">
              Admin total clicks
            </h5>
            <p className="text-gray-700 text-base mb-4">{totalAdminClicks}</p>
          </div>
        </div>
        {/* @ts-ignore */}
        <div className="grid grid-cols-3 mt-10">
          <div className="p-6 col-span-2 " style={{ height: "70vh" }}>
            {/* <div className="grid grid-cols-2 gap-12 mb-6">
              <Select
                components={animatedComponents}
                isMulti={false}
                name="users"
                className="basic-multi-select"
                placeholder="select..."
                classNamePrefix="select"
                closeMenuOnSelect={false}
                options={firstOptions}
                value={selectedTabs1}
                onChange={(e) => setSelectedTabs1(e)}
              />
              <Select
                components={animatedComponents}
                isMulti={false}
                name="users"
                className="basic-multi-select"
                placeholder="select..."
                classNamePrefix="select"
                closeMenuOnSelect={false}
                options={secondOptions}
                value={selectedTabs2}
                onChange={(e) => setSelectedTabs2(e)}
              />
            </div> */}

            <div className="p-6 ring-1 ring-[#D62828] w-full bg-white">
              <LineChart clicks={monthlyClicks} />
            </div>
          </div>
          <div
            className="p-6 col-span-1 h-full items-end justify-center"
            style={{ height: "70vh" }}
          >
            <div className="ring-1 ring-[#003049] p-10">
              <div>
                <h2 className="text-xl text-red-900">Clicks for this week</h2>
                <h2 className="text-2xl text-gray-900 my-4">
                  {dailyClicks?.reduce(
                    (acc, curr) => (acc += curr?.totalClicks),
                    0
                  )}
                </h2>
              </div>
              <div>
                <h2 className="text-xl text-blue-900">Reviews for this week</h2>
                <h2 className="text-2xl text-gray-900 my-4">
                  {reviews?.length}
                </h2>
              </div>
              {/* <div>
                <h2 className="text-xl text-gray-400">Clicks for this week</h2>
                <h2 className="text-2xl text-gray-900 my-4">90</h2>
              </div> */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
