import React, { useEffect, useState } from "react";
import { faker } from "@faker-js/faker";
import Pagination from "../../components/Pagination";
import { Link, useLocation } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import DeleteModal from "../../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteCoupon,
	deleteManyCoupon,
	getAllCoupons,
	reset,
} from "../../redux/coupons/coupon.slices";
import { getAllCashback, approveCashback } from "../../redux/cashback/cashback.slices";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const Cashback = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [couponsFiltered, setcouponsFiltered] = useState([]);
	const [ids, setids] = useState([]);
	const [showModal, setShowModal] = React.useState(false);
	const [keyword, setKeyword] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(15);
	const [single, setsingle] = useState("");
	const { cashbacks, isLoading, isError, isSuccess } = useSelector((state) => state?.Cashback);

	useEffect(() => {
		dispatch(getAllCashback());
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success("Deleted successfully");
			dispatch(reset());
			dispatch(getAllCoupons());
			setShowModal(false);
		}
	}, [isSuccess]);

	function isSubstring(a, b) {
		const lowerCaseA = a.toLowerCase();
		const lowerCaseB = b.toLowerCase();

		return lowerCaseA.includes(lowerCaseB);
	}

	useEffect(() => {
		const re = new RegExp(keyword, "i");
		setcouponsFiltered(

			cashbacks?.filter((entry) => isSubstring(entry.brandName, keyword))
			// coupons?.filter((entry) =>
			// 	Object.values(entry).some(
			// 		(val) => typeof val == "string" && val.match(re)
			// 	)
			// )
		);
	}, [keyword, cashbacks]);



	useEffect(() => {
		// Extract 'page' parameter from the URL
		const searchParams = new URLSearchParams(location.search);
		const keyd = searchParams.get('searchkeyword') || "";
		setKeyword(keyd);
		const pageFromURL = parseInt(searchParams.get('page')) || 1;
		setCurrentPage(pageFromURL);

		// Fetch coupons based on the current page
		dispatch(getAllCashback(pageFromURL));
	}, [location.search, dispatch]);

	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = couponsFiltered?.slice(
		indexOfFirstRecord,
		indexOfLastRecord
	);
	const nPages = Math.ceil(couponsFiltered?.length / recordsPerPage);

	function handleDelete() {
		dispatch(deleteCoupon(single));
	}

	function handleDeleteMany() {
		dispatch(deleteManyCoupon(ids));
		// console.log(ids);
	}
	function approveCashbackByid(id) {
		dispatch(approveCashback(id));
	}

	return (
		<>
			<section className="container mx-auto p-6 font-mono">
				<div className="flex my-auto items-center justify-between">
					<SearchInput
						autoComplete="on"
						onChange={(e) => setKeyword(e.target.value)}
						value={keyword}
					/>
					<h1 className="text-2xl text-red-700 underline">Cashback activative </h1>
					<div className="flex gap-4">
						{/* <button
							disabled={ids.length === 0}
							class="block text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
							type="button"
							// onClick={handleDeleteMany}
						>
							DELETE
						</button> */}
						{/* <Link
							class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
							type="button"
							// onClick={() => setIsOpen(!modalIsOpen)}
							to={"/coupons/add"}
						>
							ADD
						</Link> */}
					</div>
				</div>

				<div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
					<div className="w-full overflow-x-auto">
						<table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
							<thead>
								<tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
									<th className="px-4 py-3">ID</th>
									<th className="px-4 py-3">Store</th>
									<th className="px-4 py-3">user Name</th>
									<th className="px-4 py-3">Click ID</th>
									<th className="px-4 py-3">Buy Amount</th>
									<th className="px-4 py-3">Cashback Amount</th>
									<th className="px-4 py-3">Type Of Payment</th>
									<th className="px-4 py-3">Date</th>
									<th className="px-4 py-3">Status</th>
									<th className="px-4 py-3">Actions</th>
								</tr>
							</thead>
							<tbody className="bg-white">
								{isLoading ? (
									<tr>
										<td colSpan={8}>
											<Loader />
										</td>
									</tr>
								) : currentRecords?.length <= 0 ? (
									<tr>
										<td className="flex m-5 col-span-6">
											No records found
										</td>
									</tr>
								) : (
									currentRecords?.map((coupon, i) => (
										<tr
											className="text-gray-700"
											key={coupon?._id}
										>
											<td className="px-4 py-3 text-sm font-no border">
												<div class="inline-flex items-center gap-x-3">
													<input
														type="checkbox"
														className=" w-[30px]"
														name="ids"
														id="ids"
														value={coupon?._id}
														onChange={(e) =>
															setids([
																...ids,
																e.target.value,
															])
														}
													/>

													<span className="font-semibold">
														0{(i + 1) + ((currentPage - 1) * 15)}
													</span>
												</div>
											</td>
											<td className="px-4 py-3 text-sm font-no border">
												{coupon?.brandName}
											</td>
											<td className="px-4 py-3 text-sm font-no border">
												{coupon?.userid?.first_name}{" "}{coupon?.userid?.last_name}
											</td>
											<td className="px-4 py-3 text-sm font-no border">
												{coupon?.code}
											</td>
											<td className="px-4 py-3 text-sm font-normal border">
												{coupon?.buy_amount}
											</td>

											<td className="px-4 py-3 text-xs border">
												{coupon?.cashback_am}

											</td>
											<td className="px-4 py-3 text-sm border">
												{(coupon?.typeOfPayment === 'cod') ? 'COD' : "Online"}
											</td>
											<td className="px-4 py-3 text-sm border">
												{moment(
													coupon?.createdAt
												).format("YYYY MMM, DD")}
											</td>
											<td className="px-4 py-3 text-sm border">
												{
													coupon?.status === "0"
														? <span >pending</span>
														: coupon?.status === "1"
															? "confirmed"
															: coupon?.status === "2"
																? "canceled"
																: "unknown"}
											</td>
											<td className="px-4 py-3 text-sm border">
												<div className="flex gap-5">
													{
														coupon?.status === "0"
															? (
																<>
																	<button
																		onClick={() => {
																		
																			approveCashbackByid(coupon?._id);

																		}}
																		className="btn dropdown-item"
																	>

																		approve
																	</button>
																	<button
																		onClick={() => {
																			setsingle(
																				coupon?._id
																			);
																			approveCashback(coupon?._id);

																		}}
																		className="btn dropdown-item"
																	>

																		canciled
																	</button>
																</>

															)
															: coupon?.status === "1"
																? "confirmed"
																: coupon?.status === "2"
																	? "canceled"
																	: "unknown"}

												</div>
											</td>
										</tr>
									))
								)}
							</tbody>
							<Pagination
								nPages={nPages}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/>
						</table>
					</div>
				</div>
			</section>{" "}
			{showModal && (
				<>
					<DeleteModal
						toggle={() => setShowModal(!showModal)}
						isLoading={isLoading}
						onDeleteClick={handleDelete}
					/>
					<div className="opacity-5 fixed inset-0 z-40 bg-black"></div>
				</>
			)}
		</>
	);
};

export default Cashback;
