import React, { useEffect } from "react";
import { faker } from "@faker-js/faker";
import { Link, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import DeleteModal from "../../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
	deleteStore,
	getStore,
	reset,
	updateStore,
} from "../../redux/stores/stores.slices";
import Loader from "../../components/Loader";

function Store() {
	const [showModal, setShowModal] = React.useState(false);

	const { id } = useParams();
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { store, isLoading, isSuccess } = useSelector((state) => state.store);

	useEffect(() => {
		dispatch(getStore(id));
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success("Success");
			dispatch(reset());
			setShowModal(false);
			navigate("/stores");
		}
	}, [isSuccess]);

	function handleDelete(id) {
		dispatch(deleteStore(id));
	}

	const handleRecommend = () => {
		dispatch(
			updateStore({
				id,
				data: {
					is_recommended: !store?.is_recommended,
				},
			})
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<div>
					<div className="flex gap-2 justify-between px-8 my-4">
						<BreadCrumb title={"Store's details"} link="stores" />
						<div className="flex gap-2">
							<Link
								class="block w-20 text-white bg-[#003049] hover:bg-[#003049] focus:ring-4 focus:ring-[#003049] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
								type="button"
								to={`/stores/${store?._id}/edit`}
							>
								EDIT
							</Link>
							<button
								class="block bg-yellow-700 text-white hover:bg-[#003049] ring-1 focus:ring-[#003049] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
								type="button"
								onClick={handleRecommend}
							>
								{store?.is_recommended
									? "UNRECOMMEND"
									: "RECOMMEND"}
							</button>
							<button
								class="block w-20 text-red-900 hover:bg-[#003049] ring-1 focus:ring-[#003049] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
								type="button"
								onClick={() => setShowModal(!showModal)}
							>
								DELETE
							</button>
						</div>
					</div>

					<div class="relative block p-8 overflow-hidden border bg-white border-slate-100 rounded-lg ml-6 mr-6">
						<span class="absolute inset-x-0 top-0 h-2 bg-gradient-to-r from-[#D62828] via-[#F77F00] to-[#003049]"></span>
						<div class="justify-between sm:flex">
							<div>
								<h5 class="text-xl font-bold text-slate-900">
									{store?.name}'s Store
								</h5>
								<p class="mt-1 text-xs font-medium text-slate-600">
									ID : {store?.storeId}
								</p>
							</div>
							<div>
								<div>
									<h5 class="text-xl font-bold text-slate-900">
										Category
									</h5>

									<div class="text-xs mt-1 rounded-md text-slate-200 bg-blue-500 px-3 py-1">
										{store?.categories?.name}
									</div>
								</div>
							</div>
							<div class="flex">
								<div>
									<div class="text-xl font-bold text-slate-900">
										Created On
									</div>
									<div class="text-xs mt-1 text-slate-500">
										{moment(store?.createdAt).format(
											"YYYY MMM, DD : hh:mm:a"
										)}
									</div>
								</div>

								<div class="ml-3 sm:ml-6">
									<div class="text-xl font-bold text-slate-900">
										Status
									</div>
									<div class="text-xs mt-1 text-slate-500 bg-green-300 px-3">
										{store?.status}
									</div>
								</div>
							</div>
							<div class="flex-shrink-0 hidden ml-3 sm:block">
								<img
									class="object-cover w-16 h-16 rounded-lg shadow-sm"
									src={store?.icon}
									alt=""
								/>
							</div>
						</div>

						<div className="grid lg:grid-cols-2 sm:grid-cols-1">
							<div>
								<h1 className="text-blue-800 mt-4 ">
									Content above the fold
								</h1>
								<div class="mt-4 sm:pr-8">
									<p class="text-sm text-slate-500">
										{store?.content_above}
									</p>
								</div>
							</div>

							<div className="p-8 border-l-8 rounded-l-lg">
								<h1 className="text-blue-800 my-4 ">Tabs</h1>
								<div className="flex gap-4">
									{store?.tabs?.map((tab, i) => (
										<div
											key={i}
											class="text-xs mt-1 rounded-md text-slate-200 bg-blue-500 px-3 py-1"
										>
											{tab}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="grid lg:grid-cols-3 sm:grid-cols-1">
						{store?.content_below?.map((content) => (
							<div class="relative block p-8 overflow-hidden border bg-white border-slate-100 rounded-lg ml-6 mr-6">
								<span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-[#f8f6f6] via-[#F77F00] to-[#003049]"></span>

								<h1 className="text-blue-800 mt-4 ">
									{content?.tab} Content Below the fold
								</h1>
								<div
									class="mt-4 sm:pr-8 text-sm text-slate-500"
									dangerouslySetInnerHTML={{
										__html: content?.desc,
									}}
								/>
							</div>
						))}
					</div>
					{showModal && (
						<>
							<DeleteModal
								toggle={() => setShowModal(!showModal)}
								isLoading={isLoading}
								onDeleteClick={() => handleDelete(store?._id)}
							/>
							<div className=" opacity-25 fixed inset-0 z-40 bg-black"></div>
						</>
					)}
				</div>
			)}
		</>
	);
}

export default Store;
