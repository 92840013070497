import { Jodit } from "jodit-react";

export const editorConfig = {
	// direction: "ltr",
	readonly: false,
	defaultMode: Jodit.MODE_WYSIWYG,
	uploader: {
		insertImageAsBase64URI: true,
	},
	controls: {
		font: {
			list: {
				"Montserrat, san-serif": "Montserrat",
				"Roboto, sans-serif": "Roboto",
				"Okra, sans-serif": "Okra",
			},
		},
	},
	spellcheck: false,
	toolbarSticky: false,
	showXPathInStatusbar: false,
	showWordsCounter: false,
	showCharsCounter: false,
	askBeforePasteHTML: true,
	askBeforePasteFromWord: true,
	// defaultActionOnPaste: "insert_clear_html",
	beautifyHTML: true,
	// enter: "DIV",
	createAttributes: {
		div: {
			class: "test",
		},
		h1: {
			class: "h1",
		},
		h2: {
			class: "h2",
		},
		h3: {
			class: "h3",
		},
		h4: {
			class: "h4",
		},
		h5: {
			class: "h5",
		},
		h6: {
			class: "h6",
		},
		ul: function (ul) {
			ul.classList.add("ul");
		},
		ol: function (ul) {
			ul.classList.add("ol");
		},
	},
};
