import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllCategories } from "../../redux/categories/categories.slices";
import {
  getSubCategory,
  reset,
  updateSubCategory,
} from "../../redux/subcategories/subcategories.slices";

export default function EditSubCategory() {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading, isSuccess, subcategory } = useSelector(
    (state) => state.subcategory
  );
  const { categories } = useSelector((state) => state.category);
  const { user } = useSelector((state) => state.auth);

  const [category, setCategory] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [subCategory, setsubCategory] = useState("");

  useEffect(() => {
    dispatch(getSubCategory(id));
    dispatch(getAllCategories());

    fetch(process.env.REACT_APP_API_URL + "subcategory/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const cat = json.subcategory;
        setCategory(cat.category.name);
        setCategoryID(cat.category.id);
        setsubCategory(cat.name);
      });
  }, [dispatch, id]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Updated successfully");
      dispatch(reset());
      navigate("/sub-categories");
    }
  }, [isSuccess]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: subcategory?.category?.id,
    },
  });

  function handleUpdate(data) {
    dispatch(
      updateSubCategory({
        id,
        data: {
          name: subCategory,
          category: categoryID,
        },
      })
    );
  }

  return (
    <>
      <h1 className="text-3xl text-center text-red-600">Edit category</h1>
      <div className="flex items-center justify-center p-12">
        <div className="mx-auto w-full">
          <form
            className="container px-24"
            onSubmit={handleSubmit(handleUpdate)}
          >
            <div className="grid grid-cols-3 gap-5">
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Category name
                </label>
                <select
                  className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
                  onChange={(e) => setCategoryID(e.target.value)}
                >
                  <option value={categoryID}>{category}</option>
                  {categories?.map((cat) => (
                    <option key={cat?._id} value={cat?._id}>
                      {cat?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  SubCategory Name
                </label>
                <input
                  type="text"
                  placeholder="enter sub-category name"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  value={subCategory}
                  onChange={(e) => setsubCategory(e.target.value)}
                />
              </div>
            </div>

            <div className="mt-5">
              <button
                type="submit"
                className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
              >
                {isLoading ? "saving" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
