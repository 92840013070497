import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "user/";

//get All Users a user

const getAllUsers = async (token) => {
  const response = await axios.get(API_URL, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return response.data.users;
};

//Update a user

const updateUser = async (token, user) => {
  const fd = new FormData();

  fd.append("isActive", !user.isActive);
  fd.append("_id", user._id);

  const response = await axios.patch(API_URL + "admin", fd, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
//Update a user

const updateUserInfo = async (token, user) => {
  const response = await axios.post(API_URL + "admin", user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

//Update a user

const userDetailsById = async (token, id) => {
  const response = await axios.post(API_URL + "admin/details", id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const authService = {
  getAllUsers,
  updateUser,
  updateUserInfo,
  userDetailsById,
};

export default authService;
