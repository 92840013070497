import React from "react";

export default function Pagination({ nPages, currentPage, setCurrentPage }) {
  // const pageNumbers = [...Array(nPages + 1)?.keys()]?.slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <tfoot>
      <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
        <span className="text-xs xs:text-sm text-gray-900">
          Showing {currentPage} of {nPages} Entries
        </span>
        <div className="inline-flex mt-2 xs:mt-0">
          <button
            onClick={prevPage}
            className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
          >
            Prev
          </button>
          <button
            onClick={nextPage}
            className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
          >
            Next
          </button>
        </div>
      </div>
    </tfoot>
  );
}
