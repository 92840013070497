import React from "react";
import { Link } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import moment from "moment";

export default function VideoCard({ data, toggle, isLoading, onDeleteClick }) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div>
      <div class="px-4">
        <div class="max-w-[370px] mx-auto mb-10 rounded-md border-b-4 border-[#003049]">
          <div class="rounded overflow-hidden">
            <img
              src={
                data?.desktop_image ||
                "https://hips.hearstapps.com/hmg-prod/images/dominos-menu-items-are-shown-on-october-12-2017-in-chicago-news-photo-860662534-1544206128.jpg"
              }
              alt="image1"
              class="w-full"
            />
          </div>
          <div>
            <span
              class="
                     bg-primary
                     rounded
                     inline-block
                     text-center
                     py-1

                     text-xs
                     leading-loose
                     font-semibold
                     mb-2
                     "
            >
              {moment(data.createdAt).format("YYYY MMM, DD : hh:mm:a")}
            </span>
            <h3
              class="
                        font-semibold
                        text-xl
                        sm:text-2xl
                        lg:text-xl
                        xl:text-2xl
                        mb-4
                        text-dark text-ellipsis
                        hover:text-primary truncate ...
                        "
            >
              {data?.title}
            </h3>
            <p class="text-base text-body-color truncate">
              {data?.description}
            </p>
          </div>
          <div className="flex gap-5 my-4">
            <Link to={`/videos/${data._id}`} className="btn dropdown-item">
              <i className="dropdown-icon ri-eye-line"></i>
            </Link>
            <Link to={`/videos/${data._id}/edit`} className="btn dropdown-item">
              <i className="dropdown-icon ri-edit-line"></i>
            </Link>

            <button
              className="btn dropdown-item"
              onClick={() => setShowModal(!showModal)}
            >
              <i className="dropdown-icon ri-delete-bin-6-line"></i>
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <>
          <DeleteModal
            toggle={toggle}
            isLoading={isLoading}
            onDeleteClick={onDeleteClick}
          />
          <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </div>
  );
}
