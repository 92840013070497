import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "category/";

// POST new Category
const addCategory = async (token, Category) => {
  const res = await axios.post(API_URL, Category, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// GET all Category
const getAllCategories = async (token) => {
  const res = await axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.categories;
};

// GET an Category
const getCategory = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.category;
};

// Delete a Category
const deleteCategory = async (id, token) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an Category
const updateCategory = async (Category, id, token) => {
  const res = await axios.patch(API_URL + id, Category, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

const CategoryService = {
  addCategory,
  getCategory,
  updateCategory,
  deleteCategory,
  getAllCategories,
};

export default CategoryService;
