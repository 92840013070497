import React, { useEffect, useState } from "react";
import { faker } from "@faker-js/faker";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import DeleteModal from "../../components/DeleteModal";
import CommentsModal from "../../components/CommentsModal";
import StarPicker from "react-star-picker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
    getQuestions,
    publishQuestion,
    reset,
} from "../../redux/questions/question.slices";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import axios from "axios";

const ViewReply = ({questionId,CommentId, onClose }) => {
    const dispatch = useDispatch();


    const [keyword, setKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(15);
    const [refresh,setRefresh] = useState(false)
    const [reply,setReply]=useState([])


    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(()=>{
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: process.env.REACT_APP_API_URL +"questions/"+questionId + "/comments/"+CommentId+"/replies" ,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${user?.token}`,
			},
			data: {}
		};

		axios.request(config)
			.then((response) => {
				console.log(JSON.stringify(response.data));
                console.log(response.data)
				setReply(response.data.replies)

			})
			.catch((error) => {
				console.log(error);
			});


	},[refresh])

    const { user } = useSelector((state) => state.auth);

    const publishQuestion = (data) => {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_API_URL + 'replies/publish/'+questionId+"/"+CommentId +"/"+ data.id ,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
            },
            data: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setRefresh(!refresh)
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const unpublishQuestion = (data) => {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_API_URL + 'replies/unpublish/'+questionId+"/"+CommentId+"/" + data.id ,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
            },
            data: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setRefresh(!refresh)

            })
            .catch((error) => {
                console.log(error);
            });

    }



    return (
        <>
            <section className="container mx-auto p-6 font-mono">
                <div className="flex my-auto items-center justify-between">

                    <h1 className="text-2xl text-red-700 underline">FAQs Reply</h1>
                    {/* <Link
						class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
						type="button"
						// onClick={() => setIsOpen(!modalIsOpen)}
						to={"/questions/add"}
					>
						ADD
					</Link> */}
                    <div class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => onClose()}>Close</div>
                </div>

                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                    <div className="w-full overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                                    <th className="px-4 py-3">Created</th>
                                    <th className="px-4 py-3">User</th>
                                    <th className="px-4 py-3">reply</th>
                                    <th className="px-4 py-3">Status</th>
                                    {/* <th className="px-4 py-3">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {
                                    reply.length <= 0 ? (
                                        <tr>
                                            <td
                                                className="flex m-5 col-span-6"
                                                colSpan={5}
                                            >
                                                No records found
                                            </td>
                                        </tr>
                                    ) : (
                                        reply?.map((faq) => (
                                            <>
                                                <tr className="text-gray-700">
                                                    <td className="px-4 py-3 text-ms font-semibold border">
                                                        {moment(
                                                            faq?.createdAt
                                                        ).format("YYYY/MM/DD")}
                                                    </td>
                                                    <td className="px-4 py-3 text-xs border">
                                                        {faq?.user}
                                                    </td>

                                                    <td className="px-4 py-3 text-xs border">
                                                        {faq?.replyText}
                                                    </td>
                                                    {/* <td className="px-4 py-3 text-xs border">
														{faq?.answer}
													</td> */}

                                                    <td className="px-4 py-3 text-sm border">

                                                        {/* <button
															class="block text-black ring-1 ring-black my-4 hover:text-white hover:bg-yellow-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center"
															type="button"
															onClick={() =>
																dispatch(
																	publishQuestion({
																		id: faq?._id,
																		data: {},
																	})
																)
															}
														>
															{faq?.is_publish
																? "Unpublish"
																: "publish"}
														</button> */}

                                                        {!faq?.is_publish ?
                                                            <button
                                                                class="block text-black ring-1 ring-black my-4 hover:text-white hover:bg-yellow-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center"
                                                                type="button"
                                                                onClick={() =>
                                                                    publishQuestion({
                                                                        id: faq?._id,
                                                                        data: {},
                                                                    })

                                                                }
                                                            >

                                                                publish
                                                            </button> :
                                                            <button
                                                                class="block text-black ring-1 ring-black my-4 hover:text-white hover:bg-yellow-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center"
                                                                type="button"
                                                                onClick={() =>
                                                                    unpublishQuestion({
                                                                        id: faq?._id,
                                                                        data: {},
                                                                    })

                                                                }
                                                            >

                                                                Unpublish
                                                            </button>

                                                        }
                                                    </td>
                                                    {/* <td className="px-4 py-3 text-sm border">
														<div className="flex gap-5">

															<button onClick={(e) => {
																// handleShowComments(e, faq?._id)
															}}
																className="btn dropdown-item"
															>
																<i className="dropdown-icon ri-chat-delete-line text-2xl text-red-800"></i>
															</button>
														</div>
													</td> */}
                                                </tr>

                                            </>
                                        ))
                                    )}
                            </tbody>
                            {/* <Pagination
								nPages={nPages}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/> */}
                        </table>
                    </div>
                </div>
            </section>

        </>
    );
};

export default ViewReply;
