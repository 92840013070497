import { useState, useRef, useEffect, useMemo } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import JoditEditor, { Jodit } from "jodit-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
	reset,
	updateCategory,
} from "../../redux/categories/categories.slices";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { editorConfig } from "../../utils/editorConfig";

const animatedComponents = makeAnimated();

function EditCategory() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { isLoading, isError, isSuccess } = useSelector(
		(state) => state.category
	);

	const { user } = useSelector((state) => state.auth);

	const [name, setName] = useState(null);
	const [url, setUrl] = useState(null);
	const [icon, setIcon] = useState(null);
	const [tabs, setTabs] = useState(null);

	const [contentAbove, setContentAbove] = useState(null);
	const [isTabs, setIsTabs] = useState(false);
	const [status, setStatus] = useState(false);
	const [isContentAbove, setIsContentAbove] = useState(false);
	const [isContentBelow, setIsContentBelow] = useState(null);
	const [contentBelow, setContentBelow] = useState(null);
	//  const [status, setStatus] = useState(category?.status);
	const [categoryId, setCategoryId] = useState(null);
	const [meta_title, setMetaTitle] = useState(null);
	const [description, setDescription] = useState(null);
	const [selectedTabs, setSelectedTabs] = useState(null);
	const [checkedTabs, setCheckedTabs] = useState([]);
	const [altTagText, setAltTagText] = useState("");
	const [card_header, setCardHeader] = useState("");
	const [card_description, setCardDescription] = useState("");

	useEffect(() => {
		if (!isTabs) {
			setSelectedTabs([]);
			setCheckedTabs([]);
			setTabs([]);
			setContentBelow([]);
		}
		if (!isContentAbove) {
			setContentAbove("");
		}
		if (!isContentBelow) {
			setContentBelow([]);
		}
	}, [isTabs, isContentAbove, isContentBelow]);

	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "category/" + id, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${user?.token}`,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				const category = json.category;

				setCategoryId(category?.categoryId);
				setName(category?.name);
				setUrl(category?.url);
				setIcon(category?.icon);
				setAltTagText(category?.icon_alt_tag);
				setTabs(category?.tabs);
				setCheckedTabs(category?.checked_tabs);
				setContentAbove(category?.content_above);
				setIsTabs(category?.is_tabs);
				setIsContentAbove(category?.is_content_above);
				setIsContentBelow(category?.is_content_below);
				setContentBelow(category?.content_below);
				setDescription(category?.description);
				setMetaTitle(category?.meta_title);

				setCardDescription(category?.card_description);
				setCardHeader(category?.card_header);
				setStatus(category?.status);
				setTabs(
					category?.content_below?.map((t) => {
						return {
							value: t.tab,
							label: t.tab,
							tab: t.tab,
							desc: t.desc,
						};
					})
				);
				setSelectedTabs(
					category?.tabs?.map((t) => {
						return {
							value: t,
							label: t,
						};
					})
				);
			});
	}, [dispatch, id]);

	const editor = useRef(null);

	const config = useMemo(() => editorConfig, []);

	//

	const handleDescChange = (tab) => {
		const newData = contentBelow?.map((item) => {
			if (item.tab === tab.tab) {
				return { ...item, desc: tab.value };
			} else {
				return item;
			}
		});
		setContentBelow([
			...newData.filter((item) => item.tab !== tab.tab),
			{
				tab: tab.tab,
				desc: tab.value,
			},
		]);
	};

	const tabsOption = [
		{ value: "Videos", label: "Videos" },
		{ value: "Offers", label: "Offers" },
		{ value: "Reviews", label: "Reviews" },
		{ value: "Must read", label: "Must read" },
	];

	useEffect(() => {
		if (isSuccess) {
			toast.success("Updated category successfully");
			dispatch(reset());
			navigate("/categories");
		}
	}, [dispatch, isSuccess, navigate]);

	const handleImage = (e) => {
		// e.preventDefault();
		try {
			let file = e.target.files[0];
			let formData = new FormData();
			formData.append("image", file);
			const config = {
				headers: {
					"content-type": "multipart/form-data",
				},
			};
			axios
				.post(
					process.env.REACT_APP_API_URL + `images`,
					formData,
					config
				)
				.then(({ data }) => {
					setIcon(data?.data?.uploadedUrl);
					toast.success("Success");
				});
		} catch (err) {
			toast.error(err.message);
		}
	};

	function handleSave(e) {
		e.preventDefault();

		const newtabs = tabs?.map((t) => {
			return {
				tab: t.value,
				desc: "",
			};
		});

		const filteredContent = contentBelow.filter((obj1) => {
			return newtabs.some((obj2) => obj2.tab === obj1.tab);
		});

		const combinedArray = newtabs.concat(filteredContent);

		const uniqueMembers = Object.values(
			combinedArray.reduce((acc, obj) => {
				acc[obj.tab] = obj;
				return acc;
			}, new Map())
		);

		dispatch(
			updateCategory({
				id,
				data: {
					categoryId,
					name,
					url,
					icon,
					icon_alt_tag: altTagText,
					meta_title,
					description,
					tabs: tabs?.map((t) => t?.value),
					content_above: contentAbove,
					is_tabs: isTabs,
					is_content_above: isContentAbove,
					is_content_below: isContentBelow,
					content_below: uniqueMembers,
					card_description,
					card_header,
				},
			})
		);
	}

	if (isLoading) return <Loader />;

	return (
		<>
			<h1 className="text-3xl text-center text-green-600">
				Edit {name} category
			</h1>

			<div className="flex items-center justify-center p-12">
				<div className="mx-auto w-full">
					<form className="container px-24" onSubmit={handleSave}>
						<div className="grid grid-cols-3 gap-5">
							<div>
								<label
									htmlFor="name"
									className="mb-3 block text-base font-medium text-[#07074D]"
								>
									Name
								</label>
								<input
									type="text"
									placeholder="category name"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									ID
								</label>
								<input
									type="text"
									placeholder="enter id e.g FD_002"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={categoryId}
									onChange={(e) =>
										setCategoryId(e.target.value)
									}
								/>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Url
								</label>
								<input
									type="text"
									placeholder="enter carteegory Url e.g www.rezgari.com/foodoffers"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={url}
									onChange={(e) => setUrl(e.target.value)}
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-5 mt-5">
							<div>
								<label className="my-6 block text-base font-medium text-[#07074D]">
									Category Icon
								</label>
								<div className="d-flex flex-col gap-4">
									{icon ? (
										<div className="image-preview relative">
											<img src={icon} alt="" />
											<button
												onClick={() => setIcon(null)}
												className="btn absolute top-0 right-0 z-40 bg-white"
											>
												<i className="dropdown-icon ri-delete-bin-6-line"></i>
											</button>
										</div>
									) : (
										<div className="flex">
											<label
												for="file-upload"
												className="upload-button"
											>
												<i className="ri-file-add-line"></i>
											</label>

											<input
												id="file-upload"
												type="file"
												name="profileImage"
												size="large"
												onChange={handleImage}
												accept="image/*"
												hidden
											/>
										</div>
									)}
								</div>
							</div>
							<div>
								<label
									htmlFor="name"
									className="mb-3 block text-base font-medium text-[#07074D]"
								>
									Alt tag text
								</label>
								<input
									type="text"
									placeholder="enter alt text here"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={altTagText}
									onChange={(e) =>
										setAltTagText(e.target.value)
									}
								/>
							</div>
						</div>

						<div className="grid grid-cols-1 gap-5 mt-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Content above fold
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="contentAbove"
											className="h-5 w-5"
											checked={isContentAbove}
											onChange={(e) =>
												setIsContentAbove(true)
											}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Add content above
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="contentAbove"
											className="h-5 w-5"
											checked={!isContentAbove}
											onChange={(e) =>
												setIsContentAbove(false)
											}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Not required
										</label>
									</div>
								</div>
								{isContentAbove && (
									<textarea
										rows="4"
										placeholder="Type category content above fold here"
										className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
										value={contentAbove}
										onChange={(e) =>
											setContentAbove(e.target.value)
										}
									></textarea>
								)}
							</div>

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Tabs
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											checked={isTabs}
											onChange={(e) => setIsTabs(true)}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Add tabs
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											checked={!isTabs}
											onChange={(e) => {
												setIsTabs(false);
												setTabs([]);
											}}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											No tab required
										</label>
									</div>
								</div>
								{isTabs && (
									<Select
										components={animatedComponents}
										isMulti
										name="users"
										className="basic-multi-select"
										placeholder="select the tabs to display..."
										classNamePrefix="select"
										closeMenuOnSelect={false}
										options={tabsOption}
										value={tabs}
										onChange={(e) => setTabs(e)}
									/>
								)}
							</div>
						</div>

						<div className="grid grid-cols-1 gap-5 mt-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Content below fold
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="contentBelow"
											className="h-5 w-5"
											checked={isContentBelow}
											onChange={(e) =>
												setIsContentBelow(true)
											}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Add content below
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="contentBelow"
											className="h-5 w-5"
											checked={!isContentBelow}
											onChange={(e) =>
												setIsContentBelow(false)
											}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Not required
										</label>
									</div>
								</div>

								{isContentBelow && (
									<>
										<div className="my-4">
											{tabs?.map((tab, i) => (
												<details
													key={i}
													className="group"
												>
													<summary className="flex items-center font-medium cursor-pointer list-none bg-[#F5F5F5] p-2">
														<span className="transition group-open:rotate-180">
															<svg
																width="23"
																height="22"
																viewBox="0 0 23 22"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M11.7001 0.057373C5.65134 0.057373 0.750488 4.95823 0.750488 11.007C0.750488 17.0558 5.65134 21.9567 11.7001 21.9567C17.7489 21.9567 22.6498 17.0558 22.6498 11.007C22.6498 4.95823 17.7489 0.057373 11.7001 0.057373ZM18.058 12.2433C18.058 12.5347 17.8196 12.7731 17.5282 12.7731H13.4662V16.8351C13.4662 17.1265 13.2278 17.3649 12.9364 17.3649H10.4639C10.1725 17.3649 9.93407 17.1265 9.93407 16.8351V12.7731H5.8721C5.5807 12.7731 5.34228 12.5347 5.34228 12.2433V9.77078C5.34228 9.47938 5.5807 9.24096 5.8721 9.24096H9.93407V5.17899C9.93407 4.88758 10.1725 4.64916 10.4639 4.64916H12.9364C13.2278 4.64916 13.4662 4.88758 13.4662 5.17899V9.24096H17.5282C17.8196 9.24096 18.058 9.47938 18.058 9.77078V12.2433Z"
																	fill="black"
																/>
															</svg>
														</span>
														<span className="ml-4">
															{tab?.value} Content
															Below the fold
														</span>
													</summary>
													<div>
														<JoditEditor
															ref={editor}
															value={tab?.desc}
															tabIndex={1}
															config={config}
															onBlur={(value) =>
																handleDescChange(
																	{
																		tab: tab?.value,
																		value,
																	}
																)
															}
														/>
													</div>
												</details>
											))}
										</div>
									</>
								)}
							</div>
						</div>
						<div className="grid grid-cols-2 gap-5 mt-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Card Header
								</label>

								<input
									type="text"
									placeholder="enter card title"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={card_header}
									onChange={(e) =>
										setCardHeader(e.target.value)
									}
								/>
							</div>

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Category Card Description
								</label>
								<textarea
									rows="4"
									placeholder="Type card description here"
									className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={card_description}
									onChange={(e) =>
										setCardDescription(e.target.value)
									}
								></textarea>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-5 mt-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Meta title
								</label>

								<input
									type="text"
									placeholder="enter meta title"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={meta_title}
									onChange={(e) =>
										setMetaTitle(e.target.value)
									}
								/>
							</div>

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Category Description
								</label>

								<textarea
									rows="4"
									placeholder="Type category description here"
									className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={description}
									onChange={(e) =>
										setDescription(e.target.value)
									}
								></textarea>
							</div>
						</div>
						<div className="mt-5">
							<button
								type="submit"
								className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
							>
								{isLoading ? "Updating..." : "Update"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default EditCategory;
