import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import reviewService from "./review.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  reviews: [],
  reviews_graph: [],
  review: null,
};

//add new review
export const addReview = createAsyncThunk(
  "reviews/add",
  async function (review, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await reviewService.addReview(token, review);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all reviews
export const getAllReviews = createAsyncThunk(
  "reviews/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await reviewService.getAllReviews(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an review
export const getReview = createAsyncThunk(
  "reviews/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await reviewService.getReview(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a review
export const updateReview = createAsyncThunk(
  "reviews/update",
  async function (review, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await reviewService.updateReview(review.data, review.id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a publish review
export const publishReview = createAsyncThunk(
  "reviews/publish",
  async function (review, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await reviewService.publishReview(review.data, review.id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// DEL a review
export const deleteReview = createAsyncThunk(
  "reviews/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await reviewService.deleteReview(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for reviews
const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.review = action.payload;
      })
      .addCase(addReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.review = action.payload;
      })
      .addCase(getReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })

      .addCase(getAllReviews.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllReviews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviews = action.payload;
      })
      .addCase(getAllReviews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(updateReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.review = action.payload;
        state.isSuccess = true;
      })
      .addCase(updateReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(publishReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(publishReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.review = action.payload;
        state.isSuccess = true;
      })
      .addCase(publishReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.review = action.payload;
        state.isSuccess = true;
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = reviewSlice.actions;
export default reviewSlice.reducer;
