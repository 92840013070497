import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "auth/";
const API_USER_URL = process.env.REACT_APP_API_URL + "users/";

//login a user

const login = async (user) => {
  const response = await axios.post(API_URL + "admin/login", user);
  // if (user.remember_me === true) {
  localStorage.setItem("rezgari", JSON.stringify(response.data.data));
  // }

  return response.data.data;
};

//update profile

const updateProfile = async (user, token) => {
  var formdata = new FormData();
  formdata.append("email", user.email);
  formdata.append("name", user.name);

  const response = await axios.patch(API_USER_URL + "profile", formdata, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//Set new password for a user

const setNewPassword = async (user, token) => {
  var formdata = new FormData();
  formdata.append("current_password", user.current_password);
  formdata.append("new_password", user.new_password);
  formdata.append("confirm_password", user.confirm_password);

  const response = await axios.post(
    API_URL + "password/newPassword/admin",
    formdata,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

//Set new password for a user

const resetPassword = async (user) => {
  var formdata = new FormData();
  formdata.append("email", user.email);

  const response = await axios.post(API_URL + "resetpassword/admin", formdata);

  return response.data;
};

//logout a user

const logout = () => {
  localStorage.removeItem("rezgari");
};

const authService = {
  login,
  updateProfile,
  setNewPassword,
  resetPassword,
  logout,
};

export default authService;
