import React, { useEffect, useState } from "react";
import { faker } from "@faker-js/faker";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import DeleteModal from "../../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubCategory,
  getAllSubCategories,
  reset,
} from "../../redux/subcategories/subcategories.slices";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

export default function SubCategories() {
  const dispatch = useDispatch();

  const [subcategoriesFiltered, setSubCategoriesFiltered] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const [single, setsingle] = useState("");

  const { subcategories, isLoading, isSuccess } = useSelector(
    (state) => state.subcategory
  );

  useEffect(() => {
    dispatch(getAllSubCategories());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Deleted successfully");
      dispatch(reset());
      dispatch(getAllSubCategories());
      setShowModal(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    const re = new RegExp(keyword, "i");

    setSubCategoriesFiltered(
      subcategories?.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val == "string" && val.match(re)
        )
      )
    );
  }, [keyword, subcategories]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = subcategoriesFiltered?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(subcategoriesFiltered?.length / recordsPerPage);

  function handleDelete() {
    dispatch(deleteSubCategory(single));
  }

  return (
    <>
      <section className="container mx-auto p-6 font-mono">
        <div className="flex my-auto items-center justify-between">
          <SearchInput
            autoComplete="on"
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />
          <h1 className="text-2xl text-red-700 underline">Sub-Categories</h1>

          <Link
            class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
            // onClick={() => setIsOpen(!modalIsOpen)}
            to={"/sub-categories/add"}
          >
            ADD
          </Link>
        </div>
        <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                  <th className="px-4 py-3">ID</th>
                  <th className="px-4 py-3">Category Name</th>
                  <th className="px-4 py-3">Sub-Category Name</th>
                  <th className="px-4 py-3">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {isLoading ? (
                  <tr>
                    <td colSpan={4}>
                      <Loader />
                    </td>
                  </tr>
                ) : currentRecords.length <= 0 ? (
                  <tr>
                    <td className="flex m-5 col-span-6" colSpan={4}>
                      No records found
                    </td>
                  </tr>
                ) : (
                  currentRecords?.map((category, i) => (
                    <tr className="text-gray-700">
                      <td className="px-4 py-3 border">
                        <div className="flex items-center text-sm">
                          <p className="font-semibold text-black">0{(i + 1) + ((currentPage - 1) * 15)}</p>
                        </div>
                      </td>

                      <td className="px-4 py-3 text-ms font-semibold border">
                        <div class="flex gap-2">
                          <span class="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                            {category?.category?.name}
                          </span>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-ms font-semibold border">
                        {category?.name}
                      </td>
                      <td className="px-4 py-3 text-sm border">
                        <div className="flex gap-5">
                          <button
                            onClick={() => {
                              setsingle(category?._id);
                              setShowModal(!showModal);
                            }}
                            className="btn dropdown-item"
                          >
                            <i className="dropdown-icon ri-delete-bin-6-line"></i>
                          </button>
                          <Link
                            to={`/sub-categories/${category?._id}/edit`}
                            className="btn dropdown-item"
                          >
                            <i className="dropdown-icon ri-edit-line"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
              <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </table>
          </div>
        </div>{" "}
        {showModal && (
          <>
            <DeleteModal
              isLoading={isLoading}
              onDeleteClick={handleDelete}
              toggle={() => setShowModal(!showModal)}
            />
            <div className=" opacity-5 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
      </section>
    </>
  );
}
