import React from "react";

const Footer = () => {
  return (
    <div className="text-center text-gray-500 fixed bg-white w-full bottom-4 z-50">
      {new Date().getFullYear()} &copy;
      <span> Powered by ScaleupAlly</span>
    </div>
  );
};

export default Footer;
