// faqSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import faqService from "./comment.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isDeleted: false,
  message: "",
  comments: [],
  comment: null,
};

// add new question
export const addComment = createAsyncThunk(
  "questions/add",
  async function (Faq, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqService.addComments(token, Faq);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// get all questions
export const getcomments = createAsyncThunk(
  "questions/all",
  async function (_, thunkAPI) {
    try {
      return await faqService.getComments();
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


// get a question by id
export const getCommentsyId = createAsyncThunk(
  "questions/details",
  async function (id, thunkAPI) {
    try {
      return await faqService.getCommentsById(id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// publish a question
export const publishQuestion = createAsyncThunk(
  "questions/publish",
  async function (id, thunkAPI) {
    try {
      return await faqService.publishComments(id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// unpublish a question
export const unpublishQuestion = createAsyncThunk(
  "questions/unpublish",
  async function (id, thunkAPI) {
    try {
      return await faqService.unpublishComments(id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);



// create slice for questions
const commentSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.comment = action.payload;
      })
      .addCase(addComment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(getcomments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getcomments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.comments = action.payload;
      })
      .addCase(getcomments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(getCommentsyId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCommentsyId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.comment = action.payload;
      })
      .addCase(getCommentsyId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(publishQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(publishQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.comment = action.payload;
        state.isSuccess = true;
      })
      .addCase(publishQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(unpublishQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unpublishQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.comment = action.payload;
        state.isDeleted = true;
      })
      .addCase(unpublishQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      
   
  },
});

export const { reset } = commentSlice.actions;
export default commentSlice.reducer;
