// CommentsModal.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getComments } from "../../redux/comments/comment.slices";
import { getComments } from "../redux/questions/question.slices";
import Loader from "./Loader";

const CommentsModal = ({ questionId, onClose }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getComments(questionId));
  }, [dispatch, questionId]);

  const { comments, isLoading } = useSelector((state) => state.comments);

  // Implement your modal structure and comment display logic here

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Comments for Question</h2>
        {isLoading ? (
          <Loader />
        ) : (
          <ul>
            {comments.map((comment) => (
              <li key={comment._id}>{comment.commentText}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CommentsModal;
