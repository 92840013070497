import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth.slices";
import categoriesSlices from "./categories/categories.slices";
import couponSlices from "./coupons/coupon.slices";
import faqSlices from "./faqs/faq.slices";
import reviewSlices from "./reviews/review.slices";
import storesSlices from "./stores/stores.slices";
import subcategoriesSlices from "./subcategories/subcategories.slices";
import tagSlices from "./tags/tag.slices";
import userSlices from "./users/user.slices";
import videoSlices from "./videos/video.slices";
import questionSlices from "./questions/question.slices";
import commentSlices from "./recentcomment/comment.slices";
import cashbackSlices from "./cashback/cashback.slices";
import walletSlices from "./wallet/wallet.slices";

const store = configureStore({
  reducer: {
    auth: authSlice,
    category: categoriesSlices,
    store: storesSlices,
    subcategory: subcategoriesSlices,
    tag: tagSlices,
    coupon: couponSlices,
    user: userSlices,
    review: reviewSlices,
    faq: faqSlices,
    video: videoSlices,
    questions:questionSlices,
    comments:commentSlices,
    Cashback:cashbackSlices,
    wallet:walletSlices
  },
});

export default store;
