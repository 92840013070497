import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "subcategory/";

// POST new SubCategory
const addSubCategory = async (token, subCategory) => {
  const res = await axios.post(API_URL, subCategory, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};

// GET all SubCategory
const getAllSubCategories = async (token) => {
  const res = await axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.subcategories;
};

// GET an SubCategory
const getSubCategory = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.subcategory;
};

// Delete a SubCategory
const deleteSubCategory = async (id, token) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an SubCategory
const updateSubCategory = async (SubCategory, id, token) => {
  const res = await axios.patch(API_URL + id, SubCategory, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

const SubCategoryService = {
  addSubCategory,
  getSubCategory,
  updateSubCategory,
  deleteSubCategory,
  getAllSubCategories,
};

export default SubCategoryService;
