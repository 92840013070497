import { useState, useRef, useEffect, useMemo } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addCategory, reset } from "../../redux/categories/categories.slices";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { editorConfig } from "../../utils/editorConfig";

const animatedComponents = makeAnimated();

function AddCategory() {
	const navigate = useNavigate();

	const [contentAbove, setContentAbove] = useState(false);
	const [contentBelow, setContentBelow] = useState(false);
	const [isTabs, setIsTabs] = useState(false);
	const [selectedTabs, setSelectedTabs] = useState([]);
	const [checkedTabs, setCheckedTabs] = useState([]);
	const [image, setImage] = useState(null);

	const editor = useRef([]);
	const config = useMemo(() => editorConfig, []);

	const [formData, setFormData] = useState([]);

	const handleContentChange = (content, index) => {
		setFormData([
			...formData,
			{
				tab: content?.tab,
				desc: content?.content,
			},
		]);
	};

	const tabsOption = [
		{ value: "Videos", label: "Videos", tab: "Videos", desc: "Videos" },
		{ value: "Offers", label: "Offers", tab: "Offers", desc: "Offers" },
		{ value: "Reviews", label: "Reviews", tab: "Reviews", desc: "Reviews" },
		{
			value: "Must read",
			label: "Must read",
			tab: "Must read",
			desc: "Must read",
		},
	];

	const dispatch = useDispatch();

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm();

	const { isSuccess, isLoading } = useSelector((state) => state.category);

	useEffect(() => {
		if (isSuccess) {
			toast.success("Added category successfully");
			dispatch(reset());
			navigate("/categories");
		}
	}, [isSuccess]);

	const [loading, setLoading] = useState(false);

	const handleImage = (e) => {
		// e.preventDefault();
		try {
			let file = e.target.files[0];
			let formData = new FormData();
			formData.append("image", file);
			const config = {
				headers: {
					"content-type": "multipart/form-data",
				},
			};
			axios
				.post(
					process.env.REACT_APP_API_URL + `images`,
					formData,
					config
				)
				.then(({ data }) => {
					setImage(data?.data?.uploadedUrl);
					// setImage(data.data[0].location);
					toast.success("Success");
				});
		} catch (err) {
			toast.error(err.message);
		}
	};

	function handleSave(data) {
		if (!image) {
			return toast.warn("Category icon is required");
		}
		const newtabs = selectedTabs?.map((t) => {
			return {
				tab: t.value,
				desc: "",
			};
		});

		const combinedArray = newtabs.concat(formData);

		const uniqueMembers = Object.values(
			combinedArray.reduce((acc, obj) => {
				acc[obj.tab] = obj;
				return acc;
			}, new Map())
		);

		dispatch(
			addCategory({
				...data,
				icon: image,
				tabs: selectedTabs?.map((t) => t.value),
				is_content_above: contentAbove,
				is_tabs: isTabs,
				checked_tabs: checkedTabs,
				content_below: uniqueMembers,
				is_content_below: contentBelow,
			})
		);
	}

	return (
		<>
			<h1 className="text-3xl text-center text-red-600">
				Add new category
			</h1>
			<div className="flex items-center justify-center p-12">
				<div className="mx-auto w-full">
					<form
						className="container px-24"
						onSubmit={handleSubmit(handleSave)}
					>
						<div className="grid grid-cols-3 gap-5">
							<div>
								<label
									htmlFor="name"
									className="mb-3 block text-base font-medium text-[#07074D]"
								>
									Name
								</label>
								<input
									type="text"
									placeholder="category name"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("name", { required: true })}
								/>
								{errors.name && (
									<span className="text-red-700">
										Name is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									ID
								</label>
								<input
									type="text"
									placeholder="enter id e.g FD_002"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("categoryId", {
										required: true,
									})}
								/>
								{errors.categoryId && (
									<span className="text-red-700">
										Category ID is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Url
								</label>
								<input
									type="text"
									placeholder="enter carteegory Url e.g www.rezgari.com/foodoffers"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("url", { required: true })}
								/>
								{errors.url && (
									<span className="text-red-700">
										Category URL is required
									</span>
								)}
							</div>
						</div>
						<div>
							<label className="my-6 block text-base font-medium text-[#07074D]">
								Category Icon
							</label>
							<div className="d-flex flex-col gap-4">
								{image ? (
									<div className="image-preview relative">
										<img src={image} alt="" />
										<button
											onClick={() => setImage(null)}
											className="btn absolute top-0 right-0 z-40 bg-white"
										>
											<i className="dropdown-icon ri-delete-bin-6-line"></i>
										</button>
									</div>
								) : (
									<div className="flex">
										<label
											for="file-upload"
											className="upload-button"
										>
											<i className="ri-file-add-line"></i>
										</label>

										<input
											id="file-upload"
											type="file"
											name="profileImage"
											size="large"
											onChange={handleImage}
											accept="image/*"
											hidden
										/>
									</div>
								)}
							</div>
						</div>
						<div>
							<label
								htmlFor="name"
								className="mb-3 block text-base font-medium text-[#07074D]"
							>
								Alt tag text
							</label>
							<input
								type="text"
								placeholder="enter alt text here"
								className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
								{...register("icon_alt_tag")}
							/>
						</div>
						<div className="grid grid-cols-1 gap-5 mt-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Content above fold
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="contentAbove"
											className="h-5 w-5"
											value={true}
											onChange={(e) =>
												setContentAbove(true)
											}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Add content above
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="contentAbove"
											className="h-5 w-5"
											value={false}
											onChange={(e) =>
												setContentAbove(false)
											}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Not required
										</label>
									</div>
								</div>
								{contentAbove && (
									<textarea
										rows="4"
										placeholder="Type category content above fold here"
										className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
										{...register("content_above")}
									></textarea>
								)}
							</div>

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Tabs
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											value={true}
											onChange={(e) => setIsTabs(true)}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Add tabs
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											value={false}
											onChange={(e) => {
												setIsTabs(false);
												setSelectedTabs([]);
											}}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											No tab required
										</label>
									</div>
								</div>
								{isTabs && (
									<Select
										components={animatedComponents}
										isMulti
										name="users"
										className="basic-multi-select"
										placeholder="select the tabs to display..."
										classNamePrefix="select"
										closeMenuOnSelect={false}
										options={tabsOption}
										value={selectedTabs}
										onChange={(e) => setSelectedTabs(e)}
									/>
								)}
							</div>
						</div>

						<div className="grid grid-cols-1 gap-5 mt-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Content below fold
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="contentBelow"
											className="h-5 w-5"
											value={true}
											onChange={(e) =>
												setContentBelow(true)
											}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Add content below
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="contentBelow"
											className="h-5 w-5"
											value={false}
											onChange={(e) => {
												setContentBelow(false);
												setFormData([]);
											}}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Not required
										</label>
									</div>
								</div>
								{contentBelow && (
									<>
										{/* <div>
                      {selectedTabs.map((tab, i) => (
                        <label
                          key={i}
                          className="inline-flex items-center my-3 mr-5"
                        >
                          <input
                            type="checkbox"
                            value={tab?.value}
                            className="form-checkbox h-5 w-5 text-red-600"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              const value = e.target.value;
                              if (isChecked) {
                                setCheckedTabs([...(checkedTabs || []), value]);
                              } else {
                                setCheckedTabs((prev) =>
                                  prev.filter((tab) => tab !== value)
                                );
                              }
                            }}
                          />
                          <span className="ml-2 text-gray-700">
                            {tab?.value}
                          </span>
                        </label>
                      ))}
                    </div> */}
										{selectedTabs?.map((tab, i) => (
											<details key={i} className="group">
												<summary className="flex items-center font-medium cursor-pointer list-none bg-[#F5F5F5] p-2">
													<span className="transition group-open:rotate-180">
														<svg
															width="23"
															height="22"
															viewBox="0 0 23 22"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M11.7001 0.057373C5.65134 0.057373 0.750488 4.95823 0.750488 11.007C0.750488 17.0558 5.65134 21.9567 11.7001 21.9567C17.7489 21.9567 22.6498 17.0558 22.6498 11.007C22.6498 4.95823 17.7489 0.057373 11.7001 0.057373ZM18.058 12.2433C18.058 12.5347 17.8196 12.7731 17.5282 12.7731H13.4662V16.8351C13.4662 17.1265 13.2278 17.3649 12.9364 17.3649H10.4639C10.1725 17.3649 9.93407 17.1265 9.93407 16.8351V12.7731H5.8721C5.5807 12.7731 5.34228 12.5347 5.34228 12.2433V9.77078C5.34228 9.47938 5.5807 9.24096 5.8721 9.24096H9.93407V5.17899C9.93407 4.88758 10.1725 4.64916 10.4639 4.64916H12.9364C13.2278 4.64916 13.4662 4.88758 13.4662 5.17899V9.24096H17.5282C17.8196 9.24096 18.058 9.47938 18.058 9.77078V12.2433Z"
																fill="black"
															/>
														</svg>
													</span>
													<span className="ml-4">
														{tab.value} Content
														Below the fold
													</span>
												</summary>
												<div>
													<JoditEditor
														ref={editor}
														// value={tab}
														config={config}
														name={tab.value}
														onBlur={(content) =>
															handleContentChange(
																{
																	tab: tab.value,
																	content,
																},
																i
															)
														}
													/>
												</div>
											</details>
										))}
									</>
								)}
							</div>
						</div>

						<div className="grid grid-cols-2 gap-5 mt-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Card Header
								</label>

								<input
									type="text"
									placeholder="enter card title"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("card_header", {
										required: true,
									})}
								/>
								{errors.meta_title && (
									<span className="text-red-700">
										Card title is required
									</span>
								)}
							</div>

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Category Card Description
								</label>
								<textarea
									rows="4"
									placeholder="Type card description here"
									className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("card_description", {
										required: true,
									})}
								></textarea>

								{errors.description && (
									<span className="text-red-700">
										Card Description is required
									</span>
								)}
							</div>
						</div>
						<div className="grid grid-cols-2 gap-5 mt-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Meta title
								</label>

								<input
									type="text"
									placeholder="enter meta title"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("meta_title", {
										required: true,
									})}
								/>
								{errors.meta_title && (
									<span className="text-red-700">
										Meta title is required
									</span>
								)}
							</div>

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Category Description
								</label>
								<textarea
									rows="4"
									placeholder="Type category description here"
									className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("description", {
										required: true,
									})}
								></textarea>

								{errors.description && (
									<span className="text-red-700">
										Description is required
									</span>
								)}
							</div>
						</div>
						<div className="mt-5">
							<button
								type="submit"
								className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
							>
								{isLoading ? "Saving..." : "Save"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default AddCategory;
