import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cashbackService from "./cashback.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  cashbacks: [],
  cashback: null,
};

//get all coupons
export const getAllCashback = createAsyncThunk(
  "cashback/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cashbackService.getAllCashback(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
//get an coupon
export const approveCashback = createAsyncThunk(
  "cashback/aoorove",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cashbackService.approveCashback(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for coupons
const cashbackSlice = createSlice({
  name: "cashback",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(approveCashback.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveCashback.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.coupon = action.payload;
      })
      .addCase(approveCashback.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getAllCashback.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllCashback.fulfilled, (state, action) => {
        console.log("BBBBBBBBBB",action)
        state.isLoading = false;
        state.cashbacks = action.payload;
      })
      .addCase(getAllCashback.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      });
  },
});

export const { reset } = cashbackSlice.actions;
export default cashbackSlice.reducer;
