/* eslint-disable react/jsx-key */
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useState, useMemo } from "react";
import { logout } from "../redux/auth/auth.slices";
import { useDispatch, useSelector } from "react-redux";
import menuItems from "../routes/_naLinks";
import { useEffect } from "react";

const Sidebar = () => {
  const [toggleCollapse,setToggleCollapse] = useState(true);
  const [isCollapsible,setIsCollapsible] = useState(false);

  const router = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      router("/login");
    }
  }, [router, user]);

  const activeMenu = useMemo(
    () => menuItems.find((menu) => menu.link === router.pathname),
    [router.pathname]
  );

  const wrapperClasses = classNames(
    "px-4 pt-8 pb-4 bg-light flex justify-between flex-col",
    {
      ["w-60"]: !toggleCollapse,
      ["w-20"]: toggleCollapse,
    }
  );

  const collapseIconClasses = classNames(
    "p-4 rounded bg-light-lighter absolute right-0",
    {
      "rotate-180": toggleCollapse,
    }
  );

  const getNavItemClasses = (menu) => {
    return classNames(
      "flex items-center cursor-pointer hover:bg-light-lighter rounded w-full overflow-hidden whitespace-nowrap",
      {
        ["bg-light-lighter"]: activeMenu?.id === menu.id,
      }
    );
  };

  const onMouseOver = () => {
    setIsCollapsible(!isCollapsible);
  };

  const handleSidebarToggle = () => {
    setToggleCollapse(!toggleCollapse);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div
      className={`${wrapperClasses}  border-r border-gray-400 bg-gray-200 overflow-scroll h-full`}
      // onMouseEnter={onMouseOver}
      // onMouseLeave={onMouseOver}
      onMouseEnter={handleSidebarToggle}
      onMouseLeave={handleSidebarToggle}
      style={{
        transition: "width 300ms cubic-bezier(0.2, 0, 0, 1) 0s",
        height: "93vh",
      }}
    >
      <div className="flex flex-col">
        <div className="flex items-center justify-between relative">
          {isCollapsible && (
            <button
              className={collapseIconClasses}
              onClick={handleSidebarToggle}
            >
              <i className="ri-arrow-left-line"></i>
            </button>
          )}
        </div>

        <div className="flex flex-col items-start mt-4">
          {menuItems.map(({ icon, ...menu }, i) => {
            const classes = getNavItemClasses(menu);
            return (
              <div className={classes} key={i}>
                <NavLink
                  to={menu.link}
                  className={({ isActive }) =>
                    isActive
                      ? "w-full text-yellow-800 underline"
                      : "inactive hover:text-red-800 active:bg-white"
                  }
                >
                  <div className="flex my-4 px-3 items-center w-full h-full">
                    <div style={{ width: "2.5rem" }}>
                      <i className={icon}></i>
                    </div>
                    {!toggleCollapse && (
                      <span className={classNames("text-md text-text-light")}>
                        {menu.label}
                      </span>
                    )}
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={`${getNavItemClasses({})} px-3 py-4`}
        onClick={handleLogout}
      >
        <div style={{ width: "2.5rem" }}>
          <i className="ri-logout-box-line"></i>
        </div>
        {!toggleCollapse && (
          <span className={classNames("text-md font-medium text-text-light")}>
            Logout
          </span>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
