import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { reset, updateFaq } from "../../redux/faqs/faq.slices";
import axios from "axios";
function EditQuestions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { isLoading, isSuccess } = useSelector((state) => state.faq);
  const { user } = useSelector((state) => state.auth);

  const [question, setQuestion] = useState(null);
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "questions/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log("MMMMMMMMMMMCc", json)
        // const faq = json.faqs;
        setQuestion(json.userQuestion);
        setAnswer(json.adminReply);
      });
  }, [dispatch, id, user?.token]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Updated FAQ successfully");
      dispatch(reset());
      navigate("/questions");
    }
  }, [dispatch, isSuccess, navigate]);

  function handleSave(e) {
    e.preventDefault();

    submitQuestionAns(id)
    // dispatch(
    //   updateFaq({
    //     id,
    //     data: {
    //       question,
    //       answer,
    //     },
    //   })
    // );
  }

  const submitQuestionAns = (id) => {

    let data = {}

    data.id = id
    data.adminReply = answer


    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL +'answers',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.token}`,
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        navigate("/questions");

      })
      .catch((error) => {
        console.log(error);
      });

  }






  return (
    <>
      <h1 className="text-3xl text-center text-red-600">Edit or reply FAQ</h1>
      <div className="flex items-center justify-center p-12">
        <div className="mx-auto w-full">
          <form onSubmit={handleSave}>
            <div className="grid grid-cols-2 gap-5">
              <div>
                <div className="my-4">Question</div>
                <textarea
                  rows="4"
                  placeholder="Type faq question"
                  className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                ></textarea>
              </div>
              <div>
                <div className="my-4">Answer</div>
                <textarea
                  rows="4"
                  placeholder="Type faq answer"
                  className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="mt-5">
              <button
                type="submit"
                className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditQuestions;
