import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FileUploader } from "react-drag-drop-files";
import BreadCrumb from "../../components/BreadCrumb";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { reset, updateCoupon } from "../../redux/coupons/coupon.slices";
import { getAllCategories } from "../../redux/categories/categories.slices";
import { getAllSubCategories } from "../../redux/subcategories/subcategories.slices";
import { getAllTags } from "../../redux/tags/tag.slices";
import { getAllStores } from "../../redux/stores/stores.slices";
import axios from "axios";
import JoditEditor from "jodit-react";
import { editorConfig } from "../../utils/editorConfig";
const animatedComponents = makeAnimated();
export default function EditCoupon() {
	const [title, setTitle] = useState(null);
	const [admin_votes, setAdminVotes] = useState(0);
	const [admin_clicks, setAdminClicks] = useState(0);
	const [description, setDescription] = useState(null);
	const [text, setText] = useState(null);
	const [category, setCategory] = useState(null);
	const [store, setStore] = useState(null);
	const [coupon_type, setCouponType] = useState(null);
	const [coupon_format, setCouponFormat] = useState(null);
	const [affiliate_url, setAffiliateUrl] = useState(null);
	const [imageTypeDesktop, setimageTypeDesktop] = useState(null);
	const [imageTypeMobile, setimageTypeMobile] = useState(null);
	const [isTabs, setIsTabs] = useState(null);
	const [isBankOffers, setIsBankOffers] = useState(false);
	const [selectedBank, setSelectedBank] = useState(null);
	const [tabs, setTabs] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]);
	const [selectedSubCategory, setSelectedSubCategory] = useState([]);
	const [imageMobile, setImageMObile] = useState([]);
	const [imageDesktop, setImageDesktop] = useState([]);
	const [imageMobileAlt, setImageMObileAlt] = useState("");
	const [imageDesktopAlt, setImageDesktopAlt] = useState("");
	const [contentBelow, setContentBelow] = useState([]);
	const [offertext2, setOffertext2] = useState(null);
	const [offertext1, setOffertext1] = useState(null);
	const [storeBankOffers, setStoreBankOffers] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = useParams();
	const {
		reset: resetForm, register,
		formState: { errors },
		clearErrors,
	} = useForm();

	const { categories } = useSelector((state) => state.category);
	const { subcategories } = useSelector((state) => state.subcategory);
	const { stores } = useSelector((state) => state.store);
	const { tags } = useSelector((state) => state.tag);
	const { isSuccess, isLoading } = useSelector((state) => state.coupon);
	const { user } = useSelector((state) => state.auth);
	const editor = useRef(null);
	const config = useMemo(() => editorConfig, []);

	useEffect(() => {
		dispatch(getAllCategories());
		dispatch(getAllSubCategories());
		dispatch(getAllTags());
		dispatch(getAllStores());
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success("Added coupon successfully");
			resetForm();
			dispatch(reset());
			const searchParams = new URLSearchParams(location.search);
			console.log("hdhshsd", searchParams);
			const currentPage = searchParams.get('page') || 1;
			const searchKewword = searchParams.get('searchkeyword') || '';
			navigate(`/coupons?page=${currentPage}&searchkeyword=${searchKewword}`);
			
		}
	}, [isSuccess]);

	const tabsOption = [
		{ value: "What is the Offer", label: "What is the Offer" },
		{ value: "How to use it", label: "How to use it" },
		{ value: "Comments", label: "Comments" },
		{ value: "Share", label: "Share" },
	];
	
	const handleStoreChange = (selectedStore) => {
		
		findBankOffers(selectedStore?.value);
	};
	 const findBankOffers = (storeName) => {
		const store = stores.find(store => store._id === storeName);

		if (store) {
			if (store?.bankOffers && store?.bankOffers?.length > 0) {
				const bankOption = store?.bankOffers.map((bankName) => ({
					value: bankName,
					label: bankName,
				}));
				setStoreBankOffers(bankOption);
			}



		} else {
			
			setStoreBankOffers(null);
		}
	};
	const subCategoriesOptpions = subcategories?.map((sub) => ({
		value: sub?._id,
		label: sub?.name,
	}));

	const tagsOption = tags?.map((sub) => ({
		value: sub?._id,
		label: sub?.name,
	}));

	const [loadingDesktop, setLoadingDesktop] = useState(false);
	const [loadingMobile, setLoadingMobile] = useState(false);

	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "coupon/" + id, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${user?.token}`,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				const coupon = json.coupon;

				setTitle(coupon?.title);
				setDescription(coupon?.description);
				setText(coupon?.text);
				setAffiliateUrl(coupon?.affiliate_url);
				setTabs(
					coupon?.content_below?.map((t) => {
						return {
							value: t.tab,
							label: t.tab,
							tab: t.tab,
							desc: t.desc,
						};
					})
				);
				setContentBelow(coupon?.content_below);
				setIsTabs(coupon?.is_tabs);
				setIsBankOffers(coupon?.is_bank_offers);
				setSelectedBank(coupon?.bankOffers?.map((t) => {
					
					return {
						label: t,
						value: t,
					};
				}))

			
				setCouponFormat(coupon?.coupon_format);
				setimageTypeDesktop(coupon?.image_format_desktop);
				setimageTypeMobile(coupon?.image_format_mobile);

				setImageDesktopAlt(coupon?.desktop_alt_tag);
				setImageMObileAlt(coupon?.mobile_alt_tag);

				setImageDesktop(coupon?.desktop_image);
				setImageMObile(coupon?.mobile_image);
				setCategory(coupon?.category);
				setSelectedSubCategory(
					coupon?.subcategories?.map((t) => {
						return {
							label: t?.name,
							value: t?._id,
						};
					})
				);
				setStore(coupon?.store);
				setCouponType(coupon?.coupon_type);
				setAdminClicks(coupon?.admin_clicks);
				setAdminVotes(coupon?.admin_votes);
				setOffertext1(coupon?.offer_text_1);
				setOffertext2(coupon?.offer_text_2)

				setSelectedTags(
					coupon?.tags?.map((t) => {
						return {
							label: t?.name,
							value: t?._id,
						};
					})
				);
			});
	}, [dispatch, id]);

	const handleDescChange = (tab) => {
		const newData = contentBelow?.map((item) => {
			if (item.tab === tab.tab) {
				return { ...item, desc: tab.value };
			} else {
				return item;
			}
		});
		setContentBelow([
			...newData.filter((item) => item.tab !== tab.tab),
			{
				tab: tab.tab,
				desc: tab.value,
			},
		]);
	};

	const handleImageMobile = (e) => {
		// e.preventDefault();
		setLoadingMobile(true);
		try {
			Array.from(e.target.files).map(async (i) => {
				let file = i;

				let formData = new FormData();
				formData.append("image", file);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
					},
				};
				const res = await axios
					.post(
						process.env.REACT_APP_API_URL + `images`,
						formData,
						config
					)
					.then(({ data }) => {
						setImageMObile((prev) => [
							...prev,
							data?.data?.uploadedUrl,
						]);

						toast.success("Success");
					});
				return res;
			});
		} catch (error) {
			toast.error(
				error.response.data.message || error.response.data.error
			);
		} finally {
			setLoadingMobile(false);
		}
	};

	const handleImageDesktop = (e) => {
		// e.preventDefault();
		setLoadingDesktop(true);
		try {
			Array.from(e.target.files).map(async (i) => {
				let file = i;

				let formData = new FormData();
				formData.append("image", file);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
					},
				};
				const res = await axios
					.post(
						process.env.REACT_APP_API_URL + `images`,
						formData,
						config
					)
					.then(({ data }) => {
						setImageDesktop((prevImageDesktop) => [
							...prevImageDesktop,
							data?.data?.uploadedUrl,
						]);

						toast.success("Success");
					});

				return res;
			});
		} catch (err) {
			toast.error(err.message);
		} finally {
			setLoadingDesktop(false);
		}
	};
	useEffect(() => {
		console.log("JGHGHGJG")
	}, [storeBankOffers])

	function handleSave(e) {
		e.preventDefault();

		if (imageDesktop === "album" && imageDesktop?.length < 3) {
			return toast.warn("please provide atleast 3 images");
		}
		if (imageTypeMobile === "album" && imageMobile?.length < 3) {
			return toast.warn("please provide atleast 3 images");
		}

		const newtabs = tabs?.map((t) => {
			return {
				tab: t.value,
				desc: "",
			};
		});

		const filteredContent = contentBelow.filter((obj1) => {
			return newtabs.some((obj2) => obj2.tab === obj1.tab);
		});

		const combinedArray = newtabs.concat(filteredContent);

		const uniqueMembers = Object.values(
			combinedArray.reduce((acc, obj) => {
				acc[obj.tab] = obj;
				return acc;
			}, new Map())
		);

		dispatch(
			updateCoupon({
				id,
				data: {
					title,
					text,
					description,
					affiliate_url,
					coupon_format,
					content_below: uniqueMembers,
					coupon_type,
					is_tabs: isTabs,
					is_bank_offers: isBankOffers,
					// bankOffers: selectedBank && selectedBank !== null ? selectedBank.value : null,
					bankOffers: (selectedBank && isBankOffers && selectedBank.length > 0 && selectedBank !== null) ? selectedBank.map((tab) => tab.value) : null,
				
					admin_clicks,
					admin_votes,
					offer_text_2: offertext2,
					offer_text_1: offertext1,
					tabs: tabs.map((tab) => tab.value),
					tags: selectedTags.map((tag) => tag.value),
					subcategories: selectedSubCategory?.map(
						(subcat) => subcat.value
					),
					desktop_image: imageDesktop,
					mobile_image: imageMobile,
					mobile_alt_tag: imageMobileAlt,
					desktop_alt_tag: imageDesktopAlt,
					image_format_desktop: imageTypeDesktop,
					image_format_mobile: imageTypeMobile,
				},
			})
		);


	}

	const preventMinus = (e) => {
		if (e.code === "Minus") {
			e.preventDefault();
		}
		const keyCode = e.keyCode;

		const excludedKeys = [8, 37, 39, 46];

		if (
			!(
				(keyCode >= 48 && keyCode <= 57) ||
				(keyCode >= 96 && keyCode <= 105) ||
				excludedKeys.includes(keyCode)
			)
		) {
			e.preventDefault();
		}
	};

	return (
		<>
			<div className="flex gap-2 justify-between px-8 my-4">
				<BreadCrumb title={"Coupon Add"} link="coupons" />
				<h1 className="text-2xl text-center text-red-600">
					Edit Coupon or Offer
				</h1>
				<div className="flex gap-2"></div>
			</div>
			<div className="flex items-center justify-center p-12">
				<div className="mx-auto w-full">
					<form className="container px-24" onSubmit={handleSave}>
						<div className="grid grid-cols-2 gap-5">
							<div>
								<label
									htmlFor="name"
									className="mb-3 block text-base font-medium text-[#07074D]"
								>
									Store name
								</label>
								{/* <select
									className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
									onChange={(e) => setStore(e.target.value)}
								>
									<option value={store?._id}>
										{store?.name}
									</option>
									{stores?.map((s) => (
										<option value={s._id}>{s.name}</option>
									))}
								</select> */}
								{stores.length !== 0 ?
									<Select {...register("store", { required: true })}
										onChange={(e) => {
											setStore("store", e.value);
											clearErrors("store");
											handleStoreChange(e);
										}}
										options={stores?.map((s) => ({ value: s._id, label: s.name }))} />
									: <Select />}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Category name
								</label>
								<select
									className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
									onChange={(e) =>
										setCategory(e.target.value)
									}
								>
									<option value={category?._id}>
										{category?.name}
									</option>
									{categories?.map((cat) => (
										<option value={cat._id}>
											{cat.name}
										</option>
									))}
								</select>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Sub Categories
								</label>

								<Select
									components={animatedComponents}
									isMulti
									name="subcategories"
									className="basic-multi-select"
									placeholder="select a subcategory to display..."
									classNamePrefix="select"
									closeMenuOnSelect={false}
									options={subCategoriesOptpions}
									value={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e)}
								/>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Tags
								</label>

								<Select
									components={animatedComponents}
									isMulti
									name="tags"
									className="basic-multi-select"
									placeholder="select the tags to interlink..."
									classNamePrefix="select"
									closeMenuOnSelect={false}
									options={tagsOption}
									value={selectedTags}
									onChange={(e) => setSelectedTags(e)}
								/>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Title
								</label>
								<textarea
									rows="3"
									placeholder="Type coupon title here..."
									className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={title}
									onChange={(e) => setTitle(e.target.value)}
								></textarea>
								{errors.title && (
									<span className="text-red-700">
										Title is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Description
								</label>
								<div>
									<JoditEditor
										ref={editor}
										value={description}
										config={config}
										onChange={(value) => { }}
										onBlur={(content) =>
											setDescription(content)
										}
									/>
								</div>
							</div>
							
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Affiliate Url
								</label>
								<input
									type="text"
									placeholder="enter affilaite Url e.g www.mytra.com/MYT004"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									value={affiliate_url}
									onChange={(e) =>
										setAffiliateUrl(e.target.value)
									}
								/>
							</div>
							

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Coupon type
								</label>
								<select
									className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
									value={coupon_type}
									onChange={(e) =>
										setCouponType(e.target.value)
									}
								>
									<option value={coupon_type}>
										{coupon_type}
									</option>
									<option value="Coupon">Coupon</option>
									<option value="Deal">Deal</option>
									{/* <option value="Bank offer">
										Bank offer
									</option>
									<option value="Wallet offer">
										Wallet offer
									</option> */}
								</select>
							</div>
							</div>
							{storeBankOffers && storeBankOffers?.length >0 &&
							(
								<>
							<label className="mb-3 block text-base font-medium mt-5 text-[#07074D]">
								Bank Ofers
							</label>
							<div className="flex items-center space-x-6 mb-5">
								<div className="flex items-center">
									<input
										type="radio"
										name="tabs"
										className="h-5 w-5"
										value={true}
										checked={isBankOffers}
										onChange={(e) => setIsBankOffers(true)}
									/>
									<label
										for="radioButton1"
										className="pl-3 text-base font-medium text-gray-400"
									>
										Yes
									</label>
								</div>
								<div className="flex items-center">
									<input
										type="radio"
										name="tabs"
										className="h-5 w-5"
										checked={!isBankOffers}
										value={false}
										onChange={(e) => setIsBankOffers(false)}
									/>
									<label
										for="radioButton2"
										className="pl-3 text-base font-medium text-gray-400"
									>
										No
									</label>
								</div>
							</div>
							{isBankOffers && (
								<Select
									components={animatedComponents}
									isMulti
									name="tabs"
									className="basic-multi-select"
									placeholder="select Bank "
									classNamePrefix="select"
									closeMenuOnSelect={false}
									options={storeBankOffers}
									value={selectedBank}
									onChange={(e) => setSelectedBank(e)}
								/>
							)}
							</>
						)
						}
							
						

					
						<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Coupon format
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="coupon_format"
											className="h-5 w-5"
											checked={coupon_format === "Text"}
											onChange={(e) =>
												setCouponFormat("Text")
											}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Text format
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="coupon_format"
											className="h-5 w-5"
											checked={coupon_format === "Image"}
											onChange={(e) =>
												setCouponFormat("Image")
											}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Image format
										</label>
									</div>
								</div>
							</div>

						{coupon_format === "Text" && (
							<textarea
								rows="3"
								placeholder="Type text for coupon here"
								className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
								value={text}
								onChange={(e) => setText(e.target.value)}
							></textarea>
						)}
						{coupon_format === "Image" && (
							<div className="grid grid-cols-1 gap-5 mt-5">
								<div className="grid grid-cols-2">
									<div>
										<label className="mb-3 block text-base font-medium text-[#07074D]">
											Desktop Image
										</label>
										<div className="flex items-center space-x-6 mb-5">
											<div className="flex items-center">
												<input
													type="radio"
													name="image_format_desktop"
													className="h-5 w-5"
													checked={
														imageTypeDesktop ===
														"single"
													}
													onChange={(e) =>
														setimageTypeDesktop(
															"single"
														)
													}
												/>
												<label
													for="radioButton1"
													className="pl-3 text-base font-medium text-gray-400"
												>
													Single
												</label>
											</div>
											<div className="flex items-center">
												<input
													type="radio"
													name="image_format_desktop"
													className="h-5 w-5"
													checked={
														imageTypeDesktop ===
														"album"
													}
													onChange={(e) =>
														setimageTypeDesktop(
															"album"
														)
													}
												/>
												<label
													for="radioButton2"
													className="pl-3 text-base font-medium text-gray-400"
												>
													Album
												</label>
											</div>
											<div className="flex items-center">
												<input
													type="radio"
													name="image_format_desktop"
													className="h-5 w-5"
													checked={
														imageTypeDesktop ===
														"gif"
													}
													onChange={(e) =>
														setimageTypeDesktop(
															"gif"
														)
													}
												// {...register("image_format_desktop")}
												/>
												<label
													for="radioButton2"
													className="pl-3 text-base font-medium text-gray-400"
												>
													Gif
												</label>
											</div>
										</div>
										{imageTypeDesktop === "single" ||
											imageTypeDesktop === "gif" ? (
											<>
												{imageDesktop.length > 0 ? (
													<div class="image-preview relative">
														<img
															src={
																imageDesktop[0]
															}
															alt=""
														/>
														<button
															onClick={() =>
																setImageDesktop(
																	[]
																)
															}
															className="btn absolute top-0 right-0 z-40 bg-white"
														>
															<i className="dropdown-icon ri-delete-bin-6-line"></i>
														</button>
													</div>
												) : (
													<div class="flex">
														<label
															for="file-upload1"
															class="upload-button"
														>
															<i class="ri-file-add-line"></i>
														</label>

														<input
															id="file-upload1"
															type="file"
															name="desktop_image"
															size="large"
															onChange={
																handleImageDesktop
															}
															accept="image/*"
															hidden
														/>
													</div>
												)}
											</>
										) : (
											imageTypeDesktop === "album" && (
												<div className="flex flex-wrap gap-4 w-full p-4">
													{imageDesktop.length > 0 &&
														imageDesktop?.map(
															(image, i) => (
																<div
																	key={i}
																	class="image-preview relative"
																>
																	<img
																		src={
																			image
																		}
																		alt=""
																	/>
																	<button
																		onClick={() =>
																			setImageDesktop(
																				imageDesktop?.filter(
																					(
																						img
																					) =>
																						img !==
																						image
																				)
																			)
																		}
																		className="btn absolute top-0 right-0 z-40 bg-white"
																	>
																		<i className="dropdown-icon ri-delete-bin-6-line"></i>
																	</button>
																</div>
															)
														)}

													<div class="flex items-center justify-center">
														<label
															for="file-upload1"
															class="upload-button"
														>
															<i class="ri-file-add-line"></i>
														</label>
														<input
															id="file-upload1"
															type="file"
															multiple
															name="desktop_image"
															size="large"
															onChange={
																handleImageDesktop
															}
															accept="image/*"
															hidden
														/>
													</div>
												</div>
											)
										)}
									</div>
									<div>
										<div className="w-[100%]">
											<label className="mb-3 block text-base font-medium text-[rgb(7,7,77)]">
												Mobile Image
											</label>
											<div className="flex items-center space-x-6 mb-5 w-full">
												<div className="flex items-center">
													<input
														type="radio"
														name="image_format_mobile"
														className="h-5 w-5"
														checked={
															imageTypeMobile ===
															"single"
														}
														onChange={(e) =>
															setimageTypeMobile(
																"single"
															)
														}
													// {...register("image_format")}
													/>
													<label
														for="radioButton1"
														className="pl-3 text-base font-medium text-gray-400"
													>
														Single
													</label>
												</div>
												<div className="flex items-center">
													<input
														type="radio"
														name="image_format_mobile"
														className="h-5 w-5"
														checked={
															imageTypeMobile ===
															"album"
														}
														onChange={(e) =>
															setimageTypeMobile(
																"album"
															)
														}
													// {...register("image_format")}
													/>
													<label
														for="radioButton2"
														className="pl-3 text-base font-medium text-gray-400"
													>
														Album
													</label>
												</div>
												<div className="flex items-center">
													<input
														type="radio"
														name="image_format_mobile"
														className="h-5 w-5"
														checked={
															imageTypeMobile ===
															"gif"
														}
														onChange={(e) =>
															setimageTypeMobile(
																"gif"
															)
														}
													/>
													<label
														for="radioButton2"
														className="pl-3 text-base font-medium text-gray-400"
													>
														Gif
													</label>
												</div>
											</div>

											{imageTypeMobile === "single" ||
												imageTypeMobile === "gif" ? (
												<>
													{imageMobile.length > 0 ? (
														<div class="image-preview relative">
															<img
																src={
																	imageMobile[0]
																}
																alt=""
															/>
															<button
																onClick={() =>
																	setImageMObile(
																		[]
																	)
																}
																className="btn absolute top-0 right-0 z-40 bg-white"
															>
																<i className="dropdown-icon ri-delete-bin-6-line"></i>
															</button>
														</div>
													) : (
														<div class="flex">
															<label
																for="file-upload"
																class="upload-button"
															>
																<i class="ri-file-add-line"></i>
															</label>

															<input
																id="file-upload"
																type="file"
																name="mobile_image"
																size="large"
																onChange={
																	handleImageMobile
																}
																accept="image/*"
																hidden
															/>
														</div>
													)}
												</>
											) : (
												imageTypeMobile === "album" && (
													<div className="flex flex-wrap gap-4 w-full p-4">
														{imageMobile.length >
															0 &&
															imageMobile?.map(
																(image, i) => (
																	<div
																		key={i}
																		class="image-preview relative"
																	>
																		<img
																			src={
																				image
																			}
																			alt=""
																		/>
																		<button
																			onClick={() =>
																				setImageMObile(
																					imageMobile?.filter(
																						(
																							img
																						) =>
																							img !==
																							image
																					)
																				)
																			}
																			className="btn absolute top-0 right-0 z-40 bg-white"
																		>
																			<i className="dropdown-icon ri-delete-bin-6-line"></i>
																		</button>
																	</div>
																)
															)}

														<div class="flex items-center justify-center">
															<label
																for="file-upload"
																class="upload-button"
															>
																<i class="ri-file-add-line"></i>
															</label>
															<input
																id="file-upload"
																type="file"
																multiple
																name="mobile_image"
																size="large"
																onChange={
																	handleImageMobile
																}
																accept="image/*"
																hidden
															/>
														</div>
													</div>
												)
											)}
										</div>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-4 my-4">
									<div>
										<label className="mb-3 block text-base font-medium text-[#07074D]">
											Desktop Image Alt tag
										</label>
										<input
											type="text"
											placeholder="provide alt text for desktop images"
											value={imageDesktopAlt}
											onChange={(e) =>
												setImageDesktopAlt(
													e.target.value
												)
											}
											className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
										/>
									</div>
									<div>
										<label className="mb-3 block text-base font-medium text-[#07074D]">
											Mobile Image Alt tag
										</label>
										<input
											type="text"
											placeholder="provide alt text for mobile images"
											value={imageMobileAlt}
											onChange={(e) =>
												setImageMObileAlt(
													e.target.value
												)
											}
											className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
										/>
									</div>
								</div>
							</div>
						)}
					{(storeBankOffers && storeBankOffers.length > 0) && (
						<div>
						<label className="mb-3 block text-base font-medium mt-5 text-[#07074D]">
							Bank Ofers
						</label>
						<div className="flex items-center space-x-6 mb-5">
							<div className="flex items-center">
								<input
									type="radio"
									name="bankoffers"
									className="h-5 w-5"
									checked={isBankOffers}
									onChange={(e) => setIsBankOffers(true)}
								/>
								<label
									for="radioButton1"
									className="pl-3 text-base font-medium text-gray-400"
								>
									Yes
								</label>
							</div>
							<div className="flex items-center">
								<input
									type="radio"
									name="bankoffers"
									className="h-5 w-5"
									checked={!isBankOffers}
									onChange={(e) => setIsBankOffers(false)}
								/>
								<label
									for="radioButton2"
									className="pl-3 text-base font-medium text-gray-400"
								>
									No
								</label>
							</div>
						</div>
						{isBankOffers && (
							<Select
								components={animatedComponents}
								isMulti
								name="tabs"
								className="basic-multi-select"
								placeholder="select Bank "
								classNamePrefix="select"
								// closeMenuOnSelect={false}
								options={storeBankOffers}
								value={selectedBank}
								onChange={(e) => setSelectedBank(e)}
							/>
						)}
					</div>

					)}	
						<div>
							<label className="mb-3 block text-base font-medium text-[#07074D]">
								Sections (details)
							</label>
							<div className="flex items-center space-x-6 mb-5">
								<div className="flex items-center">
									<input
										type="radio"
										name="tabs"
										className="h-5 w-5"
										checked={isTabs}
										onChange={(e) => setIsTabs(true)}
									/>
									<label
										for="radioButton1"
										className="pl-3 text-base font-medium text-gray-400"
									>
										Add sections
									</label>
								</div>
								<div className="flex items-center">
									<input
										type="radio"
										name="tabs"
										className="h-5 w-5"
										checked={!isTabs}
										onChange={(e) => setIsTabs(false)}
									/>
									<label
										for="radioButton2"
										className="pl-3 text-base font-medium text-gray-400"
									>
										No sections required
									</label>
								</div>
							</div>
							{isTabs && (
								<Select
									components={animatedComponents}
									isMulti
									name="tabs"
									className="basic-multi-select"
									placeholder="select the tabs to display..."
									classNamePrefix="select"
									closeMenuOnSelect={false}
									options={tabsOption}
									value={tabs}
									onChange={(e) => {
										setTabs(e);
									}}
								/>
							)}
						</div>
						<div className="grid grid-cols-1 gap-5 mt-3">
							{isTabs && (
								<>
									<div className="my-4">
										{tabs?.map((tab, i) => (
											<details key={i} className="group">
												<summary className="flex items-center font-medium cursor-pointer list-none bg-[#F5F5F5] p-2">
													<span className="transition group-open:rotate-180">
														<svg
															width="23"
															height="22"
															viewBox="0 0 23 22"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M11.7001 0.057373C5.65134 0.057373 0.750488 4.95823 0.750488 11.007C0.750488 17.0558 5.65134 21.9567 11.7001 21.9567C17.7489 21.9567 22.6498 17.0558 22.6498 11.007C22.6498 4.95823 17.7489 0.057373 11.7001 0.057373ZM18.058 12.2433C18.058 12.5347 17.8196 12.7731 17.5282 12.7731H13.4662V16.8351C13.4662 17.1265 13.2278 17.3649 12.9364 17.3649H10.4639C10.1725 17.3649 9.93407 17.1265 9.93407 16.8351V12.7731H5.8721C5.5807 12.7731 5.34228 12.5347 5.34228 12.2433V9.77078C5.34228 9.47938 5.5807 9.24096 5.8721 9.24096H9.93407V5.17899C9.93407 4.88758 10.1725 4.64916 10.4639 4.64916H12.9364C13.2278 4.64916 13.4662 4.88758 13.4662 5.17899V9.24096H17.5282C17.8196 9.24096 18.058 9.47938 18.058 9.77078V12.2433Z"
																fill="black"
															/>
														</svg>
													</span>
													<span className="ml-4">
														{tab?.value} Content
														Below the fold
													</span>
												</summary>
												<div>
													<JoditEditor
														ref={editor}
														value={tab?.desc}
														config={config}
														onBlur={(value) =>
															handleDescChange({
																tab: tab?.value,
																value,
															})
														}
													/>
												</div>
											</details>
										))}
									</div>
								</>
							)}
						</div>
						<div className="grid grid-cols-2 gap-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Admin votes
								</label>
								<input
									type="number"
									placeholder="enter admin votes"
									min={"0"}
									onKeyDown={preventMinus}
									value={admin_votes}
									onChange={(e) =>
										setAdminVotes(e.target.value)
									}
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
								/>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Admin clicks
								</label>
								<input
									type="number"
									placeholder="enter admin clicks"
									min={"0"}
									onKeyDown={preventMinus}
									value={admin_clicks}
									onChange={(e) =>
										setAdminClicks(e.target.value)
									}
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
								/>
							</div>
						</div>

						<div className="grid grid-cols-2  my-4 gap-5">


							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Offer Text 1
								</label>
								<div>
									<JoditEditor
										ref={editor}
										value={offertext1}
										config={config}
										onChange={(value) => { }}
										onBlur={(content) =>
											setOffertext1(content)
										}
									/>
								</div>
							</div>



							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Offer Text 2
								</label>
								<div>
									<JoditEditor
										ref={editor}
										value={offertext2}
										config={config}
										onChange={(value) => { }}
										onBlur={(content) =>
											setOffertext2(content)
										}
									/>
								</div>
							</div>
						</div>
						<div className="mt-5">
							<button
								type="submit"
								className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
							>
								{isLoading ? "Saving..." : "Save"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
