import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "store/";

// POST new Store
const addStore = async (token, Store) => {
  const res = await axios.post(API_URL, Store, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// GET all Store
const getAllStores = async (token) => {
  const res = await axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.stores;
};

// GET an Store
const getStore = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.store;
};

// Delete a Store
const deleteStore = async (id, token) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an Store
const updateStore = async (Store, id, token) => {
  const res = await axios.patch(API_URL + id, Store, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

const storeService = {
  addStore,
  getStore,
  updateStore,
  deleteStore,
  getAllStores,
};

export default storeService;
