import React from "react";
import { Link } from "react-router-dom";

export default function BreadCrumb({ link, title }) {
  return (
    <div className="flex items-center">
      <Link
        class="block text-blue-600 w-24 hover:text-[#003049] rounded-lg text-sm px-5 py-2.5 text-center"
        type="button"
        to={`/${link}`}
      >
        Go back
      </Link>
      <span className="text-xl">
        || {"  "} {title}
      </span>
    </div>
  );
}
