import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../redux/categories/categories.slices";
import { getAllStores } from "../../redux/stores/stores.slices";
import { getAllFaqs } from "../../redux/faqs/faq.slices";
import JoditEditor from "jodit-react";
import { editorConfig } from "../../utils/editorConfig";
import Loader from "../../components/Loader";

const animatedComponents = makeAnimated();

function SettingsHome() {
  const dispatch = useDispatch();

  const { stores } = useSelector((state) => state.store);
  const { categories } = useSelector((state) => state.category);
  const { faqs } = useSelector((state) => state.faq);
  const { user } = useSelector((state) => state.auth);

  const [isPending, setIsPending] = useState(false);
  const [status, setStatus] = useState("");
  const [imageType, setimageType] = useState("");
  const [imageTypeStore, setimageTypeStore] = useState("");
  // const [contentAbove, setContentAbove] = useState(false);
  const [isTabsAbove, setIsTabsAbove] = useState(false);
  const [isTabs, setIsTabs] = useState(false);
  const [selectedTabsAbove, setSelectedTabsAbove] = useState([]);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [checkedTabs, setCheckedTabs] = useState([]);
  const [isFaQs, setIsFaQs] = useState(false);
  const [home_url, setHomeUrl] = useState(null);
  const [isCategory, setIsCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isStore, setIsStore] = useState(false);
  const [selectedStore, setSelectedStore] = useState([]);
  const [imageStore, setImageStore] = useState([]);
  const [imageCategory, setImageCategory] = useState([]);
  const [category_banner, setCategoryBanner] = useState(false);
  const [store_banner, setStoreBanner] = useState(null);
  const [content_above, setContentAbove] = useState(null);
  const [is_content_above, setIsContentAbove] = useState(false);
  const [is_content_below, setIsContentBelow] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [contentBelow, setContentBelow] = useState([]);
  const [tabs, setTabs] = useState(null);
  const [meta_title, setMetaTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [home_banner, setHomeBanner] = useState(false);
  const [home_banner_image, setHomeBannerImage] = useState([]);
  const [altTagText, setAltTagText] = useState("");
  const [imageMobile, setImageMObile] = useState([]);
  const [imageDesktop, setImageDesktop] = useState([]);
  const [box1imageDesktop, setBox1imageDesktop] = useState([]);
  const [box2imageDesktop, setBox2imageDesktop] = useState([]);
  const [box3imageDesktop, setBox3imageDesktop] = useState([]);

  const editor = useRef([]);
  const config = useMemo(() => editorConfig, []);


  const handleImageMobile = (e) => {
    // e.preventDefault();
    try {
      setLoading(true);
      Array.from(e.target.files).map((i) => {
        let file = i;

        let formData = new FormData();
        formData.append("image", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + `images`,
            formData,
            config
          )
          .then(({ data }) => {
            // set image in the state
            setImageMObile((prev) => [
              ...prev,
              // data.data[0].location,
              data?.data?.uploadedUrl,
            ]);
            toast.success("Success");
            setLoading(false);
          });
      });
      return;
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    } finally {
      // setLoading(false);
    }
  };

  const handleImageDesktop = (e) => {
    // e.preventDefault();
    try {
      setLoading(true);
      Array.from(e.target.files).map((i) => {
        let file = i;
        let formData = new FormData();
        formData.append("image", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        axios.post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
          .then(({ data }) => {
            setImageDesktop((prev) => [
              ...prev,
              data?.data?.uploadedUrl,
            ]);
            toast.success("Success");
            setLoading(false);
          });
      });
    } catch (error) {
      toast.error(
        error.response.data.message || error.response.data.error
      );
      setLoading(false);
    } finally {
      // setLoading(false);
    }
  };

  const handleBox1ImageDesktop = (e) => {
    // e.preventDefault();
    try {
      setLoading(true);
      Array.from(e.target.files).map((i) => {
        let file = i;
        let formData = new FormData();
        formData.append("image", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        axios.post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
          .then(({ data }) => {
            setBox1imageDesktop((prev) => [
              ...prev,
              data?.data?.uploadedUrl,
            ]);
            toast.success("Success");
            setLoading(false);
          });
      });
    } catch (error) {
      toast.error(
        error.response.data.message || error.response.data.error
      );
      setLoading(false);
    } finally {
      // setLoading(false);
    }
  };
  const handleBox2ImageDesktop = (e) => {
    // e.preventDefault();
    try {
      setLoading(true);
      Array.from(e.target.files).map((i) => {
        let file = i;
        let formData = new FormData();
        formData.append("image", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        axios.post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
          .then(({ data }) => {
            setBox2imageDesktop((prev) => [
              ...prev,
              data?.data?.uploadedUrl,
              // data?.data?.uploadedUrl,
            ]);
            toast.success("Success");
            setLoading(false);
          });
      });
    } catch (error) {
      toast.error(
        error.response.data.message || error.response.data.error
      );
      setLoading(false);
    } finally {
      // setLoading(false);
    }
  };
  const handleBox3ImageDesktop = (e) => {
    // e.preventDefault();
    try {
      setLoading(true);
      Array.from(e.target.files).map((i) => {
        let file = i;
        let formData = new FormData();
        formData.append("image", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        axios.post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
          .then(({ data }) => {
            setBox3imageDesktop((prev) => [
              ...prev,
              data?.data?.uploadedUrl,
              // data?.data?.uploadedUrl,
            ]);
            toast.success("Success");
            setLoading(false);
          });
      });
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response.data.message || error.response.data.error
      );
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    const fetchHomePageApi = async () => {
      try {
        // setIsPending(true);
        setLoading(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}settings/settings`
        );

        const setting = data?.data;
        setStatus(setting?.status);
        setHomeUrl(setting?.home_url);
        setContentAbove(setting?.content_above);
        setSelectedTabsAbove(
          setting?.tabs_above?.map((tab) => ({
            value: tab,
            label: tab,
          }))
        );
        setCheckedTabs(
          setting?.tabs?.map((tab) => ({
            value: tab,
            label: tab,
          }))
        );
        setSelectedTabs(
          setting?.tabs?.map((tab) => ({
            value: tab,
            label: tab,
          }))
        );
        setTabs(
          setting?.content_below?.map((t) => {
            return {
              value: t.tab,
              label: t.tab,
              tab: t.tab,
              desc: t.desc,
            };
          })
        );
        setIsCategory(setting?.category_section);
        setIsStore(setting?.store_section);
        setSelectedCategory(setting?.categories);
        setSelectedStore(setting?.stores);
        // setFaqsList(setting?.faqs);
        setIsContentAbove(setting?.is_content_above);
        setIsContentBelow(setting?.is_content_below);
        setContentBelow(setting?.content_below || []);
        // setFormData(setting?.content_below);
        setIsTabsAbove(setting?.is_tabs);
        setIsTabs(setting?.is_tabs_below);
        setStoreBanner(setting?.store_banner);
        setIsFaQs(setting?.is_faqs);
        setHomeBanner(setting?.home_banner);
        setHomeBannerImage(setting?.home_banner_image || []);
        setBox1imageDesktop(setting?.home_box1_banner_image || []);
        setBox2imageDesktop(setting?.home_box2_banner_image || []);
        setBox3imageDesktop(setting?.home_box3_banner_image || []);
        setImageMObile(setting?.home_banner_mobile_image || [])
        setCategoryBanner(setting?.category_banner);
        setImageCategory(setting?.category_images || []);
        setImageStore(setting?.store_images || []);
        setimageType(setting?.category_image_format);
        setimageTypeStore(setting?.store_image_format);
        setMetaTitle(setting?.meta_title);
        setDescription(setting?.description);
        setAltTagText(setting?.home_banner_image_alt_tag);
        setSelectedCategory(
          setting?.categories?.map((cat) => ({
            value: cat?._id,
            label: cat?.name,
            image: cat?.icon,
            url: cat?.url,
          }))
        );
        setSelectedStore(
          setting?.stores?.map((store) => ({
            value: store?._id,
            label: store?.name,
            image: store?.icon,
            url: store?.url,
          }))
        );
        setAllQuestions(
          setting?.faqs?.map((faq) => ({
            value: faq?._id,
            label: faq?.question,
            answer: faq?.answer,
          }))
        );
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      } finally {
        // setIsPending(false);
      }
    };
    fetchHomePageApi();
  }, []);

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllStores());
    dispatch(getAllFaqs());
  }, []);

  const [loading, setLoading] = useState(false);

  const handleDescChange = (tab) => {
    const newData = contentBelow?.map((item) => {
      if (item?.tab === tab?.tab) {
        return { ...item, desc: tab.value };
      } else {
        return item;
      }
    });
    setContentBelow([
      ...newData?.filter((item) => item.tab !== tab.tab),
      {
        tab: tab.tab,
        desc: tab.value,
      },
    ]);
  };

  const handleImage = (e) => {
    try {
      setLoading(true);
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("image", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
        .then(({ data }) => {
          // @ts-ignore
          setHomeBannerImage([data?.data?.uploadedUrl]);
          // setHomeBannerImage(data?.data?.uploadedUrl);
          toast.success("Success");
          setLoading(false);
        });
    } catch (error) {
      toast.error(
        error.response.data.message || error.response.data.error || "Somthing wents Wrong"
      );
      setLoading(false);
    } finally {
      // setLoading(false);
    }
  };

  const handleimageStore = (e) => {
    // e.preventDefault();
    try {
      setLoading(true);
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("image", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
        .then(({ data }) => {
          if (data?.data?.uploadedUrl) {
            setImageStore([...imageStore, data?.data?.uploadedUrl]);
          }
          toast.success("Success");
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response.data.message || error.response.data.error
      );
    } finally {
      // setLoading(false);
    }
  };

  const handleimageCategory = (e) => {
    e.preventDefault();
    // setLoading(true);
    try {
      setLoading(true);
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("image", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + `images`,
          formData,
          config
        )
        .then(({ data }) => {
          if (data?.data?.uploadedUrl) {
            setImageCategory([...imageCategory, data?.data?.uploadedUrl]);
          }
          toast.success("Success");
          setLoading(false);
        });
    } catch (error) {
      toast.error(
        error.response.data.message || error.response.data.error
      );
      setLoading(false);
    } finally {
      // setLoading(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      setIsPending(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}settings`,
        {
          status,
          home_url,
          is_content_above,
          is_content_below,
          is_tabs: isTabsAbove,
          is_tabs_below: isTabs,
          is_faqs: isFaQs,
          content_above,
          tabs: selectedTabs?.map((al) => al?.value),
          tabs_above: selectedTabsAbove?.map((al) => al?.value),
          store_banner,
          home_banner_image_alt_tag: altTagText,
          category_banner,
          category_section: isCategory,
          store_section: isStore,
          categories: selectedCategory?.map((c) => c?.value),
          stores: selectedStore?.map((s) => s?.value),
          faqs: allQuestions?.map((al) => al?.value),
          category_image_format: imageType,
          store_image_format: imageTypeStore,
          category_images: imageCategory,
          store_images: imageStore,
          content_below: contentBelow,
          meta_title,
          description,
          home_banner,
          home_box1_banner_image: box1imageDesktop,
          home_box2_banner_image: box2imageDesktop,
          home_box3_banner_image: box3imageDesktop,
          home_banner_image: home_banner_image,
          home_banner_mobile_image: imageMobile
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      toast.success("Saved successfully");
      setIsPending(false);
    } catch (error) {
      toast.error(error.message);
      setIsPending(false);
    } finally {
      // setIsPending(false);
    }
  };

  const tabsOption = [
    { value: "Offers", label: "Offers" },
    { value: "Videos", label: "Videos" },
    { value: "Must read", label: "Must read" },
    { value: "Reviews", label: "Reviews" },
  ];

  const categoriesOptpions = categories?.map((sub) => ({
    value: sub?._id,
    label: sub?.name,
    image: sub?.icon,
    url: sub?.url,
  }));

  const storesOptions = stores?.map((sub) => ({
    value: sub?._id,
    label: sub?.name,
    image: sub?.icon,
    url: sub?.url,
  }));

  return (
    loading ?
      <div class="flex items-center justify-center h-screen">
        <div class="text-white p-8 rounded-lg">
          <Loader /></div>
      </div>
      :
      <>
        <div className="flex gap-2 justify-between px-8 my-4">
          <h1 className="text-2xl text-center text-red-600">
            Homepage settings
          </h1>
          <div className="flex gap-2"></div>
        </div>
        <div className="flex items-center justify-center p-12">
          <div className="mx-auto w-full">
            <form className="container px-24" onSubmit={handleSave}>
              <div className="grid grid-cols-2 gap-5">
                <div>
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Homepage Status
                  </label>
                  <div className="flex items-center space-x-6 mb-5">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="status"
                        className="h-5 w-5"
                        value={"active"}
                        checked={status === "active"}
                        onChange={(e) =>
                          setStatus("active")
                        }
                      />
                      <label
                        htmlFor="radioButton1"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Active
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="status"
                        className="h-5 w-5"
                        value={"inactive"}
                        checked={status === "inactive"}
                        onChange={(e) =>
                          setStatus("inactive")
                        }
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Inactive
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="status"
                        className="h-5 w-5"
                        value={"delete"}
                        checked={status === "delete"}
                        onChange={(e) =>
                          setStatus("delete")
                        }
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Delete
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlhtmlFor="name"
                    className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Homepage Url
                  </label>
                  <input
                    type="text"
                    placeholder="www.rezgari.com"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                    value={home_url}
                    onChange={(e) => setHomeUrl(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Homepage content above fold
                </label>
                <div className="flex items-center space-x-6 mb-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="content_above"
                      className="h-5 w-5"
                      value={true}
                      checked={is_content_above}
                      onChange={(e) =>
                        setIsContentAbove(true)
                      }
                    />
                    <label
                      htmlFor="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Add content above
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="content_above"
                      className="h-5 w-5"
                      value={false}
                      checked={!is_content_above}
                      onChange={(e) =>
                        setIsContentAbove(false)
                      }
                    />
                    <label
                      htmlFor="radioButton2"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Not required
                    </label>
                  </div>
                </div>
                {is_content_above && (
                  <textarea
                    rows="4"
                    placeholder="Type category content above fold here"
                    className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                    value={content_above}
                    onChange={(e) =>
                      setContentAbove(e.target.value)
                    }
                  ></textarea>
                )}
              </div>

              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Homepage banner
                </label>
                <div className="flex items-center space-x-6 mb-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="home_banner"
                      className="h-5 w-5"
                      value={true}
                      checked={home_banner}
                      onChange={(e) => setHomeBanner(true)}
                    />
                    <label
                      htmlFor="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Add homepage banner
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="home_banner"
                      className="h-5 w-5"
                      value={false}
                      checked={!home_banner}
                      onChange={(e) => {
                        setHomeBanner(false);
                        setHomeBannerImage([]);
                      }}
                    />
                    <label
                      htmlFor="radioButton2"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Not required
                    </label>
                  </div>
                </div>
                {home_banner && (
                  <>
                    {home_banner_image?.length ? (
                      <div class="image-preview relative">
                        <img
                          src={home_banner_image[0]}
                          alt=""
                        />
                        <button
                          onClick={() =>
                            setHomeBannerImage([])
                          }
                          className="btn absolute top-0 right-0 z-40 bg-white"
                        >
                          <i className="dropdown-icon ri-delete-bin-6-line"></i>
                        </button>
                      </div>
                    ) : (
                      <div class="flex w-full">
                        <label
                          htmlFor="file-upload-home"
                          class="upload-button"
                        >
                          <i class="ri-file-add-line"></i>
                        </label>
                        <input
                          id="file-upload-home"
                          type="file"
                          name="home_banner_image"
                          size="large"
                          onChange={handleImage}
                          accept="image/*"
                          hidden
                        />
                      </div>
                    )}
                    <div className="grid grid-cols-2">
                      <div>
                        <label className="mb-3 block text-base font-medium text-[#07074D]">
                          Desktop Image
                        </label>

                        {/* Box 1 images start  */}
                        <label className="mb-3 block text-base font-medium text-[#07074D]">
                          Box 1 Image
                        </label>

                        <div className="flex flex-wrap gap-4">
                          {box1imageDesktop.length > 0 &&
                            box1imageDesktop?.map(
                              (image, i) => (
                                <div
                                  key={i}
                                  class="image-preview relative"
                                >
                                  <img
                                    src={
                                      image
                                    }
                                    alt=""
                                  />
                                  <button
                                    onClick={() =>

                                      setBox1imageDesktop(
                                        box1imageDesktop?.filter(
                                          (
                                            img
                                          ) =>
                                            img !==
                                            image
                                        )
                                      )
                                    }
                                    className="btn absolute top-0 right-0 z-40 bg-white"
                                  >
                                    <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                  </button>
                                </div>
                              )
                            )}

                          <div class="flex items-center justify-center">
                            <label
                              for="box1-file-upload"
                              class="upload-button"
                            >
                              <i class="ri-file-add-line"></i>
                            </label>
                            <input
                              id="box1-file-upload"
                              type="file"
                              multiple
                              name="box1_desktop_image"
                              size="large"
                              onChange={

                                handleBox1ImageDesktop
                              }
                              accept="image/*"
                              hidden
                            />
                          </div>
                        </div>
                        {/* Box 2 images start  */}
                        <label className="mb-3 block text-base font-medium text-[#07074D]">
                          Box 2 Image
                        </label>


                        <div className="flex flex-wrap gap-4">
                          {box2imageDesktop.length > 0 &&
                            box2imageDesktop?.map(
                              (image, i) => (
                                <div
                                  key={i}
                                  class="image-preview relative"
                                >
                                  <img
                                    src={
                                      image
                                    }
                                    alt=""
                                  />
                                  <button
                                    onClick={() =>

                                      setBox2imageDesktop(
                                        box2imageDesktop?.filter(
                                          (
                                            img
                                          ) =>
                                            img !==
                                            image
                                        )
                                      )
                                    }
                                    className="btn absolute top-0 right-0 z-40 bg-white"
                                  >
                                    <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                  </button>
                                </div>
                              )
                            )}

                          <div class="flex items-center justify-center">
                            <label
                              for="box2-file-upload"
                              class="upload-button"
                            >
                              <i class="ri-file-add-line"></i>
                            </label>
                            <input
                              id="box2-file-upload"
                              type="file"
                              multiple
                              name="box2_desktop_image"
                              size="large"
                              onChange={

                                handleBox2ImageDesktop
                              }
                              accept="image/*"
                              hidden
                            />
                          </div>
                        </div>

                        {/* Box 3 images Start  */}
                        <label className="mb-3 block text-base font-medium text-[#07074D]">
                          Box 3 Image
                        </label>


                        <div className="flex flex-wrap gap-4">
                          {box3imageDesktop.length > 0 &&
                            box3imageDesktop?.map(
                              (image, i) => (
                                <div
                                  key={i}
                                  class="image-preview relative"
                                >
                                  <img
                                    src={
                                      image
                                    }
                                    alt=""
                                  />
                                  <button
                                    onClick={() =>

                                      setBox3imageDesktop(
                                        box3imageDesktop?.filter(
                                          (
                                            img
                                          ) =>
                                            img !==
                                            image
                                        )
                                      )
                                    }
                                    className="btn absolute top-0 right-0 z-40 bg-white"
                                  >
                                    <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                  </button>
                                </div>
                              )
                            )}

                          <div class="flex items-center justify-center">
                            <label
                              for="box3_file-upload"
                              class="upload-button"
                            >
                              <i class="ri-file-add-line"></i>
                            </label>
                            <input
                              id="box3_file-upload"
                              type="file"
                              multiple
                              name="box3_desktop_image"
                              size="large"
                              onChange={

                                handleBox3ImageDesktop
                              }
                              accept="image/*"
                              hidden
                            />
                          </div>
                        </div>


                      </div>
                      <div>
                        {/* Mobile images start  */}
                        <label className="mb-3 block text-base font-medium text-[#07074D]">
                          Mobile Image
                        </label>

                        {

                          <div className="flex flex-wrap gap-4">
                            {imageMobile.length > 0 &&
                              imageMobile?.map(
                                (image, i) => (
                                  <div
                                    key={i}
                                    class="image-preview relative"
                                  >
                                    <img
                                      src={
                                        image
                                      }
                                      alt=""
                                    />
                                    <button
                                      onClick={() =>
                                        setImageMObile(
                                          imageMobile?.filter(
                                            (
                                              img
                                            ) =>
                                              img !==
                                              image
                                          )
                                        )
                                      }
                                      className="btn absolute top-0 right-0 z-40 bg-white"
                                    >
                                      <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                    </button>
                                  </div>
                                )
                              )}

                            <div class="flex items-center justify-center">
                              <label
                                for="file-upload-mobile"
                                class="upload-button"
                              >
                                <i class="ri-file-add-line"></i>
                              </label>
                              <input
                                id="file-upload-mobile"
                                multiple
                                type="file"
                                name="mobile_image"
                                size="large"
                                onChange={
                                  handleImageMobile
                                }
                                accept="image/*"
                                hidden
                              />
                            </div>
                          </div>

                        }
                      </div>
                    </div>

                    <div className="mt-4">
                      <label
                        htmlFor="name"
                        className="mb-3 block text-base font-medium text-[#07074D]"
                      >
                        Home banner alt tag text
                      </label>
                      <input
                        type="text"
                        placeholder="enter home banner alt text here"
                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                        value={altTagText}
                        onChange={(e) =>
                          setAltTagText(e.target.value)
                        }
                      />
                    </div>
                  </>
                )}
              </div>


              <div className="grid grid-cols-1 gap-5 mt-5">
                <div>
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Tabs
                  </label>
                  <div className="flex items-center space-x-6 mb-5">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="tabs"
                        className="h-5 w-5"
                        value={true}
                        checked={isTabsAbove}
                        onChange={(e) =>
                          setIsTabsAbove(true)
                        }
                      />
                      <label
                        htmlFor="radioButton1"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Add tabs
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="tabs"
                        className="h-5 w-5"
                        value={false}
                        checked={!isTabsAbove}
                        onChange={(e) =>
                          setIsTabsAbove(false)
                        }
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        No tab required
                      </label>
                    </div>
                  </div>
                  {isTabsAbove && (
                    <Select
                      components={animatedComponents}
                      isMulti
                      name="users"
                      className="basic-multi-select"
                      placeholder="select the tabs to display..."
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      options={tabsOption}
                      value={selectedTabsAbove}
                      onChange={(e) =>
                        setSelectedTabsAbove(e)
                      }
                    />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-5 mt-3">
                {isTabsAbove && (
                  <>
                    <div>
                      {selectedTabsAbove?.map((tab, i) => (
                        <div
                          key={i}
                          class="inline-flex items-center border border-gray-700 px-5 py-3 my-3 mr-5"
                        >
                          <span class="text-gray-700">
                            {tab?.value}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div className="grid grid-cols-1 gap-5 mt-5">
                <div>
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Category section
                  </label>
                  <div className="flex items-center space-x-6 mb-5">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="categoryOption"
                        className="h-5 w-5"
                        // value={isTabsAbove}
                        checked={isCategory}
                        onChange={(e) =>
                          setIsCategory(true)
                        }
                      />
                      <label
                        htmlFor="radioButton1"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="categoryOption"
                        className="h-5 w-5"
                        // value={isTabsAbove}
                        checked={!isCategory}
                        onChange={(e) =>
                          setIsCategory(false)
                        }
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {isCategory && (
                    <Select
                      components={animatedComponents}
                      isMulti
                      name="tabs"
                      className="basic-multi-select"
                      placeholder="select the tabs to display..."
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      options={categoriesOptpions}
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e)}
                    />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-5 mt-3">
                {isCategory && (
                  <>
                    <div>
                      {selectedCategory?.map((tab, i) => (
                        <div
                          className="grid grid-cols-4 gap-5 mt-3"
                          key={i}
                        >
                          <div>Category: {i + 1}</div>
                          <div
                            key={i}
                            class="inline-flex items-center border border-gray-700 px-5 py-3 my-3 mr-5"
                          >
                            <span class="text-gray-700">
                              {tab?.label}
                            </span>
                          </div>
                          <div
                            key={i}
                            class="inline-flex items-center border border-gray-700 px-5 py-3 my-3 mr-5"
                          >
                            <img
                              src={tab?.image}
                              alt=""
                              className="h-8"
                            />
                          </div>
                          <div
                            key={i}
                            class="inline-flex items-center border border-gray-700 px-5 py-3 my-3"
                          >
                            <a
                              href={tab?.url}
                              target="_blank"
                              rel="noreferrer"
                              class="text-gray-700  overflow-ellipsis truncate"
                            >
                              {tab?.url}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div className="grid grid-cols-1 gap-5 mt-5">
                <div>
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Store section
                  </label>
                  <div className="flex items-center space-x-6 mb-5">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="storeOptions"
                        className="h-5 w-5"
                        // value={isStore}
                        checked={isStore}
                        onChange={(e) => setIsStore(true)}
                      />
                      <label
                        htmlFor="radioButton1"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="storeOptions"
                        className="h-5 w-5"
                        // value={isStore}
                        checked={!isStore}
                        onChange={(e) => setIsStore(false)}
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {isStore && (
                    <Select
                      components={animatedComponents}
                      isMulti
                      name="users"
                      className="basic-multi-select"
                      placeholder="select the tabs to display..."
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      options={storesOptions}
                      value={selectedStore}
                      onChange={(e) => setSelectedStore(e)}
                    />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-5 mt-3">
                {isStore && (
                  <>
                    <div>
                      {selectedStore?.map((tab, i) => (
                        <div
                          className="grid grid-cols-4 gap-5 mt-3"
                          key={i}
                        >
                          <div>Store: {i + 1}</div>
                          <div
                            key={i}
                            class="inline-flex items-center border border-gray-700 px-5 py-3 my-3 mr-5"
                          >
                            <span class="text-gray-700">
                              {tab?.label}
                            </span>
                          </div>
                          <div
                            key={i}
                            class="inline-flex items-center border border-gray-700 px-5 py-3 my-3 mr-5"
                          >
                            <img
                              src={tab?.image}
                              alt=""
                              className="h-8"
                            />
                          </div>
                          <div
                            key={i}
                            class="inline-flex items-center border border-gray-700 px-5 py-3 my-3 overflow-hidden"
                          >
                            <a
                              href={tab?.url}
                              target="_blank"
                              rel="noreferrer"
                              class="text-gray-700 overflow-ellipsis truncate"
                            >
                              {tab?.url}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div className="grid grid-cols-1 gap-5 mt-5">
                <div>
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Homepage Content below the fold Tabs
                  </label>
                  <div className="flex items-center space-x-6 mb-5">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="contentbelow"
                        className="h-5 w-5"
                        checked={isTabs}
                        onChange={(e) => setIsTabs(true)}
                      />
                      <label
                        htmlFor="radioButton1"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Add tabs
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="contentbelow"
                        className="h-5 w-5"
                        checked={!isTabs}
                        onChange={(e) => {
                          setIsTabs(false);
                          setContentBelow([]);
                          setTabs([]);
                        }}
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        No tab required
                      </label>
                    </div>
                  </div>
                  {isTabs && (
                    <Select
                      components={animatedComponents}
                      isMulti
                      name="users"
                      className="basic-multi-select"
                      placeholder="select the tabs to display..."
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      options={tabsOption}
                      value={tabs}
                      onChange={(e) => setTabs(e)}
                    />
                  )}
                </div>
              </div>
              <label className="mb-3 block text-base font-medium text-[#07074D]">
                Content below fold
              </label>
              <div className="flex items-center space-x-6 mb-5">
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="contentBelow"
                    className="h-5 w-5"
                    checked={is_content_below}
                    onChange={(e) => setIsContentBelow(true)}
                  />
                  <label
                    htmlFor="radioButton1"
                    className="pl-3 text-base font-medium text-gray-400"
                  >
                    Add content below
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="contentBelow"
                    className="h-5 w-5"
                    checked={!is_content_below}
                    onChange={(e) => setIsContentBelow(false)}
                  />
                  <label
                    htmlFor="radioButton2"
                    className="pl-3 text-base font-medium text-gray-400"
                  >
                    Not required
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5 mt-3">
                {is_content_below && (
                  <>
                    <div className="my-4">
                      {tabs?.map((tab, i) => (
                        <details
                          key={i + "-" + tab.value}
                          className="group"
                        >
                          <summary className="flex items-center font-medium cursor-pointer list-none bg-[#F5F5F5] p-2">
                            <span className="transition group-open:rotate-180">
                              <svg
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.7001 0.057373C5.65134 0.057373 0.750488 4.95823 0.750488 11.007C0.750488 17.0558 5.65134 21.9567 11.7001 21.9567C17.7489 21.9567 22.6498 17.0558 22.6498 11.007C22.6498 4.95823 17.7489 0.057373 11.7001 0.057373ZM18.058 12.2433C18.058 12.5347 17.8196 12.7731 17.5282 12.7731H13.4662V16.8351C13.4662 17.1265 13.2278 17.3649 12.9364 17.3649H10.4639C10.1725 17.3649 9.93407 17.1265 9.93407 16.8351V12.7731H5.8721C5.5807 12.7731 5.34228 12.5347 5.34228 12.2433V9.77078C5.34228 9.47938 5.5807 9.24096 5.8721 9.24096H9.93407V5.17899C9.93407 4.88758 10.1725 4.64916 10.4639 4.64916H12.9364C13.2278 4.64916 13.4662 4.88758 13.4662 5.17899V9.24096H17.5282C17.8196 9.24096 18.058 9.47938 18.058 9.77078V12.2433Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            <span className="ml-4">
                              {tab?.value} Content
                              Below the fold
                            </span>
                          </summary>
                          <div>
                            <JoditEditor
                              ref={editor}
                              value={tab?.desc}
                              config={config}
                              onBlur={(value) =>
                                handleDescChange({
                                  tab: tab?.value,
                                  value,
                                })
                              }
                            />
                          </div>
                        </details>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Category Banner
                </label>
                <div className="flex items-center space-x-6 mb-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="categoryBananer"
                      className="h-5 w-5"
                      checked={category_banner}
                      onChange={(e) =>
                        setCategoryBanner(true)
                      }
                    />
                    <label
                      htmlFor="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="categoryBananer"
                      className="h-5 w-5"
                      checked={!category_banner}
                      onChange={(e) =>
                        setCategoryBanner(false)
                      }
                    />
                    <label
                      htmlFor="radioButton2"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              {category_banner && (
                <div className="mb-8">
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Category Image
                  </label>
                  <div className="flex items-center space-x-6 mb-5">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="imageType"
                        className="h-5 w-5"
                        checked={imageType === "single"}
                        onChange={(e) => {
                          setimageType("single");
                          setImageCategory([]);
                        }}
                      />
                      <label
                        htmlFor="radioButton1"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Single Image
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="imageType"
                        className="h-5 w-5"
                        checked={imageType === "album"}
                        onChange={(e) => {
                          setimageType("album");
                          setImageCategory([]);
                        }}
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Album
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="imageType"
                        className="h-5 w-5"
                        checked={imageType === "gif"}
                        onChange={(e) => {
                          setimageType("gif");
                          setImageCategory([]);
                        }}
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Gif
                      </label>
                    </div>
                  </div>
                  {(imageType === "single" || imageType === "gif") ? (
                    <>
                      {imageCategory?.length > 0 ? (
                        <div class="image-preview relative">
                          <img
                            src={imageCategory[0]}
                            alt=""
                          />
                          <button
                            onClick={() =>
                              setImageCategory([])
                            }
                            className="btn absolute top-0 right-0 z-40 bg-white"
                          >
                            <i className="dropdown-icon ri-delete-bin-6-line"></i>
                          </button>
                        </div>
                      ) : (
                        <div class="flex">
                          <label
                            htmlFor="file-upload-category-single-gif"
                            class="upload-button"
                          >
                            <i class="ri-file-add-line"></i>
                          </label>

                          <input
                            id="file-upload-category-single-gif"
                            type="file"
                            name="mobile_image"
                            size="large"
                            onChange={
                              handleimageCategory
                            }
                            accept="image/*"
                            hidden
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    imageType === "album" && (
                      <div className="flex flex-wrap gap-4">
                        {imageCategory?.length > 0 &&
                          imageCategory?.map(
                            (image, i) => (
                              <div
                                key={i}
                                class="image-preview relative"
                              >
                                <img
                                  src={image}
                                  alt=""
                                />
                                <button
                                  onClick={() =>
                                    setImageCategory(
                                      []
                                    )
                                  }
                                  className="btn absolute top-0 right-0 z-40 bg-white"
                                >
                                  <i className="dropdown-icon ri-delete-bin-6-line"></i>
                                </button>
                              </div>
                            )
                          )}

                        <div class="flex items-center justify-center">
                          <label
                            htmlFor="file-upload-category-album"
                            class="upload-button"
                          >
                            <i class="ri-file-add-line"></i>
                          </label>
                          <input
                            id="file-upload-category-album"
                            type="file"
                            name="mobile_image"
                            size="large"
                            onChange={
                              handleimageCategory
                            }
                            accept="image/*"
                            hidden
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Store Banner
                </label>
                <div className="flex items-center space-x-6 mb-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="store_banner"
                      className="h-5 w-5"
                      checked={store_banner}
                      onChange={(e) => setStoreBanner(true)}
                    />
                    <label
                      htmlFor="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="store_banner"
                      className="h-5 w-5"
                      checked={!store_banner}
                      onChange={(e) => setStoreBanner(false)}
                    />
                    <label
                      htmlFor="radioButton2"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              {store_banner && (
                <div className="mb-8">
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Store Image
                  </label>
                  <div className="flex items-center space-x-6 mb-5">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="imageTypeStore"
                        className="h-5 w-5"
                        checked={
                          imageTypeStore === "single"
                        }
                        onChange={(e) => {
                          setimageTypeStore("single");
                          setImageStore([]);
                        }}
                      />
                      <label
                        htmlFor="radioButton1"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Single Image
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="imageTypeStore"
                        className="h-5 w-5"
                        checked={imageTypeStore === "album"}
                        onChange={(e) => {
                          setimageTypeStore("album");
                          setImageStore([]);
                        }}
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Album
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="imageTypeStore"
                        className="h-5 w-5"
                        checked={imageTypeStore === "gif"}
                        onChange={(e) => {
                          setimageTypeStore("gif");
                          setImageStore([]);
                        }}
                      />
                      <label
                        htmlFor="radioButton2"
                        className="pl-3 text-base font-medium text-gray-400"
                      >
                        Gif
                      </label>
                    </div>
                  </div>
                  {imageTypeStore === "single" ||
                    imageTypeStore === "gif" ? (
                    <>
                      {imageStore?.length > 0 ? (
                        <div class="image-preview relative">
                          <img
                            src={imageStore[0]}
                            alt=""
                          />
                          <button
                            onClick={() =>
                              setImageStore([])
                            }
                            className="btn absolute top-0 right-0 z-40 bg-white"
                          >
                            <i className="dropdown-icon ri-delete-bin-6-line"></i>
                          </button>
                        </div>
                      ) : (
                        <div class="flex">
                          <label
                            htmlFor="file-upload"
                            class="upload-button"
                          >
                            <i class="ri-file-add-line"></i>
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            name="store_image"
                            size="large"
                            onChange={handleimageStore}
                            accept="image/*"
                            hidden
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    imageTypeStore === "album" && (
                      <div className="flex flex-wrap gap-4">
                        {imageStore?.length > 0 &&
                          imageStore?.map((image, i) => (
                            <div
                              key={i}
                              class="image-preview relative"
                            >
                              <img
                                src={image}
                                alt=""
                              />
                              <button
                                onClick={() =>
                                  setImageStore(
                                    []
                                  )
                                }
                                className="btn absolute top-0 right-0 z-40 bg-white"
                              >
                                <i className="dropdown-icon ri-delete-bin-6-line"></i>
                              </button>
                            </div>
                          ))}

                        <div class="flex items-center justify-center">
                          <label
                            htmlFor="file-upload"
                            class="upload-button"
                          >
                            <i class="ri-file-add-line"></i>
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            name="store_image"
                            size="large"
                            onChange={handleimageStore}
                            accept="image/*"
                            hidden
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}

              <div className="grid grid-cols-1 gap-5 my-5">
                <div>
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Meta title
                  </label>

                  <input
                    type="text"
                    placeholder="enter meta title"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                    value={meta_title}
                    onChange={(e) =>
                      setMetaTitle(e.target.value)
                    }
                  />
                </div>

                <div>
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Description
                  </label>

                  <textarea
                    rows="4"
                    placeholder="Type description here"
                    className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                    value={description}
                    onChange={(e) =>
                      setDescription(e.target.value)
                    }
                  ></textarea>
                </div>
              </div>

              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  FAQs
                </label>
                <div className="flex items-center space-x-6 mb-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="faqs"
                      className="h-5 w-5"
                      checked={isFaQs}
                      onChange={(e) => setIsFaQs(true)}
                    />
                    <label
                      htmlFor="radioButton1"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Active
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="faqs"
                      className="h-5 w-5"
                      checked={!isFaQs}
                      onChange={(e) => setIsFaQs(false)}
                    />
                    <label
                      htmlFor="radioButton2"
                      className="pl-3 text-base font-medium text-gray-400"
                    >
                      Inactive
                    </label>
                  </div>
                </div>
                {isFaQs && (
                  <div>
                    <Select
                      components={animatedComponents}
                      isMulti
                      name="users"
                      className="basic-multi-select"
                      placeholder="select the tabs to display..."
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      options={faqs?.map((faq) => ({
                        value: faq?._id,
                        label: faq?.question,
                        answer: faq?.answer,
                      }))}
                      value={allQuestions}
                      onChange={(e) => setAllQuestions(e)}
                    />{" "}
                    {allQuestions?.map((al, i) => (
                      <div
                        key={i}
                        className="grid grid-cols-2 gap-5"
                      >
                        <div>
                          <div className="my-4">
                            Question {i + 1}
                          </div>
                          <textarea
                            rows="2"
                            placeholder="Type faq question"
                            className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                            value={al?.label}
                            disabled
                          ></textarea>
                        </div>
                        <div>
                          <div className="my-4">
                            Answer {i + 1}
                          </div>
                          <textarea
                            rows="2"
                            placeholder="Type faq answer"
                            className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                            value={al?.answer}
                            disabled
                          ></textarea>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="mt-5">
                <button
                  type="submit"
                  className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
                >
                  {isPending ? "Saving..." : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
  );
}

export default SettingsHome;
