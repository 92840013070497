import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import moment from "moment";
import SearchInput from "../../components/SearchInput";
import DeleteModal from "../../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, reset } from "../../redux/users/user.slices";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

function UserHome() {
	const dispatch = useDispatch();

	const [usersFiltered, setusersFiltered] = useState([]);
	const [showModal, setShowModal] = React.useState(false);
	const [keyword, setKeyword] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(15);

	const { users, isLoading, isSuccess } = useSelector((state) => state.user);

	useEffect(() => {
		dispatch(getAllUsers());
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success("Deleted successfully");
			dispatch(reset());
			dispatch(getAllUsers());
			setShowModal(false);
		}
	}, [isSuccess]);

	useEffect(() => {
		const re = new RegExp(keyword, "i");

		setusersFiltered(
			users?.filter((entry) =>
				Object.values(entry).some(
					(val) => typeof val == "string" && val.match(re)
				)
			)
		);
	}, [keyword, users]);

	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = usersFiltered.slice(
		indexOfFirstRecord,
		indexOfLastRecord
	);
	const nPages = Math.ceil(usersFiltered.length / recordsPerPage);

	function handleDelete(id) {
		// dispatch(deleteUse(id));
	}

	return (
		<>
			<section className="container mx-auto p-6 font-mono">
				<div className="flex my-auto items-center justify-between">
					<SearchInput
						autoComplete="on"
						onChange={(e) => setKeyword(e.target.value)}
						value={keyword}
					/>
					<h1 className="text-2xl text-red-700 underline">Users</h1>
				</div>

				<div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
					<div className="w-full overflow-x-auto">
						<table className="w-full">
							<thead>
								<tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
									<th className="px-4 py-3">ID</th>
									{/* <th className="px-4 py-3">Profile</th> */}
									<th className="px-4 py-3">Name</th>
									<th className="px-4 py-3">Email</th>
									<th className="px-4 py-3">Phone</th>
									<th className="px-4 py-3">Status</th>
									<th className="px-4 py-3">Joined On</th>
									<th className="px-4 py-3">Actions</th>
								</tr>
							</thead>
							<tbody className="bg-white">
								{isLoading ? (
									<tr>
										<td colSpan={6}>
											<Loader />
										</td>
									</tr>
								) : currentRecords.length <= 0 ? (
									<tr>
										<td
											className="flex m-5 col-span-6"
											colSpan={6}
										>
											No records found
										</td>
									</tr>
								) : (
									currentRecords.map((user, i) => (
										<tr className="text-gray-700">
											<td className="px-4 py-3 border">
												<div className="flex items-center text-sm">
													<p className="font-semibold text-black">
														0{(i + 1) + ((currentPage - 1) * 10)}
													</p>
												</div>
											</td>
											{/* <td className="px-4 py-3 border">
                        <div class="relative">
                          <img
                            class="w-10 h-10 rounded-full"
                            src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                            alt=""
                          />
                          <span class="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div>
                      </td> */}

											<td className="px-4 py-3 text-ms font-semibold border">
												{user?.first_name}{" "}
												{user?.last_name}
											</td>
											<td className="px-4 py-3 text-ms font-semibold border">
												{user?.email}
											</td>
											<td className="px-4 py-3 text-ms font-semibold border">
												{user?.phone}
											</td>

											<td className="px-4 py-3 text-xs border">
												<span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">
													{user?.is_active
														? "active"
														: "not active"}
												</span>
											</td>
											<td className="px-4 py-3 text-sm border">
												{moment(user?.createdAt).format(
													"YYYY MMM, DD"
												)}
											</td>
											<td className="px-4 py-3 text-sm border">
												<div className="flex gap-5">
													<button className="btn dropdown-item">
														<i className="dropdown-icon ri-edit-line"></i>
													</button>
													<button
														onClick={() =>
															setShowModal(
																!showModal
															)
														}
														className="btn dropdown-item"
													>
														<i className="dropdown-icon ri-delete-bin-6-line"></i>
													</button>
												</div>
											</td>
											{showModal && (
												<>
													<DeleteModal
														isLoading={isLoading}
														onDeleteClick={() =>
															handleDelete(
																user?._id
															)
														}
														toggle={() =>
															setShowModal(
																!showModal
															)
														}
													/>
													<div className=" opacity-5 fixed inset-0 z-40 bg-black"></div>
												</>
											)}
										</tr>
									))
								)}
							</tbody>
							<Pagination
								nPages={nPages}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/>
						</table>
					</div>
				</div>
			</section>
		</>
	);
}

export default UserHome;
