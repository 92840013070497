import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import categoriesService from "./categories.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  categories: [],
  category: null,
};

//add new categories
export const addCategory = createAsyncThunk(
  "categories/add",
  async function (categories, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await categoriesService.addCategory(token, categories);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all categoriess
export const getAllCategories = createAsyncThunk(
  "categoriess/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await categoriesService.getAllCategories(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an categories
export const getCategory = createAsyncThunk(
  "categoriess/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await categoriesService.getCategory(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a categories
export const updateCategory = createAsyncThunk(
  "categoriess/update",
  async function (categories, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await categoriesService.updateCategory(
        categories.data,
        categories.id,
        token
      );
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// DEL a categories
export const deleteCategory = createAsyncThunk(
  "categoriess/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await categoriesService.deleteCategory(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for categoriess
const categoriesSlice = createSlice({
  name: "categoriess",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.category = action.payload;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })

      .addCase(getAllCategories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(updateCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.category = action.payload;
        state.isSuccess = true;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.category = action.payload;
        state.isSuccess = true;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = categoriesSlice.actions;
export default categoriesSlice.reducer;
