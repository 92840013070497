import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { useParams } from "react-router-dom";

const BlogDetails = () => {
	const { id } = useParams();
	const [blog, setBlog] = useState(null);

	useEffect(() => {
		const fetcher = async () => {
			await fetch(
				`https://vps54436.inmotionhosting.com/~stageserver/rezgari/wp-json/wp/v2/posts/${id}`
			)
				.then((res) => res.json())
				.then((data) => setBlog(data));
		};
		fetcher();
	}, [id]);
	return (
		<div class="mt-6 bg-gray-50">
			<BreadCrumb title={"Blog Details"} link="blogs" />

			<div class=" px-10 py-6 mx-auto">
				<div class="max-w-6xl px-10 py-6 mx-auto bg-gray-50">
					<a
						href="#_"
						class="block transition duration-200 ease-out transform hover:scale-110"
					>
						<img
							class="object-cover w-full shadow-sm h-full"
							src={blog?.jetpack_featured_media_url}
							alt=""
						/>
					</a>

					<div class="mt-6">
						<div class="sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-bold text-black  hover:underline">
							{blog?.title?.rendered}
						</div>
					</div>

					<div class="max-w-4xl px-10 pt-6 mx-auto text-2xl text-gray-700 mt-4 rounded bg-gray-100">
						<div
							dangerouslySetInnerHTML={{
								__html: blog?.content?.rendered,
							}}
						>
							{/* <p class="mt-2 p-8"></p> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogDetails;
