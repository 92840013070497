import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import subcategoriesService from "./subcategories.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  subcategories: [],
  subcategory: null,
};

//add new subcategories
export const addSubCategory = createAsyncThunk(
  "subcategories/add",
  async function (subcategories, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await subcategoriesService.addSubCategory(token, subcategories);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all subcategoriess
export const getAllSubCategories = createAsyncThunk(
  "subcategoriess/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await subcategoriesService.getAllSubCategories(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an subcategories
export const getSubCategory = createAsyncThunk(
  "subcategoriess/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await subcategoriesService.getSubCategory(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a subcategories
export const updateSubCategory = createAsyncThunk(
  "subcategoriess/update",
  async function (subcategories, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await subcategoriesService.updateSubCategory(
        subcategories.data,
        subcategories.id,
        token
      );
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// DEL a subcategories
export const deleteSubCategory = createAsyncThunk(
  "subcategoriess/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await subcategoriesService.deleteSubCategory(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for subcategoriess
const subcategoriesSlice = createSlice({
  name: "subcategoriess",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSubCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addSubCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(addSubCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getSubCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSubCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subcategory = action.payload;
      })
      .addCase(getSubCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })

      .addCase(getAllSubCategories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllSubCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subcategories = action.payload;
      })
      .addCase(getAllSubCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(updateSubCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateSubCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subcategory = action.payload;
        state.isSuccess = true;
      })
      .addCase(updateSubCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteSubCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteSubCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subcategory = action.payload;
        state.isSuccess = true;
      })
      .addCase(deleteSubCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = subcategoriesSlice.actions;
export default subcategoriesSlice.reducer;
