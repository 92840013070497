import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;

// POST new Faq
const addQuestions = async (token, Faq) => {
  const res = await axios.post(API_BASE_URL, Faq, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.faqs;
};

// GET all Faq
export const getQuestions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getQuestions`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getComments = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/questions/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


// GET an Faq
export const getQuestionById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/questions/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a Faq
export const publishQuestion = async (id) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/questions/${id}/publish`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// UPDATE an Faq
export const unpublishQuestion = async (id) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/questions/${id}/unpublish`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// UPDATE an Faq
export const publishComment = async (questionId, commentId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/questions/${questionId}/comments/${commentId}/publish`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const unpublishComment = async (questionId, commentId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/questions/${questionId}/comments/${commentId}/unpublish`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const FaqService = {
  addQuestions,
  getQuestions,
  getQuestionById,
  publishQuestion,
  unpublishQuestion,
  publishComment,
  unpublishComment,
};

export default FaqService;
