import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import couponService from "./coupon.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  coupons: [],
  coupons_graph: [],
  coupon: null,
};

//add new coupon
export const addCoupon = createAsyncThunk(
  "coupons/add",
  async function (coupon, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await couponService.addCoupon(token, coupon);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all coupons
export const getAllCoupons = createAsyncThunk(
  "coupons/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await couponService.getAllCoupons(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an coupon
export const getCoupon = createAsyncThunk(
  "coupons/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await couponService.getCoupon(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a coupon
export const updateCoupon = createAsyncThunk(
  "coupons/update",
  async function (coupon, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await couponService.updateCoupon(coupon.data, coupon.id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// DEL a coupon
export const deleteCoupon = createAsyncThunk(
  "coupons/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await couponService.deleteCoupon(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// DEL many coupon
export const deleteManyCoupon = createAsyncThunk(
  "coupons/delete/many",
  async function (ids, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await couponService.deleteManyCoupon(ids, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for coupons
const couponSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCoupon.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.coupon = action.payload;
      })
      .addCase(addCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getCoupon.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coupon = action.payload;
      })
      .addCase(getCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })

      .addCase(getAllCoupons.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coupons = action.payload;
      })
      .addCase(getAllCoupons.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(updateCoupon.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coupon = action.payload;
        state.isSuccess = true;
      })
      .addCase(updateCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteCoupon.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coupon = action.payload;
        state.isSuccess = true;
      })
      .addCase(deleteCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteManyCoupon.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteManyCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coupon = action.payload;
        state.isSuccess = true;
      })
      .addCase(deleteManyCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = couponSlice.actions;
export default couponSlice.reducer;
