import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = () => {
  const { user, isLoading, isError, message } = useSelector(
    (state) => state.auth
  );

  return (
    <div className="h-16 w-screen bg-white flex items-center justify-between px-6 border border-gray-400">
      <Link to={"/"}>
        <div className="flex items-center pl-1 gap-4">
          <i className="ri-ticket-2-line text-3xl"></i>
          <span
            className={classNames(
              "text-2xl font-medium text-text text-[#D62828]",
              {
                //   hidden: toggleCollapse,
              }
            )}
          >
            REZ<span className="text-[#003049]">GARI</span>
          </span>
        </div>
      </Link>

      <div className="flex items-center gap-4">
        <div className="mx-4">
          <i className="ri-question-fill text-3xl"></i>
        </div>
        <img
          src={
            "https://images.unsplash.com/photo-1587355760421-b9de3226a046?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80"
          }
          alt=""
          className="ring-1 rounded-full h-10 w-10"
        />{" "}
        <h3>
          {user?.first_name} {user?.last_name}
        </h3>
      </div>
    </div>
  );
};

export default Header;
