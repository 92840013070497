import React, { useEffect, useState } from "react";
import { faker } from "@faker-js/faker";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import DeleteModal from "../../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteStore,
	getAllStores,
	reset,
} from "../../redux/stores/stores.slices";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { copyToClipboard } from "../../utils/copyToClipboard";

const Stores = () => {
	const dispatch = useDispatch();

	const [storesFiltered, setstoresFiltered] = useState([]);
	const [showModal, setShowModal] = React.useState(false);

	const [keyword, setKeyword] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(15);
	const [single, setsingle] = useState("");

	const { stores, isLoading, isError, isSuccess } = useSelector(
		(state) => state.store
	);

	useEffect(() => {
		dispatch(getAllStores());
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success("Deleted successfully");
			dispatch(reset());
			dispatch(getAllStores());
			setShowModal(false);
		}
	}, [isSuccess]);

	useEffect(() => {
		const re = new RegExp(keyword, "i");

		setstoresFiltered(
			stores?.filter((entry) =>
				Object.values(entry).some(
					(val) => typeof val == "string" && val.match(re)
				)
			)
		);
	}, [keyword, stores]);

	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = storesFiltered?.slice(
		indexOfFirstRecord,
		indexOfLastRecord
	);
	const nPages = Math.ceil(storesFiltered?.length / recordsPerPage);

	function handleDelete() {
		dispatch(deleteStore(single));
	}

	return (
		<>
			<section className="container mx-auto p-6 font-mono">
				<div className="flex my-auto items-center justify-between">
					<SearchInput
						autoComplete="on"
						onChange={(e) => setKeyword(e.target.value)}
						value={keyword}
					/>
					<h1 className="text-2xl text-red-700 underline">Stores</h1>

					<Link
						class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
						type="button"
						// onClick={() => setIsOpen(!modalIsOpen)}
						to={"/stores/add"}
					>
						ADD
					</Link>
				</div>

				<div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
					<div className="w-full overflow-x-auto">
						<table className="w-full">
							<thead>
								<tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
									<th className="px-4 py-3">ID</th>
									<th className="px-4 py-3">Name</th>
									<th className="px-4 py-3">Category</th>
									<th className="px-4 py-3">Tabs</th>
									<th className="px-4 py-3">Status</th>
									<th className="px-4 py-3">Slug</th>
									<th className="px-4 py-3">Actions</th>
								</tr>
							</thead>
							<tbody className="bg-white">
								{isLoading ? (
									<tr>
										<td colSpan={7}>
											<Loader />
										</td>
									</tr>
								) : currentRecords?.length <= 0 ? (
									<tr>
										<td
											className="flex m-5 col-span-6"
											colSpan={7}
										>
											No records found
										</td>
									</tr>
								) : (
									currentRecords?.map((store) => (
										<tr className="text-gray-700">
											<td className="px-4 py-3 border">
												<div className="flex items-center text-sm">
													<p className="font-semibold text-black">
														{store?.storeId}
													</p>
												</div>
											</td>
											<td className="px-4 py-3 text-ms font-semibold border">
												{store?.name}
											</td>
											<td className="px-4 py-3 text-ms font-semibold border">
												<div class="flex gap-2">
													<span class="inline-flex items-center gap-1 rounded-full bg-red-200 px-2 py-1 text-xs font-semibold text-slate-600">
														{
															store?.categories
																?.name
														}
													</span>
												</div>
											</td>

											<td className="px-4 py-3 text-xs border">
												<div class="flex gap-2">
													{store?.tabs?.map((t) => (
														<span class="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
															{t}
														</span>
													))}
												</div>
											</td>
											<td className="px-4 py-3 text-xs border">
												<span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">
													{store?.status}
												</span>
											</td>
											<td className="px-4 py-3 text-sm border">
												{store?.slug}{" "}
												<i
													className="ri-file-copy-line cursor-pointer"
													onClick={() =>
														copyToClipboard({
															text: store?.slug,
														})
													}
												></i>
											</td>
											<td className="px-4 py-3 text-sm border">
												<div className="flex gap-5">
													<Link
														to={`/stores/${store?._id}`}
														className="btn dropdown-item"
													>
														<i className="dropdown-icon ri-eye-line"></i>
													</Link>
													<Link
														to={`/stores/${store?._id}/edit`}
														className="btn dropdown-item"
													>
														<i className="dropdown-icon ri-edit-line"></i>
													</Link>

													<button
														className="btn dropdown-item"
														onClick={() => {
															setsingle(
																store?._id
															);
															setShowModal(
																!showModal
															);
														}}
													>
														<i className="dropdown-icon ri-delete-bin-6-line"></i>
													</button>
												</div>
											</td>
										</tr>
									))
								)}
							</tbody>
							<Pagination
								nPages={nPages}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/>
						</table>
					</div>
				</div>
			</section>{" "}
			{showModal && (
				<>
					<DeleteModal
						toggle={() => setShowModal(!showModal)}
						isLoading={isLoading}
						onDeleteClick={handleDelete}
					/>
					<div className="opacity-5 fixed inset-0 z-40 bg-black"></div>
				</>
			)}
			{/* <AddCategort
        isOpen={modalIsOpen}
        toggle={() => setIsOpen(!modalIsOpen)}
      /> */}
		</>
	);
};

export default Stores;
