import { Routes, Route, Navigate } from "react-router-dom";
import TheLayout from "../layouts/TheLayout";
import ForgotPassword from "../pages/auth/ForgotPassword";
import AuthLogin from "../pages/auth/Login";
import ResetPassword from "../pages/auth/ResetPassword";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogs/BlogDetails";
import Categories from "../pages/categories";
import AddCategory from "../pages/categories/AddCategory";
import Category from "../pages/categories/Category";
import EditCategory from "../pages/categories/EditCategory";
import Coupons from "../pages/coupons";
import AddCoupon from "../pages/coupons/AddCoupon";
import Coupon from "../pages/coupons/Coupon";
import EditCoupon from "../pages/coupons/EditCoupon";
import Home from "../pages/dashboard";
import Deals from "../pages/deals";
import FaqHome from "../pages/faqs";
import Reviews from "../pages/reviews";
import AddReview from "../pages/reviews/AddReview";
import Settings from "../pages/settings";
import Stores from "../pages/stores";
import AddSctore from "../pages/stores/AddStore";
import EditSctore from "../pages/stores/EditStore";
import Store from "../pages/stores/Store";
import SubCategories from "../pages/subcategories";
import AddSubCategory from "../pages/subcategories/AddSubCategory";
import EditSubCategory from "../pages/subcategories/EditCategory";
import TagsHome from "../pages/tags";
import Users from "../pages/users";
import Videos from "../pages/videos";
import AddVideo from "../pages/videos/AddVideo";
import EditVideo from "../pages/videos/EditVideo";
import VideoScreen from "../pages/videos/VideoScreen";
import Cashback from "../pages/Cashback";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import AddFaq from "../pages/faqs/AddFaq";
import EditFaq from "../pages/faqs/EditFaq";
import EditReview from "../pages/reviews/EditReview";
import AddFaqQuestions from "../pages/questions/AddQuestions";
import EditQuestions from "../pages/questions/EditQuestions";
import QuestionsHome from "../pages/questions"
import Comment from '../pages/recentcomment'
import Wallet from "../pages/Wallets"; 

const MainRoutes = () => (
  <Routes>
    {/** Protected Routes */}
    {/** Wrap all Route under ProtectedRoutes element */}
    <Route path="/" element={<ProtectedRoutes />}>
      <Route path="/" element={<TheLayout />}>
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Home />} />
        <Route path="coupons" element={<Coupons />} />
        <Route path="cashback" element={<Cashback />} />
        <Route path="Wallet" element={<Wallet />} />
        <Route path="coupons/:id" element={<Coupon />} />
        <Route path="coupons/:id/edit" element={<EditCoupon />} />
        <Route path="coupons/add" element={<AddCoupon />} />
        <Route path="stores" element={<Stores />} />
        <Route path="stores/:id" element={<Store />} />
        <Route path="stores/:id/edit" element={<EditSctore />} />
        <Route path="stores/add" element={<AddSctore />} />
        <Route path="videos" element={<Videos />} />
        <Route path="videos/:id" element={<VideoScreen />} />
        <Route path="videos/:id/edit" element={<EditVideo />} />
        <Route path="videos/add" element={<AddVideo />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="blogs/:id" element={<BlogDetails />} />
        <Route path="categories" element={<Categories />} />
        <Route path="categories/add" element={<AddCategory />} />
        <Route path="categories/:id" element={<Category />} />
        <Route path="categories/:id/edit" element={<EditCategory />} />
        <Route path="sub-categories" element={<SubCategories />} />
        <Route path="sub-categories/add" element={<AddSubCategory />} />
        <Route path="sub-categories/:id/edit" element={<EditSubCategory />} />
        <Route path="categories/:id" element={<Category />} />
        <Route path="categories/:id/edit" element={<EditCategory />} />
        {/* <Route path="deals" element={<Deals />} /> */}
        <Route path="reviews" element={<Reviews />} />
        <Route path="reviews/add" element={<AddReview />} />
        <Route path="reviews/:id/edit" element={<EditReview />} />

        <Route path="faqs" element={<FaqHome />} />
        <Route path="faqs/add" element={<AddFaq />} />
        <Route path="faqs/:id/edit" element={<EditFaq />} />

        <Route path="questions" element={<QuestionsHome />} />
        <Route path="questions/add" element={<AddFaqQuestions />} />
        <Route path="questions/:id/edit" element={<EditQuestions />} />

        <Route path="comments" element={<Comment />} />
        <Route path="RecentComment/add" element={<AddFaqQuestions />} />
        <Route path="RecentComment/:id/edit" element={<EditQuestions />} />


        <Route path="users" element={<Users />} />
        <Route path="settings" element={<Settings />} />
        <Route path="tags" element={<TagsHome />} />
        {/* <Route path="tags/add" element={<AddSubCategory />} /> */}
      </Route>
    </Route>

    {/** Public Routes */}
    {/** Wrap all Route under PublicRoutes element */}
    <Route path="/" element={<PublicRoutes />}>
      <Route path="/login" element={<AuthLogin />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/reset" element={<ResetPassword />} />
    </Route>

   
  </Routes>
);

export default MainRoutes;
