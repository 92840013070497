import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import faqService from "./faq.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isDeleted: false,
  message: "",
  faqs: [],
  faqs_graph: [],
  faq: null,
};

//add new faq
export const addFaq = createAsyncThunk(
  "faqs/add",
  async function (faq, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqService.addFaq(token, faq);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all faqs
export const getAllFaqs = createAsyncThunk(
  "faqs/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqService.getAllFaqs(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an faq
export const getFaq = createAsyncThunk(
  "faqs/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqService.getFaq(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a faq
export const updateFaq = createAsyncThunk(
  "faqs/update",
  async function (faq, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqService.updateFaq(faq.data, faq.id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a faq
export const publishFaq = createAsyncThunk(
  "faqs/publish",
  async function (faq, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqService.publishFaq(faq.data, faq.id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// DEL a faq
export const deleteFaq = createAsyncThunk(
  "faqs/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await faqService.deleteFaq(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for faqs
const faqSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFaq.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.faq = action.payload;
      })
      .addCase(addFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getFaq.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faq = action.payload;
      })
      .addCase(getFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })

      .addCase(getAllFaqs.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllFaqs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faqs = action.payload;
      })
      .addCase(getAllFaqs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(updateFaq.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faq = action.payload;
        state.isSuccess = true;
      })
      .addCase(updateFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(publishFaq.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(publishFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faq = action.payload;
        state.isSuccess = true;
      })
      .addCase(publishFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteFaq.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faq = action.payload;
        state.isDeleted = true;
      })
      .addCase(deleteFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = faqSlice.actions;
export default faqSlice.reducer;
