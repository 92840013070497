import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllCategories } from "../../redux/categories/categories.slices";
import {
  addSubCategory,
  reset,
} from "../../redux/subcategories/subcategories.slices";

function AddSubCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { isLoading, isSuccess } = useSelector((state) => state.subcategory);
  const { categories } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added subcategory successfully");
      dispatch(reset());
      navigate("/sub-categories");
    }
  }, [isSuccess]);

  function handleSave(data) {
    dispatch(addSubCategory(data));
  }

  return (
    <>
      <h1 className="text-3xl text-center text-red-600">Add new category</h1>
      <div className="flex items-center justify-center p-12">
        <div className="mx-auto w-full">
          <form className="container px-24" onSubmit={handleSubmit(handleSave)}>
            <div className="grid grid-cols-3 gap-5">
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  Category name
                </label>
                <select
                  className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
                  {...register("category", { required: true })}
                >
                  <option></option>
                  {categories?.map((cat) => (
                    <option value={cat._id}>{cat.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="mb-3 block text-base font-medium text-[#07074D]">
                  SubCategory Name
                </label>
                <input
                  type="text"
                  placeholder="enter sub-category name"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
                  {...register("name", { required: true })}
                />
              </div>
            </div>

            <div className="mt-5">
              <button
                type="submit"
                className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
              >
                {isLoading ? "saving" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddSubCategory;
