import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BreadCrumb from "../../components/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { addVideo, reset } from "../../redux/videos/video.slices";
import { getAllCategories } from "../../redux/categories/categories.slices";
import { getAllStores } from "../../redux/stores/stores.slices";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { editorConfig } from "../../utils/editorConfig";

const animatedComponents = makeAnimated();

function AddVideo() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [thumbnail, setThumbnail] = useState("");
	const [isTabs, setIsTabs] = useState(false);
	const [selectedTabs, setSelectedTabs] = useState([]);
	const [imageMobile, setImageMObile] = useState(null);
	const [imageDesktop, setImageDesktop] = useState(null);
	const [loading, setLoading] = useState(false);
	const [description,setDescription]= useState(null) 

	const {
		handleSubmit,
		register,
		reset: resetForm,
		watch,
		formState: { errors },
	} = useForm();

	const editor = useRef([]);
	const config = useMemo(() => editorConfig, []);

	const [formData, setFormData] = useState([]);

	const handleContentChange = (content, index) => {
		setFormData([
			...formData,
			{
				tab: content?.tab,
				desc: content?.content,
			},
		]);
	};

	const { isSuccess, isLoading } = useSelector((state) => state.video);
	const { categories } = useSelector((state) => state.category);
	const { stores } = useSelector((state) => state.store);

	useEffect(() => {
		dispatch(getAllCategories());
		dispatch(getAllStores());
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success("Added video successfully");
			resetForm();
			dispatch(reset());
			navigate("/videos");
		}
	}, [isSuccess]);

	const tabsOption = [
		{ value: "About Video", label: "About Video" },
		{ value: "Comments", label: "Comments" },
		{ value: "Share", label: "Share" },
	];

	const handleImageMobile = (e) => {
		// e.preventDefault();
		setLoading(true);
		try {
			let file = e.target.files[0];
			let formData = new FormData();
			formData.append("image", file);
			const config = {
				headers: {
					"content-type": "multipart/form-data",
				},
			};
			axios
				.post(
					process.env.REACT_APP_API_URL + `images`,
					formData,
					config
				)
				.then(({ data }) => {
					setImageMObile(data?.data?.uploadedUrl);
					toast.success("Success");
				});
		} catch (err) {
			toast.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	const handleImageDesktop = (e) => {
		// e.preventDefault();
		setLoading(true);
		try {
			let file = e.target.files[0];
			let formData = new FormData();
			formData.append("image", file);
			const config = {
				headers: {
					"content-type": "multipart/form-data",
				},
			};
			axios
				.post(
					process.env.REACT_APP_API_URL + `images`,
					formData,
					config
				)
				.then(({ data }) => {
					setImageDesktop(data?.data?.uploadedUrl);
					toast.success("Success");
				});
		} catch (err) {
			toast.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	function handleSave(data) {
		const newtabs = selectedTabs?.map((t) => {
			return {
				tab: t.value,
				desc: "",
			};
		});

		const combinedArray = newtabs.concat(formData);

		const uniqueMembers = Object.values(
			combinedArray.reduce((acc, obj) => {
				acc[obj.tab] = obj;
				return acc;
			}, new Map())
		);

		dispatch(
			addVideo({
				...data,
				tabs: selectedTabs?.map((tab) => tab.value),
				desktop_image: imageDesktop,
				mobile_image: imageMobile,
				video_description:description,
				devices: [],
				thumbnail,
				content_below: uniqueMembers,
				is_tabs: isTabs,
			})
		);
	}

	const preventMinus = (e) => {
		if (e.code === "Minus") {
			e.preventDefault();
		}
		const keyCode = e.keyCode;
		const excludedKeys = [8, 37, 39, 46];

		if (
			!(
				(keyCode >= 48 && keyCode <= 57) ||
				(keyCode >= 96 && keyCode <= 105) ||
				excludedKeys.includes(keyCode)
			)
		) {
			e.preventDefault();
		}
	};

	return (
		<>
			<div className="flex gap-2 justify-between px-8 my-4">
				<BreadCrumb title={"Video Add"} link="videos" />
				<h1 className="text-2xl text-center text-red-600">
					Add New Video
				</h1>
				<div className="flex gap-2"></div>
			</div>
			<div className="flex items-center justify-center p-12">
				<div className="mx-auto w-full">
					<form
						className="container px-24"
						onSubmit={handleSubmit(handleSave)}
					>
						<div className="grid grid-cols-2 gap-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Where to display video
								</label>
								<div>
									<label class="inline-flex items-center my-3 mr-5">
										<input
											type="checkbox"
											value={true}
											class="form-checkbox h-5 w-5 text-red-600"
											{...register("storepage")}
										/>
										<span class="ml-2 text-gray-700">
											Store page
										</span>
									</label>
									<label class="inline-flex items-center my-3 mr-5">
										<input
											type="checkbox"
											value={true}
											class="form-checkbox h-5 w-5 text-red-600"
											{...register("categorypage")}
										/>
										<span class="ml-2 text-gray-700">
											Category page
										</span>
									</label>
								</div>
							</div>
							{watch("storepage") && (
								<div>
									<label className="mb-3 block text-base font-medium text-[#07074D]">
										Store
									</label>

									<select
										className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
										{...register("store", {
											required: true,
										})}
									>
										<option></option>
										{stores?.map((s) => (
											<option value={s._id}>
												{s.name}
											</option>
										))}
									</select>
									{errors.store && (
										<span className="text-red-700">
											Store is required
										</span>
									)}
								</div>
							)}
							{watch("categorypage") && (
								<div>
									<label className="mb-3 block text-base font-medium text-[#07074D]">
										Category
									</label>

									<select
										className="w-full rounded-md border border-[#e0e0e0] bg-white
                py-3 px-6 text-base font-medium text-[#6B7280] outline-none
                focus:border-[#003049] focus:shadow-md"
										{...register("category", {
											required: true,
										})}
									>
										<option></option>
										{categories?.map((cat) => (
											<option value={cat._id}>
												{cat.name}
											</option>
										))}
									</select>
									{errors.categories && (
										<span className="text-red-700">
											Category is required
										</span>
									)}
								</div>
							)}

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Video link
								</label>
								<input
									type="text"
									placeholder="enter video link e.g www.youtube.com/NG6yvK003"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("link", { required: true })}
								/>
								{errors.link && (
									<span className="text-red-700">
										Link is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Title
								</label>
								<textarea
									rows="3"
									placeholder="Type video title here..."
									className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("title", { required: true })}
								></textarea>
								{errors.title && (
									<span className="text-red-700">
										Title is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Meta title
								</label>

								<input
									type="text"
									placeholder="enter meta title"
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("meta_title", {
										required: true,
									})}
								/>
								{errors.meta_title && (
									<span className="text-red-700">
										Meta title is required
									</span>
								)}
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Description
								</label>
								<textarea
									rows="3"
									placeholder="Type video description here"
									className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("description", {
										required: true,
									})}
								></textarea>
								{errors.description && (
									<span className="text-red-700">
										Description is required
									</span>
								)}
							</div>

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
								Video Description
								</label>
								<div>

									<div>
										<JoditEditor
											ref={editor}
											value={description}
											config={config}
											onChange={(value) => { }}
											onBlur={(content) =>
												setDescription(content)
											}
											
										/>
									</div>



								</div>
							</div>

							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Thumbnail
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="video_thumbnail"
											className="h-5 w-5"
											value={"upload"}
											onChange={(e) =>
												setThumbnail("upload")
											}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Upload Image
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="video_thumbnail"
											className="h-5 w-5"
											value={"default"}
											onChange={(e) =>
												setThumbnail("default")
											}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Use Default Thumbnail
										</label>
									</div>
								</div>
							</div>
							{thumbnail === "upload" && (
								<>
									<div className="flex gap-10">
										<div>
											<div className="my-2">
												Mobile Image
											</div>
											<div className="d-flex flex-col gap-4">
												{imageMobile ? (
													<div class="image-preview relative">
														<img
															src={imageMobile}
															alt=""
														/>
														<button
															onClick={() =>
																setImageMObile(
																	null
																)
															}
															className="btn absolute top-0 right-0 z-40 bg-white"
														>
															<i className="dropdown-icon ri-delete-bin-6-line"></i>
														</button>
													</div>
												) : (
													<div class="flex">
														<label
															for="file-upload"
															class="upload-button"
														>
															<i class="ri-file-add-line"></i>
														</label>
														<input
															id="file-upload"
															type="file"
															name="profileImage2"
															size="large"
															onChange={
																handleImageMobile
															}
															accept="image/*"
															hidden
															disabled={loading}
														/>
													</div>
												)}
											</div>
										</div>
										<div>
											<div className="my-2">
												Desktop Image
											</div>
											<div className="d-flex flex-col gap-4">
												{imageDesktop ? (
													<div class="image-preview relative">
														<img
															src={imageDesktop}
															alt=""
														/>
														<button
															onClick={() =>
																setImageDesktop(
																	null
																)
															}
															className="btn absolute top-0 right-0 z-40 bg-white"
														>
															<i className="dropdown-icon ri-delete-bin-6-line"></i>
														</button>
													</div>
												) : (
													<div class="flex">
														<label
															for="file-upload2"
															class="upload-button"
														>
															<i class="ri-file-add-line"></i>
														</label>

														<input
															id="file-upload2"
															type="file"
															name="profileImage"
															size="large"
															onChange={
																handleImageDesktop
															}
															accept="image/*"
															hidden
															disabled={loading}
														/>
													</div>
												)}
											</div>
										</div>
									</div>
									<div className="grid grid-cols-2 gap-4">
										<div>
											<label className="mb-3 block text-base font-medium text-[#07074D]">
												Desktop Image Alt tag
											</label>
											<input
												type="text"
												placeholder="provide alt text for desktop images"
												{...register("desktop_alt_tag")}
												className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
											/>
										</div>
										<div>
											<label className="mb-3 block text-base font-medium text-[#07074D]">
												Mobile Image Alt tag
											</label>
											<input
												type="text"
												placeholder="provide alt text for mobile images"
												{...register("mobile_alt_tag")}
												className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
											/>
										</div>
									</div>
								</>
							)}
						</div>

						<div className="grid grid-cols-1 gap-5 mt-6">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Sections (details)
								</label>
								<div className="flex items-center space-x-6 mb-5">
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											value={isTabs}
											onChange={(e) => setIsTabs(true)}
										/>
										<label
											for="radioButton1"
											className="pl-3 text-base font-medium text-gray-400"
										>
											Add sections
										</label>
									</div>
									<div className="flex items-center">
										<input
											type="radio"
											name="tabs"
											className="h-5 w-5"
											value={isTabs}
											onChange={(e) => setIsTabs(false)}
										/>
										<label
											for="radioButton2"
											className="pl-3 text-base font-medium text-gray-400"
										>
											No section Required
										</label>
									</div>
								</div>
								{isTabs && (
									<Select
										components={animatedComponents}
										isMulti
										name="users"
										className="basic-multi-select"
										placeholder="select the tabs to display..."
										classNamePrefix="select"
										closeMenuOnSelect={false}
										options={tabsOption}
										value={selectedTabs}
										onChange={(e) => setSelectedTabs(e)}
									/>
								)}
							</div>
						</div>

						<div className="grid grid-cols-1 my-4">
							{isTabs && (
								<>
									{selectedTabs.map((tab, i) => (
										<details key={i} className="group">
											<summary className="flex items-center font-medium cursor-pointer list-none bg-[#F5F5F5] p-2">
												<span className="transition group-open:rotate-180">
													<svg
														width="23"
														height="22"
														viewBox="0 0 23 22"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M11.7001 0.057373C5.65134 0.057373 0.750488 4.95823 0.750488 11.007C0.750488 17.0558 5.65134 21.9567 11.7001 21.9567C17.7489 21.9567 22.6498 17.0558 22.6498 11.007C22.6498 4.95823 17.7489 0.057373 11.7001 0.057373ZM18.058 12.2433C18.058 12.5347 17.8196 12.7731 17.5282 12.7731H13.4662V16.8351C13.4662 17.1265 13.2278 17.3649 12.9364 17.3649H10.4639C10.1725 17.3649 9.93407 17.1265 9.93407 16.8351V12.7731H5.8721C5.5807 12.7731 5.34228 12.5347 5.34228 12.2433V9.77078C5.34228 9.47938 5.5807 9.24096 5.8721 9.24096H9.93407V5.17899C9.93407 4.88758 10.1725 4.64916 10.4639 4.64916H12.9364C13.2278 4.64916 13.4662 4.88758 13.4662 5.17899V9.24096H17.5282C17.8196 9.24096 18.058 9.47938 18.058 9.77078V12.2433Z"
															fill="black"
														/>
													</svg>
												</span>
												<span className="ml-4">
													{tab?.value} Content Below
													the fold
												</span>
											</summary>
											<div>
												<JoditEditor
													ref={editor}
													// value={tab}
													config={config}
													name={tab?.value}
													onBlur={(content) =>
														handleContentChange(
															{
																tab: tab?.value,
																content,
															},
															i
														)
													}
												/>
											</div>
										</details>
									))}
								</>
							)}
						</div>
						<div className="grid grid-cols-2 gap-5">
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Admin votes
								</label>
								<input
									type="number"
									placeholder="enter admin votes"
									defaultValue={0}
									min={"0"}
									onKeyDown={preventMinus}
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("admin_votes")}
								/>
							</div>
							<div>
								<label className="mb-3 block text-base font-medium text-[#07074D]">
									Admin views
								</label>
								<input
									type="number"
									placeholder="enter admin clicks"
									defaultValue={0}
									min={"0"}
									onKeyDown={preventMinus}
									className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
									{...register("admin_views")}
								/>
							</div>
						</div>

						<div className="mt-5">
							<button
								type="submit"
								className="hover:shadow-form rounded-md bg-[#003049] py-3 px-8 text-base font-semibold text-white outline-none"
							>
								{isLoading ? "saving..." : "Save"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default AddVideo;
