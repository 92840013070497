import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "tag/";

// POST new Tag
const addTag = async (token, tag) => {
  const res = await axios.post(API_URL, tag, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.tag;
};

// GET all Tag
const getAllTags = async (token) => {
  const res = await axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.tag;
};

// GET all cancelled Tag
const getCancelledTags = async (token) => {
  const res = await axios.get(API_URL + "cancelled", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};

// GET an Tag
const getTag = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// Delete a Tag
const deleteTag = async (id, token) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an Tag
const updateTag = async (Tag, id, token) => {
  const res = await axios.patch(API_URL + id, Tag, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

const tagService = {
  addTag,
  getAllTags,
  getTag,
  updateTag,
  deleteTag,
};

export default tagService;
